import React from "react";
import ReactDOM from "react-dom/client";
import { handleSessionKey } from "./helpers/querystring";
import { QueryClient, QueryClientProvider } from "react-query";
import handleWwwRedirect from "./helpers/wwwRedirect/handleWwwRedirect";
import App from "./App";

handleSessionKey();
handleWwwRedirect();

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);
