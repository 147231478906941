import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import "./index.css";

const PreviousWinners = () => {
  const { t, i18n } = useTranslation();
  const [videoPlay, setVideoPlay] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="main previousWinner">
        <div className="parallax parallax--alt">
          <img src="/assets/images/decoration.jpg" alt="image" />
        </div>
        {/* /.parallax */}

        <div className="hero hero--alt">
          <div className="shell">
            <div className="hero__inner">
              <h2>{t("Landing.text36")}</h2>
            </div>
            {/* /.hero__inner */}
          </div>
          {/* /.shell */}

          <div className="hero__decoration">
            <img src="/assets/images/temp/mountain@2x.png" alt="image" width="1280" height="560" />
          </div>
          {/* /.hero__decoration */}
        </div>
        {/* /.hero */}

        <section className="section-winners">
          <div className="shell">
            <div className="section__video" style={{ paddingTop: "0" }}>
              <a
                className="card__image"
                
                onClick={() => {
                  setVideoPlay(true);
                }}>
                <ReactPlayer
                  url="https://player.vimeo.com/video/814294724?h=28b79cd24e"
                  playing={videoPlay}
                  volume={1}
                  className="videoPlayer"
                  controls
                  //onReady={() => console.log("ready now")}
                />
              </a>

              {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/ScMzIvxBSi4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
            </div>
            {/* /.section__video */}

            <h3>{t("Landing.text33")}</h3>

            <p>
              {t("Landing.text34")} <span>{t("Landing.text34i1")}</span> {t("Landing.text34i2")}{" "}
            </p>
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-winners */}
      </div>
      {/* /.main */}
    </>
  );
};

export default PreviousWinners;
