import { useEffect, useState } from "react";
import { campaignClient } from "../../api";
import { useTranslation } from "react-i18next";

function RulesAppendixA() {
  const { t } = useTranslation();
  const [newPeaks, setNewPeaks] = useState<any[]>();
  useEffect(() => {
    getAllPeaks();
  }, []);

  const getAllPeaks = async () => {
    const { peaks } = await campaignClient.call<{ peaks: [any] }>("getallpeaks", {});
    if (peaks) {
      const filteredPeaks = peaks.filter((i) => i.type === "new");
      filteredPeaks?.length && setNewPeaks(filteredPeaks);
    }
  };

  const AddedPeaks = () => {
    return (
      newPeaks &&
      newPeaks.map((peak, i) => (
        <tr key={i + 1}>
          <td width={140}>
            <p>
              <a href={peak?.peakUrl}>{peak?.peakName}</a>
            </p>
          </td>
          <td width={79}>
            <p>{peak?.province}</p>
          </td>
          <td width={169}>
            <p>{peak?.city}</p>
          </td>
          <td width={235}>
            <p>{peak?.peakCode}</p>
          </td>
        </tr>
      ))
    );
  };

  return (
    <>
      <p>
        <strong>{t("rules.title")}</strong>
      </p>

      <table>
        <tbody>
          <tr>
            <td width={140}>
              <p>{t("rules.1")}</p>
            </td>
            <td width={79}>
              <p>{t("rules.2")}</p>
            </td>
            <td width={169}>
              <p>{t("rules.3")}</p>
            </td>
            <td width={235}>
              <p>{t("rules.4")}</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/lake-louise/">Lake Louise</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Lake Louise</p>
            </td>
            <td width={235}>
              <p>
                <strong>#</strong>TopPeakLakeLouise
              </p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/sunshine-village/">
                  Sunshine Village
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Banff</p>
            </td>
            <td width={235}>
              <p>#TopPeakSunshineVillage</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/marmot-basin-jasper/">
                  Marmot Basin
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Jasper</p>
            </td>
            <td width={235}>
              <p>#TopPeakMarmotBasin</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/nakiska/">Nakiska</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Kananaskis Country</p>
            </td>
            <td width={235}>
              <p>#TopPeakNakiska</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/castle-mountain/">Castle Mountain</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Southern Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakCastleMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mt-norquay-banff/">Mt. Norquay</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Banff</p>
            </td>
            <td width={235}>
              <p>#TopPeakMtNorquay</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/canada-olympic-park-calgary/">
                  Canada Olympic Park
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Calgary</p>
            </td>
            <td width={235}>
              <p>#TopPeakCanadaOlympicPark</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/fortress-mountain/">
                  Fortress Mountain
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Kananaskis Country</p>
            </td>
            <td width={235}>
              <p>#TopPeakFortressMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/canyon-ski-area/">Canyon Ski Area</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Red Deer</p>
            </td>
            <td width={235}>
              <p>#TopPeakCanyon</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/hidden-valley-ab/">
                  Hidden Valley (AB)
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Southern Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakHiddenValley</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/misery-mountain/">Misery Mountain</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Northern Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakMiseryMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/kinosoo-ridge/">Kinosoo Ridge</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Central Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakKinosooRidge</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/whispering-pines-worsley/">
                  Whispering Pines
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Northern Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakWhisperingPines</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/vista-ridge/">Vista Ridge</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Fort McMurray</p>
            </td>
            <td width={235}>
              <p>#TopPeakVistaRidge</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-joy/">Mount Joy</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Central Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountJoy</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/sunridge/">Sunridge</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Edmonton</p>
            </td>
            <td width={235}>
              <p>#TopPeakSunridge</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/whispering-pines-worsley/">
                  Whispering Pines
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Northern Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakWhisperingPines</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/vista-ridge/">Vista Ridge</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Fort McMurray</p>
            </td>
            <td width={235}>
              <p>#TopPeakVistaRidge</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-joy/">Mount Joy</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Central Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountJoy</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/snow-valley-edmonton/">
                  Snow Valley
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Edmonton</p>
            </td>
            <td width={235}>
              <p>#TopPeakSnowValley</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/pass-powderkeg-crowsnest-pass/">
                  Pass Powderkeg
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Southern Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakPassPowderkeg</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/rabbit-hill-snow-resort/">
                  Rabbit Hill Snow Resort
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Edmonton</p>
            </td>
            <td width={235}>
              <p>#TopPeakRabbitHillSnow</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/silver-summit/">Silver Summit</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Central Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakSilverSummit</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/tawatinaw-valley/">
                  Tawatinaw Valley
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Central Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakTawatinawValley</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/fairview-ski-hill/">
                  Fairview Ski Hill
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Northern Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakFairview</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/little-smoky/">Little Smoky</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Northern Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakLittleSmoky</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/medicine-lodge/">Medicine Lodge</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Central Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakMedicineLodge</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/edmonton-ski-club/">
                  Edmonton Ski Club
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Edmonton</p>
            </td>
            <td width={235}>
              <p>#TopPeakEdmonton</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/nitehawk/">Nitehawk</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Northern Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakNitehawk</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/drayton-valley-brazeau-ski-hill/">
                  Drayton Valley – Brazeau Ski Hill
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Central Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakDraytonValley</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/misty-ridge/">Misty Ridge</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Central Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakMistyRidge</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/valley-ski-hill-alliance/">
                  Valley Ski Hill – Alliance
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Central Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakValleySkiHill</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/spring-lake-ski-hill/">
                  Spring Lake Ski Hill
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Northern Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakSpringLake</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/gwynne-valley/">Gwynne Valley</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Central Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakGwynneValley</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/eastlink-park-whitecourt/">
                  Eastlink Park – Whitecourt
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Central Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakEastlinkPark</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/innisfail/">Innisfail</a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Central Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakInnisfail</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/drumheller-valley-ski-hill/">
                  Drumheller Valley Ski Hill
                </a>
              </p>
            </td>
            <td width={79}>
              <p>AB</p>
            </td>
            <td width={169}>
              <p>Central Alberta</p>
            </td>
            <td width={235}>
              <p>#TopPeakDrumhellerValley</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.whistlerblackcomb.com/">Whistler Blackcomb</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Whistler</p>
            </td>
            <td width={235}>
              <p>#TopPeakWhistlerBlackcomb</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.sunpeaksresort.com/">Sun Peaks</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Sun Peaks</p>
            </td>
            <td width={235}>
              <p>#TopPeakSunPeaks</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.bigwhite.com/">Big White</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Kelowna</p>
            </td>
            <td width={235}>
              <p>#TopPeakBigWhite</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/silver-star/">Silver Star</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>North Okanagan</p>
            </td>
            <td width={235}>
              <p>#TopPeakSilverStar</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/fernie/">Fernie</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>&nbsp;East Kootenay</p>
            </td>
            <td width={235}>
              <p>#TopPeakFernie</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/panorama/">Panorama</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>&nbsp;East Kootenay</p>
            </td>
            <td width={235}>
              <p>#TopPeakPanorama</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/kicking-horse-golden/">
                  Kicking Horse – Golden
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Columbia-Shuswap</p>
            </td>
            <td width={235}>
              <p>#TopPeakKickingHorse</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/kimberley/">Kimberley</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>&nbsp;East Kootenay</p>
            </td>
            <td width={235}>
              <p>#TopPeakKimberley</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.revelstokemountainresort.com/">Revelstoke Mountain Resort</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Revelstoke</p>
            </td>
            <td width={235}>
              <p>#TopPeakRevelstokeMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/red-mountain-resort-rossland/">
                  Red Mountain Resort – Rossland
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Kootenay</p>
            </td>
            <td width={235}>
              <p>#TopPeakRedMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/cypress-mountain/">
                  Cypress Mountain
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Vancouver</p>
            </td>
            <td width={235}>
              <p>#TopPeakCypressMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/grouse-mountain/">Grouse Mountain</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Vancouver</p>
            </td>
            <td width={235}>
              <p>#TopPeakGrouseMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/apex-mountain-resort/">
                  Apex Mountain Resort
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Okanagan</p>
            </td>
            <td width={235}>
              <p>#TopPeakApexMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-washington/">
                  Mount Washington
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Vancouver Island</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountWashington</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/whitewater-nelson/">
                  Whitewater – Nelson
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Kootenay</p>
            </td>
            <td width={235}>
              <p>#TopPeakWhitewater</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-baldy/">Mount Baldy</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Kootenay</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountBaldy</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-seymour/">Mount Seymour</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Vancouver</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountSeymour</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/hudson-bay-mountain-smithers/">
                  Hudson Bay Mountain – Smithers
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Bulkley Nechako</p>
            </td>
            <td width={235}>
              <p>#TopPeakHudsonBayMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/shames-mountain/">Shames Mountain</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Kitimat-Stikine</p>
            </td>
            <td width={235}>
              <p>#TopPeakShamesMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/manning-park/">Manning Park</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Fraser Valley</p>
            </td>
            <td width={235}>
              <p>#TopPeakManningPark</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/crystal-mountain-westbank/">
                  Crystal Mountain – Westbank&nbsp;
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Okanagan</p>
            </td>
            <td width={235}>
              <p>#TopPeakCrystalMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/harper-mountain/">Harper Mountain</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Okanagan</p>
            </td>
            <td width={235}>
              <p>#TopPeakHarperMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/tabor-mountain/">Tabor Mountain</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Fraser-Fort George</p>
            </td>
            <td width={235}>
              <p>#TopPeakTaborMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/sasquatch-mountain-hemlock-valley/">
                  Sasquatch Mountain – Hemlock Valley
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Fraser-Fort George</p>
            </td>
            <td width={235}>
              <p>#TopPeakSasquatchMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/fairmont-hot-springs/">
                  Fairmont Hot Springs
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>&nbsp;East Kootenay</p>
            </td>
            <td width={235}>
              <p>#TopPeakFairmontHotSprings</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/murray-ridge/">Murray Ridge</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Bulkley Nechako</p>
            </td>
            <td width={235}>
              <p>#TopPeakMurrayRidge</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/powder-king/">Powder King</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Fraser-Fort George</p>
            </td>
            <td width={235}>
              <p>#TopPeakPowderKing</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/purden/">Purden</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Fraser-Fort George</p>
            </td>
            <td width={235}>
              <p>#TopPeakPurden</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/troll-ski-area-quesnel/">
                  Troll Ski Area – Quesnel
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Cariboo</p>
            </td>
            <td width={235}>
              <p>#TopPeakTroll</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-cain/">Mount Cain</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Vancouver Island</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountCain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/wapiti-ski-hill-elkford/">
                  Wapiti Ski Hill – Elkford
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>East Kootenay</p>
            </td>
            <td width={235}>
              <p>#TopPeakWapiti</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/clearwater/">Clearwater</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Thompson-Nicola</p>
            </td>
            <td width={235}>
              <p>#TopPeakClearwater</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-timothy/">Mount Timothy</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Cariboo</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountTimothy</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/salmo-ski-hill/">Salmo Ski Hill</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Central Kootenay</p>
            </td>
            <td width={235}>
              <p>#TopPeakSalmo</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/phoenix-mountain/">
                  Phoenix Mountain
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Kootenay Boundary</p>
            </td>
            <td width={235}>
              <p>#TopPeakPhoenixMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/little-mac-mackenzie/">
                  Little Mac – Mackenzie
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Fraser-Fort George</p>
            </td>
            <td width={235}>
              <p>#TopPeakLittleMac</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/big-bam-taylor/">Big Bam – Taylor</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Peace River</p>
            </td>
            <td width={235}>
              <p>#TopPeakBigBam</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/hart-highlands-prince-george/">
                  Hart Highlands – Prince George
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Fraser-Fort George</p>
            </td>
            <td width={235}>
              <p>#TopPeakHartHighlands</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/summit-lake-nakusp/">
                  Summit Lake – Nakusp
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Central Kootenay</p>
            </td>
            <td width={235}>
              <p>#TopPeakSummitLake</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/monashee/">Monashee</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>British Columbia</p>
            </td>
            <td width={235}>
              <p>#TopPeakMonashee</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mike-wiegele-blue-river/">
                  Mike Wiegele – Blue River
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Thompson-Nicola</p>
            </td>
            <td width={235}>
              <p>#TopPeakBlueRiver</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/adamants/">Adamants</a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Columbia-Shuswap</p>
            </td>
            <td width={235}>
              <p>#TopPeakAdamants</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/white-grizzly-meadow-creek/">
                  White Grizzly – Meadow Creek
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Central Kootenay</p>
            </td>
            <td width={235}>
              <p>#TopPeakWhiteGrizzly</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/valemount-glaciers-planned/">
                  Valemount Glaciers (planned)
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Fraser-Fort George</p>
            </td>
            <td width={235}>
              <p>#TopPeakValemount</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/south-tweedsmuir-park/">
                  South Tweedsmuir Park
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Central Coast</p>
            </td>
            <td width={235}>
              <p>#TopPeakSouthTweedsmuir</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/retallack-alpine-adventures-new-denver/">
                  Retallack Alpine Adventures – New Denver
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Central Kootenay</p>
            </td>
            <td width={235}>
              <p>#TopPeakRetallack</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/hudsons-hope-portage-mountain/">
                  Hudson's Hope – Portage Mountain
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>Peace River</p>
            </td>
            <td width={235}>
              <p>#TopPeakPortageMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/jumbo-glacier-resort-planned/">
                  Jumbo Glacier Resort
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>British Columbia</p>
            </td>
            <td width={235}>
              <p>#TopPeakJumboGlacier</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/saddle-mountain-planned/">
                  Saddle Mountain
                </a>
              </p>
            </td>
            <td width={79}>
              <p>BC</p>
            </td>
            <td width={169}>
              <p>British Columbia</p>
            </td>
            <td width={235}>
              <p>#TopPeakSaddleMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://asessippi.com/">Asessippi</a>
              </p>
            </td>
            <td width={79}>
              <p>MB</p>
            </td>
            <td width={169}>
              <p>Western Manitoba</p>
            </td>
            <td width={235}>
              <p>#TopPeakAsessippi</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://holidaymountain.com/">Holiday Mountain (MB)</a>
              </p>
            </td>
            <td width={79}>
              <p>MB</p>
            </td>
            <td width={169}>
              <p>Southern Manitoba</p>
            </td>
            <td width={235}>
              <p>#TopPeakHolidayMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skithunderhill.ca/">Thunderhill – Benito</a>
              </p>
            </td>
            <td width={79}>
              <p>MB</p>
            </td>
            <td width={169}>
              <p>Central Manitoba</p>
            </td>
            <td width={235}>
              <p>#TopPeakThunderhill</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="http://discoverminnedosa.com/p/ski-valley">Minnedosa Ski Valley</a>
              </p>
            </td>
            <td width={79}>
              <p>MB</p>
            </td>
            <td width={169}>
              <p>Western Manitoba</p>
            </td>
            <td width={235}>
              <p>#TopPeakMinnedosa</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://skistony.com/">Stony Mountain</a>
              </p>
            </td>
            <td width={79}>
              <p>MB</p>
            </td>
            <td width={169}>
              <p>Central Manitoba</p>
            </td>
            <td width={235}>
              <p>#TopPeakStonyMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="http://www.falconridgeski.com/">Falcon Ridge</a>
              </p>
            </td>
            <td width={79}>
              <p>MB</p>
            </td>
            <td width={169}>
              <p>Central Manitoba</p>
            </td>
            <td width={235}>
              <p>#TopPeakFalconRidge</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.mysterymountain.ca/">Mystery Mountain</a>
              </p>
            </td>
            <td width={79}>
              <p>MB</p>
            </td>
            <td width={169}>
              <p>Northern Manitoba</p>
            </td>
            <td width={235}>
              <p>#TopPeakMysteryMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/crabbe-mountain/">Crabbe Mountain</a>
              </p>
            </td>
            <td width={79}>
              <p>NB</p>
            </td>
            <td width={169}>
              <p>Central Hainesville</p>
            </td>
            <td width={235}>
              <p>#TopPeakCrabbeMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-farlagne/">Mont Farlagne</a>
              </p>
            </td>
            <td width={79}>
              <p>NB</p>
            </td>
            <td width={169}>
              <p>Northeast NB</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontFarlagne</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/sugarloaf-provincial-park/">
                  Sugarloaf Provincial Park
                </a>
              </p>
            </td>
            <td width={79}>
              <p>NB</p>
            </td>
            <td width={169}>
              <p>Northeast NB</p>
            </td>
            <td width={235}>
              <p>#TopPeakSugarloaf</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/poley-mountain/">Poley Mountain</a>
              </p>
            </td>
            <td width={79}>
              <p>NB</p>
            </td>
            <td width={169}>
              <p>Southern NB</p>
            </td>
            <td width={235}>
              <p>#TopPeakPoleyMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/silverwood-winter-park/">
                  Silverwood Winter Park
                </a>
              </p>
            </td>
            <td width={79}>
              <p>NB</p>
            </td>
            <td width={169}>
              <p>Central NB</p>
            </td>
            <td width={235}>
              <p>#TopPeakSilverwood</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/marble-mountain-steady-brook-humber-valley/">
                  Marble Mountain – Steady Brook (Humber Valley)
                </a>
              </p>
            </td>
            <td width={79}>
              <p>NL</p>
            </td>
            <td width={169}>
              <p>Newfoundland &amp; Labrador</p>
            </td>
            <td width={235}>
              <p>#TopPeakMarbleMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/smokey-mountain/">Smokey Mountain</a>
              </p>
            </td>
            <td width={79}>
              <p>NL</p>
            </td>
            <td width={169}>
              <p>Newfoundland &amp; Labrador</p>
            </td>
            <td width={235}>
              <p>#TopPeakSmokeyMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/white-hills/">White Hills</a>
              </p>
            </td>
            <td width={79}>
              <p>NL</p>
            </td>
            <td width={169}>
              <p>Newfoundland &amp; Labrador</p>
            </td>
            <td width={235}>
              <p>#TopPeakWhiteHills</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/snow-goose-mountain/">
                  Snow Goose Mountain
                </a>
              </p>
            </td>
            <td width={79}>
              <p>NL</p>
            </td>
            <td width={169}>
              <p>Newfoundland &amp; Labrador</p>
            </td>
            <td width={235}>
              <p>#TopPeakSnowGooseMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/blomidon-cat-skiing/">
                  Blomidon Cat Skiing
                </a>
              </p>
            </td>
            <td width={79}>
              <p>NL</p>
            </td>
            <td width={169}>
              <p>Newfoundland &amp; Labrador</p>
            </td>
            <td width={235}>
              <p>#TopPeakBlomidonCat</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/northern-lights/">Northern Lights</a>
              </p>
            </td>
            <td width={79}>
              <p>NL</p>
            </td>
            <td width={169}>
              <p>Newfoundland &amp; Labrador</p>
            </td>
            <td width={235}>
              <p>#TopPeakNorthernLights</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <u>Ben Eoin</u>
              </p>
            </td>
            <td width={79}>
              <p>NS</p>
            </td>
            <td width={169}>
              <p>Nova Scotia</p>
            </td>
            <td width={235}>
              <p>#TopPeakBenEoin</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/wentworth/">Wentworth</a>
              </p>
            </td>
            <td width={79}>
              <p>NS</p>
            </td>
            <td width={169}>
              <p>Nova Scotia</p>
            </td>
            <td width={235}>
              <p>#TopPeakWentworth</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/martock/">Martock</a>
              </p>
            </td>
            <td width={79}>
              <p>NS</p>
            </td>
            <td width={169}>
              <p>Nova Scotia</p>
            </td>
            <td width={235}>
              <p>#TopPeakMartock</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/cape-smokey/">Cape Smokey</a>
              </p>
            </td>
            <td width={79}>
              <p>NS</p>
            </td>
            <td width={169}>
              <p>Nova Scotia</p>
            </td>
            <td width={235}>
              <p>#TopPeakCapeSmokey</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/blue-mountain-resort-collingwood/">
                  Blue Mountain Resort
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Collingwood</p>
            </td>
            <td width={235}>
              <p>#TopPeakBlueMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/craigleith-ski-club/">
                  Craigleith Ski Club
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Southwestern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakCraigleith</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/osler-bluff-ski-club/">
                  Osler Bluff Ski Club
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Southwestern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakOslerBluff</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-st-louis-moonstone/">
                  Mount St. Louis – Moonstone
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Central Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountStLouis</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/alpine-ski-club-collingwood/">
                  Alpine Ski Club
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Collingwood</p>
            </td>
            <td width={235}>
              <p>#TopPeakAlpine</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/georgian-peaks-club/">
                  Georgian Peaks Club
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Southwestern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakGeorgianPeak</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/beaver-valley-ski-club/">
                  Beaver Valley Ski Club
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Southwestern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakBeaverValley</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/antoine-mountain/">
                  Antoine Mountain
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Northern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakAntoineMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/devils-glen/">Devil's Glen</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Central Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakDevilsGlen</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/searchmont/">Searchmont</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Northern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakSearchmont</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-jamieson-resort%20/">
                  Mount Jamieson Resort
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Northern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountJamiesonResort</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-baldy-thunder-bay/">
                  Mount Baldy
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Thunder Bay</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountBaldy</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/horseshoe-resort/">
                  Horseshoe Resort
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Central Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakHorseshoe</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/caledon-ski-club/">
                  Caledon Ski Club
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Caledon</p>
            </td>
            <td width={235}>
              <p>#TopPeakCaledon</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/calabogie-peaks/">Calabogie Peaks</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Ottawa</p>
            </td>
            <td width={235}>
              <p>#TopPeakCalabogiePeaks</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/boler-mountain-london/">
                  Boler Mountain
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>London</p>
            </td>
            <td width={235}>
              <p>#TopPeakBolerMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/devils-elbow/">Devil's Elbow</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Central Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakDevilsElbow</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/skyloft/">Skyloft</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Central Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakSkyloft</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/glen-eden/">Glen Eden</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Milton</p>
            </td>
            <td width={235}>
              <p>#TopPeakGlenEden</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/chicopee/">Chicopee</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Central Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakChicopee</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/dagmar-ski-resort/">
                  Dagmar Ski Resort
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Central Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakDagmar</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/hidden-valley-highlands/">
                  Hidden Valley Highlands
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Northern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakHiddenValleyHighlands</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/lakeridge-resort/">
                  Lakeridge Resort
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Central Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakLakeridge</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/brimacombe-oshawa-ski-club/">
                  Brimacombe (Oshawa Ski Club)
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Oshawa</p>
            </td>
            <td width={235}>
              <p>#TopPeakBrimacombe</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mansfield-ski-club/">
                  Mansfield Ski Club
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Central Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakMansfield</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/uplands-ski-centre/">
                  Uplands Ski Centre
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Central Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakUplands</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/adanac-ski-hill/">Adanac Ski Hill</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Northern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakAdanac</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/earl-bales-north-york-toronto/">
                  Earl Bales
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Toronto</p>
            </td>
            <td width={235}>
              <p>#TopPeakEarlBales</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/laurentian-ski-hill-north-bay/">
                  Laurentian Ski Hill
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>North Bay</p>
            </td>
            <td width={235}>
              <p>#TopPeakLaurentian</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/madawaska-mountain/">
                  Madawaska Mountain
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Eastern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakMadawaskaMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/cedar-highlands-ski-club/">
                  Cedar Highlands Ski Club
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Eastern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakCedarHighlands</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/batawa-ski-hill/">Batawa Ski Hill</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Eastern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakBatawa</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/tri-town/">Tri Town</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Northern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakTriTown</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/cobble-hills/">Cobble Hills</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Southwestern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakCobbleHills</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/snow-valley-barrie/">Snow Valley</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Barrie</p>
            </td>
            <td width={235}>
              <p>#TopPeakSnowValley</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-evergreen-kenora/">
                  Mount Evergreen
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Kenora (Northern Ontario)</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountEvergreen</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-martin-deep-river/">
                  Mount Martin – Deep River
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Ottawa</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountMartin</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-fairweather-atikokan/">
                  Mount Fairweather – Atikokan
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Northern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountFairweather</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/lively/">Lively</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Northern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakLively</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/alice-hill-park-pembroke/">
                  Alice Hill Park
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Eastern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakAliceHill</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/trestle-ridge-terrace-bay/">
                  Trestle Ridge – Terrace Bay
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Northern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakTrestleRidge</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-pakenham/">Mount Pakenham</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Eastern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountPakenham</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-dufour/">Mount Dufour</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Northern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountDufour</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/hockley-valley/">Hockley Valley</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Central Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakHockleyValley</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-molson-petawawa/">
                  Mount Molson – Petawawa
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Eastern Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountMolson</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/centennial-park-ski-hill/">
                  Centennial Park Ski Hill
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Central Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakCentennialPark</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/sir-sams/">Sir Sam's</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Central Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakSirSams</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/axis-freestyle-academy-vaughan/">
                  Axis Freestyle Academy
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Vaughan</p>
            </td>
            <td width={235}>
              <p>#TopPeakAxisFreestyle</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/pine-ridge/">Pine Ridge</a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Central Ontario</p>
            </td>
            <td width={235}>
              <p>#TopPeakPineRidge</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mt-chinguacousy-brampton/">
                  Mt. Chinguacousy
                </a>
              </p>
            </td>
            <td width={79}>
              <p>ON</p>
            </td>
            <td width={169}>
              <p>Brampton</p>
            </td>
            <td width={235}>
              <p>#TopPeakMtChinguacousy</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/tremblant/">Tremblant</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Tremblant</p>
            </td>
            <td width={235}>
              <p>#TopPeakTremblant</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-sainte-anne/">
                  Mont-Sainte-Anne
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Capitale-Nationale</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontSainteAnne</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/bromont/">Bromont</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Montérégie</p>
            </td>
            <td width={235}>
              <p>#TopPeakBromont</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/stoneham/">Stoneham</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Capitale-Nationale</p>
            </td>
            <td width={235}>
              <p>#TopPeakStoneham</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/sommet-saint-sauveur/">
                  Sommet Saint-Sauveur
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Laurentides</p>
            </td>
            <td width={235}>
              <p>#TopPeakSommetSaintSauveur</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/le-massif-de-charlevoix/">
                  Le Massif de Charlevoix
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Capitale-Nationale</p>
            </td>
            <td width={235}>
              <p>#TopPeakLeMassif</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/le-mont-grand-fonds/">
                  Le Mont Grand-Fonds
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Capitale-Nationale</p>
            </td>
            <td width={235}>
              <p>#TopPeakmontGrandFonds</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-blanc/">Mont Blanc</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Laurentides</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontBlanc</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-orford/">Mont Orford</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Estrie</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontOrford</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/owls-head/">Owl's Head</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Estrie</p>
            </td>
            <td width={235}>
              <p>#TopPeakOwlsHead</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/le-relais-lac-beauport/">
                  Le Relais – Lac Beauport
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Capitale-Nationale</p>
            </td>
            <td width={235}>
              <p>#TopPeakLeRelais</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-orignal-lac-etchemin/">
                  Mont Orignal – Lac Etchemin
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Chaudière-Appalaches</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontOriginal</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-sutton/">Mont Sutton</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Montérégie</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontSutton</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/val-saint-come/">Val Saint-Côme</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Laurentides</p>
            </td>
            <td width={235}>
              <p>#TopPeakValSaintCome</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-avila/">Mont Avila</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Laurentides</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontAvila</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/camp-fortune/">Camp Fortune</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Outaouais</p>
            </td>
            <td width={235}>
              <p>#TopPeakCampFortune</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/massif-du-sud/">Massif du Sud</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Chaudière-Appalaches</p>
            </td>
            <td width={235}>
              <p>#TopPeakMassifduSud</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-ste-marie/">Mont Ste-Marie</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Outaouais</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontSteMarie</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-cascades/">Mont Cascades</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Outaouais</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontCascades</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/belle-neige/">Belle Neige</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Laurentides</p>
            </td>
            <td width={235}>
              <p>#TopPeakBelleNeige</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-bechervaise/">
                  Mont Béchervaise
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Gaspésie–Îles-de-la-Madeleine</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontBéchervaise</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-comi/">Mont Comi</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Bas-Saint-Laurent</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontComi</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-la-reserve-saint-donat-de-montcalm/">
                  Mont La Réserve – Saint-Donat-de-Montcalm
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Lanaudière</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontlLaReserve</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-garceau/">Mont Garceau</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Lanaudière</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontGarceau</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/le-chantecler/">Le Chantecler</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Laurentides</p>
            </td>
            <td width={235}>
              <p>#TopPeakLeChantecler</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/val-direne/">Val d'Irène</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Bas-Saint-Laurent</p>
            </td>
            <td width={235}>
              <p>#TopPeakValdIrene</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-rigaud/">Mont Rigaud</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Montérégie</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontRigaud</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-saint-bruno-saint-bruno-de-montarville/">
                  Mont Saint-Bruno – Saint-Bruno-de-Montarville
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Montérégie</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontSaintBruno</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-avalanche/">Mont Avalanche</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Laurentides</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontAvalanche</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-gleason/">Mont Gleason</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Central Quebec</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontGleason</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/gallix/">Gallix</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Côte-Nord</p>
            </td>
            <td width={235}>
              <p>#TopPeakGallix</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-saint-mathieu/">
                  Mont-Saint-Mathieu
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Bas-Saint-Laurent</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontSaintMathieu</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-belu/">Mont Bélu</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Saguenay–Lac-Saint-Jean</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontBelu</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/vallee-du-parc/">Vallée du Parc</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Mauricie</p>
            </td>
            <td width={235}>
              <p>#TopPeakValleeDuParc</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-kanasuta/">Mont Kanasuta</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Abitibi-Témiscamingue</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontKanasuta</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-edouard-lanse-saint-jean/">
                  Mont-Edouard – L'Anse-Saint-Jean
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Saguenay–Lac-Saint-Jean</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontEdouard</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-habitant/">Mont Habitant</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Laurentides</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontHabitant</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/vorlage/">Vorlage</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Outaouais</p>
            </td>
            <td width={235}>
              <p>#TopPeakVorlage</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/sommet-gabriel/">Sommet Gabriel</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Laurentides</p>
            </td>
            <td width={235}>
              <p>#TopPeakSommetGabriel</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/la-tuque/">La Tuque</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Mauricie</p>
            </td>
            <td width={235}>
              <p>#TopPeakLaTuque</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-olympia/">Mont Olympia</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Laurentides</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontOlympia</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/valinouet/">Valinouët</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Saguenay–Lac-Saint-Jean</p>
            </td>
            <td width={235}>
              <p>#TopPeakValinouët</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/morin-heights/">Morin Heights</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Laurentides</p>
            </td>
            <td width={235}>
              <p>#TopPeakMorinHeights</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-lac-vert-hebertville/">
                  Mont Lac-Vert – Hébertville
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Saguenay–Lac-Saint-Jean</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontLacVert</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/do-mi-ski-dolbeau-mistassini/">
                  Do-Mi-Ski – Dolbeau-Mistassini
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Saguenay–Lac-Saint-Jean</p>
            </td>
            <td width={235}>
              <p>#TopPeakDoMi</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-castor/">Mont Castor</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Bas-Saint-Laurent</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontCastor</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/vallee-bleue/">Vallée Bleue</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Laurentides</p>
            </td>
            <td width={235}>
              <p>#TopPeakValléeBleue</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-fortin/">Mont Fortin</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Saguenay–Lac-Saint-Jean</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontFortin</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-ti-basse-baie-comeau/">
                  Mont Ti-Basse – Baie-Comeau
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Côte-Nord</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontTiBasse</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/pin-rouge/">Pin Rouge</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Gaspésie–Îles-de-la-Madeleine</p>
            </td>
            <td width={235}>
              <p>#TopPeakPinRouge</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-adstock/">Mont Adstock</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Chaudière-Appalaches</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontAdstock</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-bellevue/">Mont Bellevue</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Estrie</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontBellevue</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-miller/">Mont Miller</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Gaspésie–Îles-de-la-Madeleine</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontMiller</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/saint-pacome/">Saint-Pacôme</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Bas-Saint-Laurent</p>
            </td>
            <td width={235}>
              <p>#TopPeakSaintPacôme</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/montcalm/">Montcalm</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Lanaudière</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontcalm</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/edelweiss-valley-ski-resort/">
                  Edelweiss Valley Ski Resort
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Outaouais</p>
            </td>
            <td width={235}>
              <p>#TopPeakEdelweissValley</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-video/">Mont Video</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Abitibi-Témiscamingue</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontVideo</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-biencourt/">Mont Biencourt</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Bas-Saint-Laurent</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontBiencourt</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/saint-raymond/">Saint-Raymond</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Capitale-Nationale</p>
            </td>
            <td width={235}>
              <p>#TopPeakSaintRaymond</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/le-petit-chamonix/">
                  Le Petit Chamonix
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Gaspésie–Îles-de-la-Madeleine</p>
            </td>
            <td width={235}>
              <p>#TopPeakLePetitChamonix</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/beauce-vallee-jonction/">
                  Beauce – Vallée-Jonction
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Chaudière-Appalaches</p>
            </td>
            <td width={235}>
              <p>#TopPeakBeauce</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/glen-mountain-park/">
                  Glen Mountain Park
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>East Bolton</p>
            </td>
            <td width={235}>
              <p>#TopPeakGlenMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-apic/">Mont Apic</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Central Quebec</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontApic</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-chilly/">Mont Chilly</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Outaouais</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontChilly</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-chalco/">Mont Chalco</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Northern Quebec</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontChalco</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/cap-chat/">Cap-Chat</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Gaspésie–Îles-de-la-Madeleine</p>
            </td>
            <td width={235}>
              <p>#TopPeakCapChat</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/saint-georges/">Saint-Georges</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Chaudière-Appalaches</p>
            </td>
            <td width={235}>
              <p>#TopPeakSaintGeorges</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/centre-de-plein-air-levis/">
                  Centre de Plein Air – Lévis
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Chaudière-Appalaches</p>
            </td>
            <td width={235}>
              <p>#TopPeakCentreLevis</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/tobo-ski/">Tobo-Ski</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Saguenay–Lac-Saint-Jean</p>
            </td>
            <td width={235}>
              <p>#TopPeakToboSki</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/baie-des-sables/">Baie des Sables</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Estrie</p>
            </td>
            <td width={235}>
              <p>#TopPeakBaieDesSables</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-pits-cabano/">
                  Mont Pits – Cabano
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Bas-Saint-Laurent</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontPits</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/val-dor/">Val-d'Or</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Abitibi-Témiscamingue</p>
            </td>
            <td width={235}>
              <p>#TopPeakValdOr</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-villa-saguenay/">
                  Mont Villa Saguenay
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Saguenay–Lac-Saint-Jean</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontVilla</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/pente-a-neige-montreal/">
                  Pente à Neige
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Montreal</p>
            </td>
            <td width={235}>
              <p>#TopPeakPenteANeige</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/terrebonne/">Terrebonne</a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Lanaudière</p>
            </td>
            <td width={235}>
              <p>#TopPeakTerrebonne</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mont-shefford/">
                  Mont Shefford&nbsp;
                </a>
              </p>
            </td>
            <td width={79}>
              <p>QC</p>
            </td>
            <td width={169}>
              <p>Montérégie</p>
            </td>
            <td width={235}>
              <p>#TopPeakMontShefford</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/bristol-pit-yellowknife/">
                  Bristol Pit
                </a>
              </p>
            </td>
            <td width={79}>
              <p>NT</p>
            </td>
            <td width={169}>
              <p>Yellowknife</p>
            </td>
            <td width={235}>
              <p>#TopPeakBristolPit</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-sima/">Mount Sima</a>
              </p>
            </td>
            <td width={79}>
              <p>YT</p>
            </td>
            <td width={169}>
              <p>Whitehorse</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountSima</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/mount-maichen/">Mount Maichen</a>
              </p>
            </td>
            <td width={79}>
              <p>YT</p>
            </td>
            <td width={169}>
              <p>Yukon</p>
            </td>
            <td width={235}>
              <p>#TopPeakMountMaichen</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/moose-mountain-dawson-city/">
                  Moose Mountain
                </a>
              </p>
            </td>
            <td width={79}>
              <p>YT</p>
            </td>
            <td width={169}>
              <p>Dawson City</p>
            </td>
            <td width={235}>
              <p>#TopPeakMooseMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/brookvale-ski-park/">
                  Brookvale Ski Park
                </a>
              </p>
            </td>
            <td width={79}>
              <p>PE</p>
            </td>
            <td width={169}>
              <p>North Wiltshire</p>
            </td>
            <td width={235}>
              <p>#TopPeakBrookvale</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/ochapowace/">Ochapowace</a>
              </p>
            </td>
            <td width={79}>
              <p>SK</p>
            </td>
            <td width={169}>
              <p>Southern Saskatchewan</p>
            </td>
            <td width={235}>
              <p>#TopPeakOchapowace</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/table-mountain/">Table Mountain</a>
              </p>
            </td>
            <td width={79}>
              <p>SK</p>
            </td>
            <td width={169}>
              <p>Battleford</p>
            </td>
            <td width={235}>
              <p>#TopPeakTableMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/wapiti-valley/">Wapiti Valley</a>
              </p>
            </td>
            <td width={79}>
              <p>SK</p>
            </td>
            <td width={169}>
              <p>Gronlid</p>
            </td>
            <td width={235}>
              <p>#TopPeakWapitiValley</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/duck-mountain-kamsack/">
                  Duck Mountain
                </a>
              </p>
            </td>
            <td width={79}>
              <p>SK</p>
            </td>
            <td width={169}>
              <p>Kamsack</p>
            </td>
            <td width={235}>
              <p>#TopPeakDuckMountain</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <u>Mission Ridge</u>
              </p>
            </td>
            <td width={79}>
              <p>SK</p>
            </td>
            <td width={169}>
              <p>Southern Saskatchewan</p>
            </td>
            <td width={235}>
              <p>#TopPeakMissionRidge</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <u>Twin Towers</u>
              </p>
            </td>
            <td width={79}>
              <p>SK</p>
            </td>
            <td width={169}>
              <p>Southern Saskatchewan</p>
            </td>
            <td width={235}>
              <p>#TopPeakTwinTowers</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/kinsmen-little-red-river-park-prince-albert/">
                  Kinsmen – Little Red River Park
                </a>
              </p>
            </td>
            <td width={79}>
              <p>SK</p>
            </td>
            <td width={169}>
              <p>Prince Albert</p>
            </td>
            <td width={235}>
              <p>#TopPeakKinsmen</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.skiresort.info/ski-resort/optimist-hill/">Optimist Hill</a>
              </p>
            </td>
            <td width={79}>
              <p>SK</p>
            </td>
            <td width={169}>
              <p>Saskatoon</p>
            </td>
            <td width={235}>
              <p>#TopPeakOptimistHill</p>
            </td>
          </tr>
          <tr>
            <td width={140}>
              <p>
                <a href="https://www.pc.gc.ca/en/pn-np/nu/auyuittuq/index">
                  Auyuittuq National Park
                </a>
              </p>
            </td>
            <td width={79}>
              <p>NU</p>
            </td>
            <td width={169}>
              <p>Auyuittuq</p>
            </td>
            <td width={235}>
              <p>#TopPeakAuyuittuq</p>
            </td>
          </tr>
          {newPeaks && AddedPeaks()}
        </tbody>
      </table>
      <p>{t("rules.bottom")}</p>
    </>
  );
}

export default RulesAppendixA;
