import { defaultStorageHelper } from "@spry/campaign-client";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { campaignClient, getSessionClient, setSessionClient } from "../../../../api";
import Loading from "../../../../Components/Loading";
import ReCAPTCHA from "react-google-recaptcha";

import { emailRegex, emailValidation } from "../../../../helpers/regexes";
import "./index.css";
import { googlekey_dev, googlekey_prod } from "../../../../helpers/constants";

export type VoteForm = {
  name: string;
  email: string;
  recap: any;
};
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const Vote = () => {
  const [submitting, setSubmitting] = useState(false);
  const { peakname, peakToken } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<VoteForm>({ mode: "onTouched" });
  const formwatch = watch();

  const recapRegister = {
    ...register("recap", { required: { value: true, message: "Please choose recaptcha." } }),
  };

  async function handleVote(data: VoteForm) {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    try {
      const { result, error, message } = await getSessionClient().call<{
        result: boolean;

        error?: string;
        message?: string;
      }>("nominate", {
        firstName: data.name,
        email: data.email,
        peakName: peakname,
        userType: "voter",
        peakToken: peakToken,
        recaptchares: data.recap,
      });

      if (result) {
        navigate(`/vote/checkEmail/${peakToken}/${data.email}`);
      } else if (error === "Duplicate") {
        window.vex.dialog.alert({
          unsafeMessage: t("errorMessages.duplicate"),
          callback: () => {
            return;
          },
        });
      } else if (error && message) {
        window.vex.dialog.alert({
          unsafeMessage: message,
          callback: () => {
            return;
          },
        });
      } else {
        window.vex.dialog.alert({
          unsafeMessage: t("phase3.vote.text4"),
          callback: () => {
            return;
          },
        });
      }

      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      window.vex.dialog.alert({
        unsafeMessage: "Sorry, something went wrong!",
        callback: () => {
          return;
        },
      });
    }
  }

  return (
    <>
      {submitting && <Loading />}
      <div className="main vote">
        <div className="parallax parallax--different">
          <img src="/assets/images/decoration-2@2x.jpg" alt="image" />
        </div>
        {/* /.parallax */}

        <div className="hero hero--different">
          <div className="shell shell--lg">
            <div className="hero__inner">
              <a href="/" className="hero__link">
                {t("nominate.text1")}
              </a>

              <h2>{t("phase3.vote.1")}</h2>
            </div>
            {/* /.hero__inner */}
          </div>
          {/* /.shell */}
          <div className="hero__decoration">
            <img src="/assets/images/temp/mountain-2@2x.png" alt="image" />
          </div>
          {/* /.hero__decoration */}
        </div>
        {/* /.hero */}

        <section className="section-register">
          <div className="shell shell--lg">
            <p style={{ color: "#0F5486" }}>{t("phase3.vote.text1")}</p>

            <div className="form-register">
              <form onSubmit={handleSubmit(handleVote)}>
                <div className="form__row">
                  <label htmlFor="name" className="hidden">
                    {t("nominate.text3")}
                  </label>

                  <div className="form__controls">
                    <input
                      {...register("name", {
                        required: { value: true, message: t("errorMessages.nofullname") },
                      })}
                      id="name"
                      type="text"
                      placeholder={t("nominate.text3")}
                      required
                      className="field"
                    />
                    {errors.name && (
                      <p className="error-message">
                        <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />{" "}
                        {errors.name.message}{" "}
                      </p>
                    )}
                  </div>
                  {/* /.form__controls */}
                </div>
                {/* /.form__row */}

                <div className="form__row">
                  <label htmlFor="email" className="hidden">
                    {t("nominate.text4")}
                  </label>

                  <div className="form__controls">
                    <input
                      id="email"
                      type="email"
                      placeholder={t("nominate.text4")}
                      className="field"
                      autoComplete="none"
                      {...register("email", {
                        required: { value: true, message: t("errorMessages.noemail") },
                        pattern: {
                          value: emailValidation,
                          message: t("errorMessages.invalidemail"),
                        },
                      })}
                    />
                    {errors.email && (
                      <p className="error-message">
                        <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />{" "}
                        {errors.email.message}{" "}
                      </p>
                    )}
                  </div>
                  {/* /.form__controls */}
                </div>
                {/* /.form__row */}
                <div className="form__row">
                  <div className="recaptcha">
                    <ReCAPTCHA
                      sitekey={
                        process.env.REACT_APP_ENV !== "prod" ? googlekey_dev : googlekey_prod
                      }
                      {...recapRegister}
                      onChange={(v) => v && setValue("recap", v)}
                    />
                  </div>

                  {errors.recap && !formwatch.recap && errors?.recap?.message && (
                    <p className="error-message">
                      <i className="fas fa-exclamation-circle" />{" "}
                      {errors?.recap?.message.toString()}
                    </p>
                  )}
                </div>

                <div className="form__actions">
                  <button type="submit" className="form__btn ">
                    {t("phase3.vote.text2")}
                  </button>
                  <button
                    type="button"
                    className="form__btn__primary"
                    onClick={() => {
                      navigate("/");
                    }}>
                    {t("phase3.vote.text3")}
                  </button>
                </div>
                {/* /.form__actions */}
              </form>
            </div>
            {/* /.form-register */}
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-register */}
      </div>
      {/* /.main */}
    </>
  );
};

export default Vote;
