import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  BarElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend
);
export const options = {
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1000,
        font: {
          size: window.innerWidth > 650 ? 18 : 10,
        }, // <----- This prop sets the stepSize
      },
    },
    x: {
      ticks: {
        font: {
          size: window.innerWidth > 650 ? 15 : 5,
        },
        autoSkip: false,
        maxRotation: 90,
        minRotation: 0,
      },
      grid: {
        offset: true,
      },
    },
  },

  plugins: {
    legend: {
      display: true,
      // labels: {
      //   fontSize: window.innerWidth > 350 ? 20 : 10
      // }
    },
    title: {
      display: true,
      text: "",
    },
  },
};

export function Graph(graphdata: { labels: string[]; points: string[]; ranks: number[] }) {
  const data = {
    labels: graphdata.labels.map((label: string) => {
      if (/\s/.test(label)) {
        return label.split(" ");
      } else {
        return label;
      }
    }),
    datasets: [
      {
        type: "line" as const,
        label: "",
        borderColor: "rgb(255, 99, 132)",
        borderWidth: 2,
        fill: false,
        data: graphdata.labels.map((label: any, i: number) => {
          return +graphdata.points[i] * 1.5;
        }),
        pointRadius: 0,
      },
      {
        label: "Total points",
        data: graphdata.labels.map((label: any, i: number) => {
          return +graphdata.points[i];
        }),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: graphdata.ranks.map((rank: any, i: number) => {
          if (rank > 6) return "#527394";
          else if (rank > 3) return "#72b6e3";
          else return "#e47f31";
        }),
        barPercentage: 0.6,
      },
    ],
  };

  return (
    <>
      {/* <Line options={options} data={data}/> */}
      <div className="graph-div">
        <Chart type="bar" data={data} options={options} />
      </div>
    </>
  );
}
