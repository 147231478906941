import { Route } from "react-router-dom";
import Closed from "../Pages/Closed";
import Open from "../Pages/Open";
import "../i18n";
import PreviousWinners from "../Pages/Open/PreviousWinners";
import RulesEn from "../Pages/RulesEn";
import RulesFr from "../Pages/RulesFr";
import Teams from "../Pages/Open/Teams";
import HowItWorks from "../Pages/Open/HowItWorks";
import LeaderBoard from "../Pages/Open/Leaderboard";
import LandingWinnerComing from "../Pages/Open/PhaseWinnerComing";
export default (
  <>
    <Route path="/" element={<Open />}>
      <Route path="/" element={<LandingWinnerComing />} />
      <Route path="teams/:peakToken" element={<Teams />} />
      <Route path="howitworks" element={<HowItWorks />} />
      <Route path="leaderboard" element={<LeaderBoard />} />
      <Route path="pastwinners" element={<PreviousWinners />} />
    </Route>

    <Route path="rulesen" element={<RulesEn />} />
    <Route path="rulesfr" element={<RulesFr />} />

    <Route path="closed" element={<Closed />} />
    <Route path="coming" element={<LandingWinnerComing />}></Route>
  </>
);
