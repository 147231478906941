import React, { useEffect, useState } from "react";
import "./index.css";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { defaultStorageHelper } from "@spry/campaign-client";

type Phases = {
  phase: string;
  timer?: string;
};

const Header = ({ currentPhase }: { currentPhase: Phases | undefined }) => {
  const { t, i18n } = useTranslation();
  const [phase, setPhase] = useState("");
  const [isOpenState, setIsOpenState] = useState(false);

  useEffect(() => {
    const localState = defaultStorageHelper.get("state");
    localState === "open" && setIsOpenState(true);
  }, []);
  useEffect(() => {
    currentPhase && setPhase(currentPhase.phase);
  }, [currentPhase]);

  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="header-container">
      <div className="bar">
        <div className="shell">
          <p>{t("Header.text1")}</p>
        </div>
        {/* /.shell */}
      </div>
      {/* /.bar */}

      <header className="header">
        <div className="shell header__shell">
          <div className="header__images">
            <div className="logo-first">
              <button
                onClick={() => {
                  window.sessionStorage.clear();
                  window.localStorage.clear();
                  {
                    phase && navigate("/");
                  }
                }}
                className="logo"
                style={{ background: "transparent", border: "none", width: "140px" }}>
                {i18n.language === "en" ? (
                  <img src="/assets/images/svg/logo-1.svg" alt="logo" />
                ) : (
                  <img
                    src="/assets/images/temp/toppeaklogo_fr.png"
                    alt="logo"
                    className="fr-logo"
                  />
                )}
              </button>

              {isOpenState && (
                <>
                  {phase === "rally" && (
                    <button
                      onClick={() => {
                        setMenuOpen(false);
                        window.localStorage.clear();
                        window.sessionStorage.clear();
                        navigate("/findyourpeak");
                      }}
                      className="pastwinners"
                      style={{ background: "transparent", border: "none" }}>
                      {t("Header.text2")}
                    </button>
                  )}
                  {phase && (
                    <button
                      onClick={() => {
                        setMenuOpen(false);
                        navigate("/howitworks");
                      }}
                      className="pastwinners"
                      style={{ background: "transparent", border: "none" }}>
                      {t("Header.text3")}
                    </button>
                  )}
                 {phase !== "winner" && <button
                    onClick={() => {
                      setMenuOpen(false);
                      navigate("/leaderboard");
                    }}
                    className="pastwinners"
                    style={{ background: "transparent", border: "none" }}>
                    {t("Header.text4")}
                  </button>}
                  {phase !== "winner" &&  <button
                    onClick={() => {
                      setMenuOpen(false);
                      navigate("/challenges");
                    }}
                    className="pastwinners"
                    style={{ background: "transparent", border: "none" }}>
                    {t("Header.text5")}
                  </button>}
                  {phase !== "winner" &&   <button
                    onClick={() => {
                      setMenuOpen(false);
                      navigate("/rallykit");
                    }}
                    className="pastwinners"
                    style={{ background: "transparent", border: "none" }}>
                    {t("Header.text6")}
                  </button>}
                  {phase && (
                    <>
                      {" "}
                      <button
                        onClick={() => {
                          navigate("/pastwinners");
                        }}
                        className="pastwinners"
                        style={{ background: "transparent", border: "none" }}>
                        {t("Landing.text37")}
                      </button>
                      <button
                        onClick={() => {
                          setMenuOpen(false);
                          navigate("/faqs");
                        }}
                        className="pastwinners"
                        style={{ background: "transparent", border: "none" }}>
                        {t("Header.text7")}
                      </button>
                    </>
                  )}
                </>
              )}

              {i18n.language === "en" ? (
                <a
                  href="https://www.mackenzieinvestments.com/en"
                  className="logo"
                  target="_blank"
                  rel="noreferrer">
                  <img src="/assets/images/svg/logo-2.svg" alt="logo" />
                </a>
              ) : (
                <a
                  href="https://www.mackenzieinvestments.com/fr"
                  className="logo"
                  target="_blank"
                  rel="noreferrer">
                  <img src="/assets/images/temp/logo2-fr.png" alt="logo" className="fr-logo2" />
                </a>
              )}

              <div className={menuOpen ? "header__nav js-nav is-active" : "header__nav js-nav"}>
                <nav className="nav-utilities nav-mobile">
                  <ul className="section1">
                    <li>
                      {i18n.language === "en" ? (
                        <a href="/" className="logo">
                          <img
                            src="/assets/images/svg/logo-white_en2.svg"
                            alt="logo"
                            className=""
                          />
                        </a>
                      ) : (
                        <a href="/" className="logo">
                          <img
                            src="/assets/images/temp/logo-white_fr2.png"
                            alt="logo"
                            className=""
                          />
                        </a>
                      )}
                    </li>
                    {phase === "rally" && (
                      <li style={{ width: "100%", textAlign: "left" }}>
                        <button
                          onClick={() => {
                            setMenuOpen(false);
                            navigate("/findyourpeak");
                          }}>
                          {t("Header.text2")}
                        </button>
                      </li>
                    )}
                    <li style={{ width: "100%", textAlign: "left" }}>
                      <button
                        onClick={() => {
                          setMenuOpen(false);
                          navigate("/howitworks");
                        }}>
                        {t("Header.text3")}
                      </button>
                    </li>
                  
                 
                    {phase !== "winner" && <>   <li style={{ width: "100%", textAlign: "left" }}>
                          <button
                            onClick={() => {
                              setMenuOpen(false);
                              navigate("/leaderboard");
                            }}>
                            {t("Header.text4")}
                          </button>
                        </li>
                        <li style={{ width: "100%", textAlign: "left" }}>
                          <button
                            onClick={() => {
                              setMenuOpen(false);
                              navigate("/challenges");
                            }}>
                            {t("Header.text5")}
                          </button>
                        </li>
                        <li style={{ width: "100%", textAlign: "left" }}>
                          <button
                            onClick={() => {
                              setMenuOpen(false);
                              navigate("/rallykit");
                            }}>
                            {t("Header.text6")}
                          </button>
                        </li></>}
            
                    <li style={{ width: "100%", textAlign: "left" }}>
                      <button
                        onClick={() => {
                          setMenuOpen(false);
                          navigate("/pastwinners");
                        }}>
                        {t("Header.pastwinners")}
                      </button>
                    </li>
                    <li style={{ width: "100%", textAlign: "left" }}>
                      <button
                        onClick={() => {
                          setMenuOpen(false);
                          navigate("/faqs");
                        }}>
                        {t("Header.text7")}
                      </button>
                    </li>
                    <li className="no-border">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          i18n.language === "fr"
                            ? "https://www.mackenzieinvestments.com/fr"
                            : "https://www.mackenzieinvestments.com/en"
                        }
                        className="navLink">
                        <p>{t("Landing.text25")}</p>
                        <p>{t("Landing.text255")}</p>
                      </a>
                      {/* /.section__actions */}
                    </li>
                    <div className="hero__decoration">
                      <img
                        src="/assets/images/temp/mountain@2x.png"
                        alt="mountain"
                        width="1280"
                        height="560"
                      />
                    </div>
                  </ul>
                  <Footer />
                </nav>
              </div>
            </div>
          </div>
          {phase && isOpenState && (
            <a
              onClick={() => (menuOpen ? setMenuOpen(false) : setMenuOpen(true))}
              className={
                menuOpen ? "nav-trigger js-nav-trigger is-active" : "nav-trigger js-nav-trigger"
              }>
              <span></span>

              <span></span>

              <span></span>
            </a>
          )}
          {/* /.header__images */}
        </div>
        {/* /.shell */}
      </header>
    </div>
  );
};

export default Header;
