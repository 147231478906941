

const TEAM_STATUS = {
    unsubmit:0,
    pending: 1,
    approved: 2,
    declined: 3
}
const googlekey_dev = "6LfuA7AbAAAAALcYTirXGbiXSOI8eER7WfAJE63m";
const googlekey_prod = "6Lf-q0wpAAAAAMTa0p7AjdrMigQs4fP1snzrmBVR";

const POINTS = {
  video: 1000,
  nomination: 10,
  social: 100
}

const BALLOTS_TYPE = {
  video: 'video',
  nomination: 'nominate',
  social: 'social',
  vote: 'votes'
}

export {
    TEAM_STATUS,
    POINTS,
    BALLOTS_TYPE,
    googlekey_dev,
    googlekey_prod
}



const cloudfrontDomains = {
  prod: "d3tnfjs3dcqndn.cloudfront.net",
  dev: "d2ztwr63umzbrb.cloudfront.net",
};


const env = process.env.REACT_APP_ENV || "";

export const s3 = cloudfrontDomains[env === "prod" ? "prod" : "dev"];
