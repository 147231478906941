import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { campaignClient } from "../../../../api";
import "./index.css";
import Modal from "react-modal";
import ReactPlayer from "react-player";

import styles from "../../../Open/ReviewProfile/submission.module.css";
import Loading from "../../../../Components/Loading";
import { s3 } from "../../../../helpers/constants";

declare const window: Window &
    typeof globalThis & {
        vex: any;
    };

type Team = {
    videoKey: string;
    peak: string;
    question1: string;
    teamName: string;
    question2: string;
    creationTime: string;
    peakName: string;
    teamStatus: number;
    thumbnailKey: string;
    peakToken: string;
    city: string;
    updateTime: string;
    sessionKey: string;
    team: string;
    declineReason: string;
    cause: string;
};
const Landing = () => {
    const { t, i18n } = useTranslation();
    const isEnglish = i18n.language === "en";
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className="main winners">
            <div className="parallax parallax--different">
                <img src="/assets/images/decoration.jpg" alt="image" />
            </div>

            <div className="hero hero-winner">
                <div className="flex-center">
                    <div className="hero__inner centered">
                        <h1> {t("phase4.landing.text1")}</h1>
                    </div>
                    {/* /.hero__inner */}
                </div>
                {/* /.shell */}

                <div className="hero__decoration_winner">
                    <img src="/assets/images/temp/mountain@2x.png" alt="image" width="1280" height="560" />
                </div>
                {/* /.hero__decoration */}
            </div>
            <section className="section-winners" style={{ paddingTop: "3rem" }}>
                <div className="shell">
                    <div className="section__video" style={{ paddingTop: "0" }}>
                        <div style={{ padding: "56.5% 0 0 0", position: "relative", zIndex: "100" }}>
                            <iframe
                                src={`https://${s3}/a607c6b41c623ed032be83119207b0e1/WINNER_ANNOUNCMENT.mp4`}
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                                style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    // height: "100%",
                                }}
                                title="FinalSR.mp4"></iframe>
                        </div>
                        <h2
                            style={{
                                marginTop: "5rem",
                            }}>
                            {t("phase4.landing.title")}{" "}
                        </h2>

                        <h6
                            style={{
                                marginBottom: "2.5rem",
                                textAlign: "left",
                                fontWeight: "bold",
                                lineHeight: "1.25",
                            }}>
                            {" "}
                            {t("phase4.landing.text12")}{" "}
                        </h6>
                        <h6
                            style={{
                                marginBottom: "2.5rem",
                                textAlign: "left",
                                fontWeight: "bold",
                                lineHeight: "1.25",
                            }}>
                            {" "}
                            {t("phase4.landing.text13")}{" "}
                        </h6>
                    </div>
                    {/* /.section__video */}

                  
                    <h6 style={{ paddingBottom: "2.5rem" }}>
                        {t("phase4.landing.text2")}{" "}
                        <a href={`https://www.mackenzieinvestments.com/${isEnglish ? "en" : "fr"}/about-us/sponsorship`}>{t("phase4.landing.text2i")}</a>
                    </h6>
                </div>
                {/* /.shell */}
            </section>
            <section className="section-gallery" style={{ textAlign: "center" }}>
                <h2>{t("Landing.text61")}</h2>
                <div className="embedsocial-hashtag" data-ref="328b1f27a5cb923ed3f3a57cc37da981ef1acce9"></div>
            </section>
        </div>
    );
};

export default Landing;
