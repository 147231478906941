import { defaultStorageHelper } from "@spry/campaign-client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { getSessionClient } from "../../../../api";
import Loading from "../../../../Components/Loading";
import { showToast } from "../../../../Components/Toast/ToastManager";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const CheckEmail = () => {
  const [submitting, setSubmitting] = useState(false);
  const { pk, email } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleResendEmail() {
    if (submitting) {
      return;
    }
    showToast({
      content: "Email resend successfully.",
      duration: 5000,
      error: false,
    });

    setSubmitting(true);
    try {
      const { sessionKey, result, message, error } = await getSessionClient().call<{
        sessionKey?: string;
        result?: string;
        email?: string;
        message?: string;
        error?: string;
      }>("resendVerify ", {
        // sk: getSessionClient().__sessionKey,
        peakToken: pk,
        email,
      });
      if (result) {
        showToast({
          content: "Email resend successfully.",
          duration: 3000,
          error: false,
        });
      } else if (message === "only one vote per day") {
        window.vex.dialog.alert({
          unsafeMessage: t("phase3.vote.text4"),
          callback: () => {
            return;
          },
        });
      } else if (error === "INVALID_LINK") {
        window.vex.dialog.alert({
          unsafeMessage: "Something vent wrong. Please try again latter",
          callback: () => {
            navigate("/");
            return;
          },
        });
      } else if (error && message) {
        window.vex.dialog.alert({
          unsafeMessage: message,
          callback: () => {
            navigate("/");
            return;
          },
        });
      }

      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);

      window.vex.dialog.alert({
        unsafeMessage: "Sorry, something went wrong! ",
        callback: () => {
          return;
        },
      });
    }
  }

  return (
    <>
      {submitting && <Loading />}
      <div className="main vote">
        <div className="parallax parallax--different">
          <img src="/assets/images/decoration-2@2x.jpg" alt="image" />
        </div>
        {/* /.parallax */}

        <div className="hero hero--different">
          <div className="shell shell--lg">
            <div className="hero__inner">
              <a href="/" className="hero__link">
                {t("nominate.text1")}
              </a>

              <h2>{t("phase3.check.1")}</h2>
            </div>
            {/* /.hero__inner */}
          </div>
          {/* /.shell */}

          <div className="hero__decoration">
            <img src="/assets/images/temp/mountain-2@2x.png" alt="image" />
          </div>
          {/* /.hero__decoration */}
        </div>
        {/* /.hero */}

        <section className="section-register">
          <div className="shell shell--lg">
            <p style={{ color: "#0F5486" }}>{t("phase3.check.2")}</p>

            <div className="form-register">
              <button className="form__btn " onClick={handleResendEmail} disabled={submitting}>
                {t("phase3.check.3")}
              </button>
            </div>
            {/* /.form-register */}
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-register */}
      </div>
      {/* /.main */}
    </>
  );
};

export default CheckEmail;
