import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { campaignClient, getSessionClient, setSessionClient } from "../../../../api";
import Loading from "../../../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";

const VoteReceived = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sessionKey, peakToken, time } = useParams();
  useEffect(() => {
    if (sessionKey) {
      setSessionClient(campaignClient.recoverSession(sessionKey));
    }
  }, []);

  const { isLoading, data } = useQuery("submitVote", async () => {
    const res: { error: string; result: boolean } = await getSessionClient().call("submitVote", {
      sessionKey,
      peakToken,
      time,
    });
    return res;
  });


  return (
    <>
    {isLoading && <Loading/>}
      <div className="main vote received">
        <div className="parallax parallax--different">
          <img src="/assets/images/decoration-2@2x.jpg" alt="image" />
        </div>
        {/* /.parallax */}

        <div className="hero hero--different">
          <div className="shell shell--lg">
            <div className="hero__inner">
              <a href="/" className="hero__link">
                {t("teams.text1")}
              </a>

              <h2>{t("phase3.received.text1")}</h2>
            </div>
            {/* /.hero__inner */}
          </div>
          {/* /.shell */}

          <div className="hero__decoration">
            <img src="/assets/images/temp/mountain-2@2x.png" alt="image" />
          </div>
          {/* /.hero__decoration */}
        </div>
        {/* /.hero */}

        <section className="section-register">
          <div className="shell shell--lg">
            <p>{t("phase3.received.text2")}</p>
            <p>{t("phase3.received.text3")}</p>
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-register */}
      </div>
      {/* /.main */}
    </>
  );
};

export default VoteReceived;
