import React, { useEffect } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";

const HowItWorks = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main howitworks">
        <div className="parallax parallax--different">
          <img src="/assets/images/decoration-2@2x.jpg" alt="image" />
        </div>
        {/* /.parallax */}

        <div className="hero hero--different hero--size1">
          <div className="shell shell--lg">
            <div className="hero__inner">
              <h2>{t("Howitworks.text1")}</h2>
            </div>
            {/* /.hero__inner */}
          </div>
          {/* /.shell */}

          <div className="hero__decoration">
            <img src="/assets/images/temp/mountain-2@2x.png" alt="image" />
          </div>
          {/* /.hero__decoration */}
        </div>
        {/* /.hero */}

        <section className="section-blocks">
          <div className="shell shell--lg">
            <div className="section__inner">
              <div className="section__hint">
                <ol>
                  <li>
                    <h6>{t("Howitworks.Nominate")}</h6>

                    <p>{t("Howitworks.text3")} </p>
                  </li>

                  <li>
                    <h6>{t("Howitworks.text6")}</h6>

                    <p>{t("Howitworks.text7")}</p>
                  </li>

                  <li>
                    <h6>{t("Howitworks.text8")}</h6>

                    <p>{t("Howitworks.text9")} </p>
                  </li>

                  <li>
                    <h6>{t("Howitworks.text10")}</h6>

                    <p>{t("Howitworks.text11")}</p>
                  </li>

                  <li>
                    <h6>{t("Howitworks.text12")}</h6>

                    <p>{t("Howitworks.text13")}</p>
                  </li>
                </ol>

                <div className="section__block">
                  <h4 style={{ color: "#E67524" }}>{t("Howitworks.text14")}</h4>

                  <h6>{t("Howitworks.text15")}</h6>

                  <h6>{t("Howitworks.text16")}</h6>
                  <p>
                    {t("Howitworks.text17")}
                    <br />
                    {t("Howitworks.1")}
                    <br />
                    {t("Howitworks.bonus")}
                  </p>

                  <h6>{t("Howitworks.text18")}</h6>
                  <p>{t("Howitworks.text19")}</p>
                  <h6>{t("Howitworks.text20")}</h6>
                  <p>{t("Howitworks.text21")}</p>
                  <h6>{t("Howitworks.text22")}</h6>
                  <p>{t("Howitworks.text23")}</p>
                  <p>{t("Howitworks.text24")}</p>
                  <p>{t("Howitworks.text25")}</p>
                  <p>{t("Howitworks.2")}</p>
                </div>
                {/* /.section__block */}

                <section className="section-dates">
                  <div className="shell">
                    <div className="section__inner">
                      <div className="section__items">
                        <h3>{t("Howitworks.text26")}</h3>

                        <ul>
                          <li>
                            <h5>{t("Howitworks.text27")}</h5>

                            <p>{t("Howitworks.text28")}</p>
                          </li>

                          <li>
                            <h5>{t("Howitworks.text29")}</h5>

                            <p>{t("Howitworks.text30")}</p>
                          </li>

                          {/* <li>
                            <h5>{t("Howitworks.text31")}</h5>

                            <p>{t("Howitworks.text32")} </p>
                          </li> */}

                          <li>
                            <h5>{t("Howitworks.text33")}</h5>

                            <p>{t("Howitworks.text34")}</p>
                          </li>

                          {/* <li>
                            <h5>{t("Howitworks.text35")}</h5>

                            <p>{t("Howitworks.text36")}</p>
                          </li> */}

                          <li>
                            <h5>{t("Howitworks.text37")}</h5>

                            <p>{t("Howitworks.text38")}</p>
                          </li>
                        </ul>
                      </div>
                      {/* /.section__content */}
                    </div>
                    {/* /.section__inner */}
                  </div>
                  {/* /.shell */}
                </section>
                {/* /.section-dates */}
                {/* /.section__actions */}
              </div>
              {/* /.section__hint */}
            </div>
            {/* /.section__inner */}
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-blocks */}
      </div>
      {/* /.main */}
    </>
  );
};

export default HowItWorks;
