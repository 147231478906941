export const randPassword = () => {

  const chars = [
     "0123456789", 
     "abcdefghijklmnopqrstuvwxyz",
     "ABCDEFGHIJKLMNOPQRSTUVWXYZ", 
     "!@#$%^*()"
  ];
    
  const randoms = [2, 4, 1, 1].map((len, i)=>{

  return Array(len).fill(chars[i]).map(function(x) {
    return x[Math.floor(Math.random() * x.length)];
    }).join('')
  });
   
   return randoms.join('').split('').sort(function(){return 0.5-Math.random()}).join('');
  
  }

export const convertcaps =(data:string)=>{

return data.split(' ').map(word=>word.charAt(0).toUpperCase()+word.slice(1)).join(" ")

}

export const disableBrowserHistory = () => {
window.history.pushState(null, null || "", window.location.href);
window.onpopstate = function (event) {
  window.history.pushState(null, "", window.location.href);
};
};

export function b64toBlob(b64Data:string, contentType:string, sliceSize=512) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
  }

var blob = new Blob(byteArrays, {type: contentType});
return blob;
}

export const FRAME_X_OFFSET = 0;
export const FRAME_Y_OFFSET = 0;
export const FRAME_WIDTH = 1620;
export const FRAME_HEIGHT = 2160;
export const FRAME_INNER_HEIGHT = 1440;

export const resizeImage = (ratio:any, originCanvas:any) => {
  const tempCanvas:any = document.createElement('canvas');
  const tempctx:any = tempCanvas.getContext('2d');
  // const _temp_ratio = Math.min(ratio.w, ratio.h);
  // tempCanvas.width = Math.ceil(originCanvas.width * _temp_ratio);
  // tempCanvas.height = Math.ceil(originCanvas.height * _temp_ratio);
  tempCanvas.width = Math.ceil(originCanvas.width * ratio.w);
  tempCanvas.height =Math.ceil(originCanvas.height * ratio.h);
  //tempctx.drawImage(originCanvas, 0, 0, FRAME_WIDTH, FRAME_HEIGHT)
  tempctx.drawImage(originCanvas, 0, 0,
      originCanvas.width, originCanvas.height, 0, 0, tempCanvas.width,
      tempCanvas.height);
  return tempCanvas;
};

