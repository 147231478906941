import React, { useEffect } from "react";
import RulesAppendixA from "../RulesAppendixA";

function RulesFr() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main rules">
      <div className="parallax parallax--different">
        <img src="/assets/images/decoration-2@2x.jpg" alt="image" />
      </div>
      {/* /.parallax */}

      <div className="hero hero--different hero--size1">
        <div className="shell shell--lg">
          <div className="hero__inner">
            <h2>RÈGLEMENTS OFFICIELS DU CONCOURS</h2>
          </div>
          {/* /.hero__inner */}
        </div>
        {/* /.shell */}

        <div className="hero__decoration">
          <img src="/assets/images/temp/mountain-2@2x.png" alt="image" />
        </div>
        {/* /.hero__decoration */}
      </div>
      {/* /.hero */}

      <section className="section-blocks">
        <div className="shell shell--lg">
          <div className="section__inner">
            <div className="section__hint">
              <>
                <p>
                  AUCUN ACHAT REQUIS. VOUS N’AUREZ PAS DE CHANCES SUPPLÉMENTAIRES DE GAGNER SI VOUS
                  EFFECTUEZ UN ACHAT OU UN PAIEMENT.
                </p>
                <ol>
                  <li>
                    <strong>DATES IMPORTANTES&nbsp;:</strong>
                  </li>
                </ol>
                <>
                  <p>
                    Le&nbsp;<strong>concours</strong>&nbsp;Au sommet avec Mackenzie (le «&nbsp;
                    <strong>concours</strong>&nbsp;») commence le 8&nbsp;janvier&nbsp;2024 à
                    0&nbsp;h (HNE) et se termine le 9 mars 2024 à 23&nbsp;h 59&nbsp;(HNE) (la&nbsp;
                    <strong>«&nbsp;période du concours&nbsp;»</strong>).
                  </p>
                  <p>
                    Le concours comprendra une (1) période de mise en candidature et de
                    participation (la&nbsp;
                    <strong>« période de mise en candidature et de participation »</strong>), une
                    (1) période de vote au Canada (le&nbsp;
                    <strong>« vote au Canada »</strong>) et une (1) période d’évaluation (la&nbsp;
                    <strong>« période d’évaluation »</strong>), comme cela est indiqué dans le
                    tableau suivant&nbsp;:
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td width={156}>
                          <p>PÉRIODE</p>
                        </td>
                        <td width={156}>
                          <p>OUVRIR</p>
                        </td>
                        <td width={156}>
                          <p>FERMER</p>
                        </td>
                        <td width={156}>
                          <p>HEURE (HNE)</p>
                        </td>
                      </tr>
                      <tr>
                        <td width={156}>
                          <p>Période de mise en candidature et de participation</p>
                        </td>
                        <td width={156}>
                          <p>8 janvier 2024</p>
                        </td>
                        <td width={156}>
                          <p>4 février 2024</p>
                        </td>
                        <td width={156}>
                          <p>23 h 59</p>
                        </td>
                      </tr>
                      <tr>
                        <td width={156}>
                          <p>Annonce des 10 finalistes</p>
                        </td>
                        <td width={156}>
                          <p>5 février 2024</p>
                        </td>
                        <td width={156}>
                          <p>N/A</p>
                        </td>
                        <td width={156}>
                          <p>À déterminer</p>
                        </td>
                      </tr>
                      <tr>
                        <td width={156}>
                          <p>Vote au Canada</p>
                        </td>
                        <td width={156}>
                          <p>5 février 2024</p>
                        </td>
                        <td width={156}>
                          <p>3 mars 2024</p>
                        </td>
                        <td width={156}>
                          <p>23 h 59</p>
                        </td>
                      </tr>
                      <tr>
                        <td width={156}>
                          <p>Annonce des 3 finalistes</p>
                        </td>
                        <td width={156}>
                          <p>4 mars 2024</p>
                        </td>
                        <td width={156}>
                          <p>N/A</p>
                        </td>
                        <td width={156}>
                          <p>À déterminer</p>
                        </td>
                      </tr>
                      <tr>
                        <td width={156}>
                          <p>Période d’évaluation</p>
                        </td>
                        <td width={156}>
                          <p>4 mars 2024</p>
                        </td>
                        <td width={156}>
                          <p>8 mars 2024</p>
                        </td>
                        <td width={156}>
                          <p>N/A</p>
                        </td>
                      </tr>
                      <tr>
                        <td width={156}>
                          <p>Dévoilement des gagnants</p>
                        </td>
                        <td width={156}>
                          <p>9 mars 2024</p>
                        </td>
                        <td width={156}>
                          <p>N/A</p>
                        </td>
                        <td width={156}>
                          <p>À déterminer</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    *&nbsp;<strong>REMARQUE IMPORTANTE&nbsp;:</strong>&nbsp;Seuls les sommets
                    (définis ci-dessous) qui obtiennent l’un&nbsp;(1) des dix&nbsp;(10)&nbsp;totaux
                    de points les plus élevés (comme cela est défini ci-dessous) à la fin de la
                    période de mise en candidature et de participation et chacune de leurs équipes
                    respectives (comme cela est défini ci-dessous) passeront à la période de vote au
                    Canada. Les sommets qui obtiennent l’un&nbsp;(1) des trois (3) totaux de points
                    les plus élevés (comme cela est défini ci-dessous) à la clôture de la période de
                    vote au Canada, et chacune de leurs équipes respectives (comme cela est défini
                    ci-dessous), seront jugés au cours de la période d’évaluation, qui commence le 4
                    mars 2024 à 0&nbsp;h (HAE) et se termine le 8 mars 2024 à 23&nbsp;h&nbsp;59
                    (HAE). Le sommet gagnant sera ensuite désigné par le jury, qui en dévoilera le
                    nom le 9 mars 2024, entre TBD (HNE).
                  </p>
                </>

                <ol>
                  <li>
                    <strong>ADMISSIBILITÉ&nbsp;:</strong>
                  </li>
                </ol>
                <p>
                  Le concours s’adresse uniquement aux résidents du Canada ayant atteint l’âge de la
                  majorité dans leur province ou territoire de résidence au moment de l’inscription.
                  N’y sont pas admissibles les employés actuels et retraités (de même que les
                  personnes avec ou sans lien de parenté avec qui ils vivent) de Mackenzie Financial
                  Corporation (l’«&nbsp;<strong>organisateur</strong>
                  &nbsp;»), ses représentants, mandataires, agents, commanditaires, sociétés mères,
                  sociétés affiliées, filiales, fournisseurs de prix et agences publicitaires, ainsi
                  que toute autre personne ou entité participant à la création, à la production, à
                  la mise en œuvre, à l’administration ou à l’exécution du concours (collectivement,
                  les «&nbsp;
                  <strong>parties du concours</strong>&nbsp;»). Pour plus de certitude et pour
                  éviter tout doute, les employés de Placements Mackenzie ne sont pas admissibles au
                  concours (et ne sont donc pas admissibles à s’inscrire dans le cadre d’une équipe
                  admissible, à gagner des points pour un sommet ni à voter pour le concours).
                </p>
                <ol>
                  <li>
                    <strong>
                      ACCORD D’ÊTRE JURIDIQUEMENT LIÉ PAR LES RÈGLEMENTS OFFICIELS&nbsp;:
                    </strong>
                  </li>
                </ol>
                <p>
                  En participant à ce concours, vous confirmez avoir lu et accepté ces règlements
                  officiels (les «&nbsp;<strong>règlements officiels</strong>
                  &nbsp;») et acceptez d’être légalement lié par ceux-ci.
                </p>
                <ol>
                  <li>
                    <strong>
                      INSTAGRAM NE PARTICIPE PAS À L’ADMINISTRATION DU CONCOURS&nbsp;:
                    </strong>
                  </li>
                </ol>
                <p>
                  Le concours n’est en aucune façon parrainé, approuvé ou administré par Instagram
                  (une «&nbsp;<strong>plateforme sociale</strong>&nbsp;») et n’est associé à cette
                  plateforme. La plateforme sociale en question est par la présente dégagée de toute
                  responsabilité relative à toutes les participations au concours. Toute question,
                  tout commentaire et toute plainte concernant le concours doivent être acheminés
                  directement à l’organisateur, et non à une plateforme sociale. Pour pouvoir
                  participer par l’intermédiaire d’une plateforme sociale, vous devez avoir
                  configuré votre compte comme public et non comme privé.
                </p>
                <ol>
                  <li>
                    <strong>COMMENT PARTICIPER&nbsp;:</strong>
                  </li>
                </ol>
                <p>
                  AUCUN ACHAT REQUIS. Les sommets admissibles, dont la liste se trouve à
                  l’Annexe&nbsp;A (chacun, un «&nbsp;<strong>sommet</strong>&nbsp;»), permettront de
                  gagner des points (les «&nbsp;<strong>points</strong>&nbsp;») en fonction de
                  l’inscription de leurs équipes admissibles (chacune, une «&nbsp;
                  <strong>équipe</strong>&nbsp;») et de leur participation au concours durant la
                  période du concours, comme cela est indiqué ci-dessous.
                </p>
                <p>
                  <strong>(I) ENREGISTREMENT</strong>
                </p>
                <p>
                  Pour participer au concours, vous devez vous inscrire au concours, dans une équipe
                  admissible. Limite d’une&nbsp;(1)&nbsp;équipe par participant ou participante.
                  Pour être admissible, une équipe doit&nbsp;: (i) satisfaire aux critères
                  d’admissibilité énoncés à la règle&nbsp;2; et (ii) désigner un&nbsp;(1) membre de
                  l’équipe pour agir à titre de représentant autorisé de l’équipe (le «&nbsp;
                  <strong>représentant de l’équipe</strong>&nbsp;»), qui doit être autorisé par
                  chaque membre de l’équipe au moment de l’inscription et demeurer autorisé pendant
                  et après le concours pour&nbsp;: (i) fournir à l’organisateur tous les
                  renseignements nécessaires concernant l’équipe et ses membres aux fins de
                  l’administration du concours conformément aux présents règlements; (ii) s’inscrire
                  au concours au nom de l’équipe; et (iii) accepter d’être juridiquement lié par les
                  présents règlements au nom de chaque membre de l’équipe.
                </p>
                <p>
                  Pour s’inscrire au concours, le représentant de l’équipe doit visiter
                  fr.mackenzietoppeak.ca/ (le «&nbsp;<strong>site Web</strong>&nbsp;») et suivre les
                  instructions à l’écran pour obtenir le formulaire officiel de participation (le
                  «&nbsp;<strong>formulaire de participation</strong>
                  &nbsp;») et le remplir en fournissant tous les renseignements requis,
                  notamment&nbsp;: (i) le nom d’un&nbsp;(1) sommet, pour lequel l’équipe choisit de
                  gagner des points pendant le concours; (ii) le nom de l’équipe; (iii) le nom du
                  représentant de l’équipe; (iv) la ville de résidence, l’adresse de courriel et le
                  numéro de téléphone valides du représentant de l’équipe, son année de naissance et
                  son adresse postale complète (y compris le code postal); (v) le nom de
                  l’organisation ou de l’organisme de bienfaisance choisi par l’équipe (défini
                  ci-dessous); et (vi) la réponse aux questions présentées à l’écran concernant
                  l’équipe et le choix de l’organisation ou de l’organisme de bienfaisance de
                  l’équipe (défini ci-dessous). Une fois qu’un représentant d’équipe a rempli le
                  formulaire de participation avec tous les renseignements requis, il doit confirmer
                  que lui et chacun des membres de son équipe ont lu les règlements et acceptent d’y
                  être liés, et ensuite suivre les instructions à l’écran pour soumettre le
                  formulaire de participation rempli (l’«&nbsp;<strong>enregistrement</strong>
                  &nbsp;»).
                  <br /> Pour être admissible, l’enregistrement doit être envoyé et reçu
                  conformément aux présents règlements, durant la période d’inscription. Limite
                  d’une&nbsp;(1)&nbsp;inscription par équipe. Dans les
                  quarante-huit&nbsp;(48)&nbsp;heures suivant la réception d’une inscription, un
                  représentant désigné de l’organisateur examinera les renseignements reçus. Si
                  l’inscription est approuvée (selon la décision de l’organisateur, à sa seule et
                  entière discrétion), l’équipe ainsi inscrite sera autorisée à participer au
                  concours. (<strong>REMARQUE</strong>&nbsp;: La vérification et l’approbation de
                  l’organisateur à cette étape&nbsp;: a) ne limitent pas sa capacité à vérifier
                  et/ou à disqualifier un participant ou une participante, une équipe ou du matériel
                  de participation (définis ci-dessous) en tout temps ou pour quelque raison que ce
                  soit, conformément à la règle&nbsp;7 ci-dessous; b) ne créent aucune
                  responsabilité pour les renonciataires (définis ci-dessous); c) n’annulent pas ou
                  ne limitent pas les obligations d’une équipe (notamment celles de son représentant
                  d’équipe), qui sont énoncées dans les présents règlements. Pour plus de certitude,
                  une telle approbation ne constitue pas une déclaration, une garantie ni tout autre
                  type d’assurance qu’une équipe (y compris, mais sans s’y limiter, son
                  représentant) respecte les présents règlements.
                </p>
                <p>
                  Avant de terminer son inscription, chaque équipe doit sélectionner
                  un&nbsp;(1)&nbsp;organisme ou une organisation de bienfaisance admissible qui peut
                  recevoir le grand prix (défini ci-dessous), conformément au processus de sélection
                  du gagnant énoncé à la règle&nbsp;11.
                  <br />{" "}
                  <strong>
                    <u>
                      Pour être admissible, un organisme ou une organisation de bienfaisance doit
                    </u>
                  </strong>
                  &nbsp;: (i)&nbsp;être un organisme de bienfaisance local enregistré ou un
                  organisme à but non lucratif qui sert la communauté de ski dans laquelle se trouve
                  le sommet de l’équipe (par exemple, il peut s’agir d’une fondation de bienfaisance
                  locale enregistrée, d’un club de ski communautaire pour les jeunes ou d’un
                  organisme semblable qui appuie les activités et les initiatives liées au ski);
                  (ii)&nbsp;ne pas être un particulier ou une entité (autre qu’une entité de
                  bienfaisance enregistrée); (iii)&nbsp;avoir donné au représentant de l’équipe
                  l’autorisation d’être désigné comme organisme ou organisation de bienfaisance
                  choisi par l’équipe et soumettre tous les renseignements requis à propos de
                  l’organisme ou l’organisation de bienfaisance à l’organisateur aux fins du
                  concours; et la confirmation que l’organisme ou l’organisation de bienfaisance
                  consent à accepter le grand prix (défini ci-dessous) et de signer le formulaire de
                  déclaration et de renonciation de l’organisateur dans le cas où l’organisme ou
                  l’organisation est admissible à recevoir ce prix –&nbsp;
                  <strong>REMARQUE IMPORTANTE</strong>&nbsp;: Pour être admissible, l’autorisation
                  de l’organisme ou l’organisation de bienfaisance choisi par l’équipe doit être
                  confirmée par le représentant de l’équipe (y compris, mais sans s’y limiter, sous
                  la forme d’une autorisation écrite reçue d’un représentant autorisé désigné de
                  l’organisme ou l’organisation de bienfaisance concerné) à tout moment, si
                  l’organisateur le demande, avant la fin de la période du concours; et
                  (iii)&nbsp;être vérifié et approuvé par l’organisateur, à sa seule discrétion
                  (un&nbsp;
                  <strong>«&nbsp;organisme ou organisation de bienfaisance&nbsp;»</strong>). Pour
                  plus de certitude et pour éviter tout doute, l’organisateur se réserve le droit, à
                  sa seule discrétion, de disqualifier une organisation ou un organisme de
                  bienfaisance sélectionné à tout moment et pour toute raison jugée nécessaire (y
                  compris, mais sans s’y limiter, pendant le processus de sélection et de
                  notification des gagnants admissibles, comme cela est indiqué dans les
                  règles&nbsp;11 à&nbsp;13 ci-dessous). Limite d’un&nbsp;(1)&nbsp;organisme ou
                  organisation de bienfaisance par enregistrement. En soumettant une inscription, le
                  représentant de l’équipe s’engage à faciliter l’attribution du grand prix (défini
                  ci-dessous) à l’organisme ou organisation de bienfaisance sélectionné par son
                  équipe (dans le cas où l’organisation ou organisme de bienfaisance est admissible
                  à recevoir ce prix, conformément au processus de sélection des gagnants indiqué
                  ci-dessous). Pour plus de certitude, cela comprend le soutien apporté à
                  l’organisateur pour la coordination d’un représentant autorisé désigné de
                  l’organisme de bienfaisance ou de l’organisme pour mener à bien le processus de
                  confirmation du gagnant énoncé à la règle&nbsp;13 ci-dessous).
                </p>
                <p>
                  <strong>* REMARQUE IMPORTANTE&nbsp;:</strong>&nbsp;Une&nbsp;(1) seule équipe
                  pourra participer au nom de chaque sommet. Si une deuxième équipe s’inscrit au nom
                  de ce sommet, un représentant ou une représentante de l’organisateur communiquera
                  avec vous afin de regrouper les équipes sous le sommet respectif.
                </p>
                <p>
                  Après avoir rempli et soumis une inscription conformément aux exigences ci-dessus,
                  l’équipe doit suivre les instructions à l’écran pour soumettre au moins
                  un&nbsp;(1)&nbsp;fichier multimédia (photo ou vidéo) admissible (chacun, un
                  «&nbsp;<strong>fichier multimédia admissible</strong>&nbsp;») sur la page de
                  sommet de l’équipe. Chaque fichier multimédia admissible doit&nbsp;: (i)
                  représenter, décrire ou communiquer d’une certaine manière le thème du sommet
                  choisi par l’équipe ou porter sur le thème de l’hiver; (ii) respecter les
                  exigences des présents règlements, y compris, sans s’y limiter, les exigences de
                  participation spécifiques énumérées ci-dessous à la règle&nbsp;8; et (iii) suivre
                  les lignes directrices ci-dessous&nbsp;:
                </p>
                <ul>
                  <li>Être original</li>
                  <li>
                    Les vidéos doivent être en format AVI, DV, MOV, QT, MPG, MPG2, MPG4, 3GP, 3G2,
                    ASF ou WMV
                  </li>
                  <li>La taille des vidéos ne doit pas dépasser 100&nbsp;Mo</li>
                  <li>La durée des vidéos ne doit pas dépasser 2&nbsp;minutes</li>
                  <li>Les photos doivent être en format JPEG, JPG ou PNG</li>
                  <li>La taille des photos ne doit pas dépasser 100&nbsp;Mo</li>
                </ul>
                <p>
                  <strong>(II) GAGNER DES POINTS</strong>
                </p>
                <p>
                  Après avoir rempli et soumis une inscription conformément aux présents règlements,
                  les équipes pourront gagner des points pour leurs sommets respectifs (comme cela
                  est indiqué dans le formulaire de participation de chaque équipe) au cours de la
                  période de mise en candidature et de participation, comme suit&nbsp;:
                </p>
                <ol>
                  <li>
                    <strong>
                      <u>Téléchargement de vidéos</u>
                    </strong>
                    <strong>:</strong>&nbsp;Chaque équipe peut télécharger une&nbsp;(1) vidéo sur la
                    page Web de son sommet, sur le site fr.mackenzietoppeak.ca/. Cette vidéo devrait
                    en révéler davantage sur les membres de l’équipe, le sommet au nom duquel ces
                    personnes concourent et l’organisme ou l’organisation de bienfaisance à qui le
                    prix sera remis. Cette vidéo permettra à l’équipe de gagner 1000&nbsp;points. Si
                    la vidéo comprend une explication des changements que le sommet apportera aux
                    pratiques durables, 250 points supplémentaires seront attribués. Cette décision
                    sera laissée à l'appréciation des juges.
                  </li>
                  <li>
                    <strong>
                      <u>Mise en candidature</u>
                    </strong>
                    <strong>:</strong>&nbsp;Pendant la période de mise en candidature et de
                    participation, les participants et participantes admissibles pourront visiter le
                    site Web et soumettre une mise en candidature (chacune, une&nbsp;
                    <strong>« mise en candidature »</strong>) pour leur équipe préférée en suivant
                    les instructions à l’écran. Pour chaque mise en candidature reçue par une
                    équipe, le sommet sélectionné par l’équipe (tel que cela est indiqué dans
                    l’inscription admissible de l’équipe) gagnera dix&nbsp;(10) points. Il n’y a pas
                    de limite au nombre de mises en candidature pouvant être reçues par équipe ou au
                    nombre de points respectifs qui peuvent être reçus par un sommet à l’aide de
                    cette méthode. Toutefois, il y a une limite d’une mise en candidature par
                    équipe, par personne, pouvant être soumise. Si l’organisateur découvre (à l’aide
                    de preuves ou de toute information qui lui serait remise ou qu’il obtient par
                    ses propres moyens) qu’une personne a tenté&nbsp;: (i)&nbsp;de dépasser les
                    limites de mise en candidature indiquées dans les présents règlements;
                    (ii)&nbsp;d’utiliser un programme automatisé, de script, de macro, de robots ou
                    tout autre programme pour soumettre des mises en candidature; et/ou
                    (iii)&nbsp;de procéder à une mise en candidature systématique répétée à partir
                    de la même adresse&nbsp;IP ou du même ordinateur; l’organisateur se réserve
                    alors le droit, à sa seule discrétion, de disqualifier les mises en candidature
                    et/ou les points auxquels ces mises en candidature se rapportent. Les
                    participants et participantes peuvent en encourager d’autres à présenter une
                    mise en candidature pour une équipe; mais aucune forme d’encouragement, de
                    bénéfice, de prix ou de chance de recevoir un tel avantage ou prix ne peut être
                    offert en contrepartie d’une mise en candidature pour une équipe. Toute personne
                    et/ou équipe (tel que cela est déterminé par l’organisateur, à sa seule
                    discrétion) qui adopte un tel comportement sera soumise à une disqualification,
                    ainsi que les mises en candidature et/ou les points correspondants auxquels ces
                    mises en candidature se rapportent.
                  </li>
                  <li>
                    <strong>
                      <u>Défis</u>
                    </strong>
                    <strong>:</strong>&nbsp;Pendant la période de mise en candidature et de
                    participation, quatre&nbsp;(4) défis d’équipe seront publiés sur le site Web et
                    sur les médias sociaux de l’organisateur. Ce dernier publiera sur le site Web et
                    les médias sociaux des instructions détaillées sur la façon de relever chaque
                    défi (chacun, un&nbsp;<strong>« défi »</strong>). Les participants et
                    participantes peuvent gagner des points pour un sommet en relevant l’un ou
                    l’ensemble des défis pendant la période de mise en candidature et de
                    participation. Chaque défi exigera des participants et participantes qu’ils et
                    qu’elles publient un message sur le fil Instagram à partir de leur compte
                    applicable. Le message (i)&nbsp;comprend le mot-clic du défi applicable, tel que
                    cela est indiqué sur le site Web par l’organisateur (ii),&nbsp;comprend un
                    mot-clic de sommet et (iii)&nbsp;reflète le thème pertinent, et comprend toute
                    autre exigence pour ce défi, tel que cela est indiqué sur le site Web par
                    l’organisateur (une&nbsp;<strong>« publication de défi »</strong>). Le sommet
                    associé au mot-clic du sommet indiqué dans une publication de défi gagnera
                    cent&nbsp;(100) points par publication de défi, sous réserve d’une limite de
                    cent&nbsp;(100) points par cette méthode, par compte et par jour.
                  </li>
                </ol>
                <p>
                  <strong>REMARQUE IMPORTANTE&nbsp;:&nbsp;</strong>Pour être admissibles, le contenu
                  et le matériel associés à un formulaire de participation, l’inscription, les
                  fichiers multimédias, les messages, les billets de défi, le vote et/ou toute autre
                  information fournie (ou censée l’être) aux fins du présent concours
                  (collectivement, le «&nbsp;
                  <strong>matériel de participation</strong>&nbsp;») doivent&nbsp;: (i) être soumis
                  et reçus conformément aux présents règlements; (ii) comprendre tous les éléments
                  et le matériel indiqués ci-dessus; (iii) être conformes aux présents règlements, y
                  compris, sans s’y limiter, les exigences de participation spécifiques énumérées
                  ci-dessous à la règle&nbsp;8; et (iv) être conformes aux modalités, règles,
                  politiques et lignes directrices applicables d’une plateforme sociale (les «&nbsp;
                  <strong>règles de la plateforme sociale</strong>&nbsp;»), le cas échéant (comme le
                  détermine l’organisateur à sa seule et entière discrétion). Pour plus de certitude
                  et pour éviter tout doute, les points seront attribués uniquement aux sommets du
                  concours, et non aux équipes individuelles. Les équipes ne peuvent recevoir que
                  des votes, comme cela est indiqué ci-dessus. Pour les utilisateurs qui choisissent
                  de participer au concours à l’aide d’un appareil mobile, les frais de données
                  standard s’appliquent. Pour en savoir plus sur les tarifs et les détails de votre
                  forfait, veuillez communiquer avec votre fournisseur de services avant de
                  participer.
                </p>
                <p>
                  <strong>(III) VOTE AU CANADA</strong>
                </p>
                <p>
                  Le 5 février 2024 à 23 h 59 (HNE), les dix&nbsp;(10) finalistes seront annoncés
                  sur le site Web et sur les médias sociaux de l’organisateur. Les dix&nbsp;(10)
                  finalistes accéderont ensuite à la période de vote au Canada À [heure à
                  déterminer]&nbsp;(HE), pendant laquelle ils gagneront des points supplémentaires
                  pour chaque vote en faveur de leur sommet. Seuls les dix&nbsp;(10) finalistes
                  pourront participer à cette étape.&nbsp;
                </p>
                <ol>
                  <li>
                    <strong>
                      <u>Votes</u>
                    </strong>
                    <strong>:</strong>&nbsp;Pendant la période de vote au Canada, les participants
                    et participantes admissibles pourront visiter le site Web et soumettre un vote
                    (chacun, un «&nbsp;<strong>vote</strong>&nbsp;») pour leur équipe préférée en
                    suivant les instructions à l’écran. Pour chaque vote reçu par une équipe, le
                    sommet sélectionné de l’équipe (tel que cela est indiqué dans l’inscription
                    admissible de l’équipe) gagnera un&nbsp;point. Il n’y a pas de limite au nombre
                    de votes pouvant être reçus par équipe ou au nombre de points respectifs pouvant
                    être reçus par un sommet à l’aide de cette méthode. Toutefois, il y a une limite
                    d’un vote par équipe par personne, par jour pouvant être soumis. Si
                    l’organisateur découvre (à l’aide de preuves ou de toute information qui lui
                    serait remise ou qu’il obtient par ses propres moyens) qu’une personne a
                    tenté&nbsp;: (i)&nbsp;de dépasser les limites de vote indiquées dans les
                    présents règlements; (ii)&nbsp;d’utiliser un programme automatisé, de script, de
                    macro, de robots ou tout autre programme pour soumettre des votes; et/ou
                    (iii)&nbsp;de procéder à un vote systématique répété à partir de la même
                    adresse&nbsp;IP ou du même ordinateur; l’organisateur se réserve alors le droit,
                    à sa seule discrétion, de disqualifier les votes et/ou les points auxquels ces
                    votes se rapportent. Les participants et participantes peuvent en encourager
                    d’autres à voter pour une équipe; mais aucune forme d’encouragement, de
                    bénéfice, de prix ou de chance de recevoir un tel avantage ou prix ne peut être
                    offert en contrepartie d’un vote pour une équipe. Toute personne et/ou équipe
                    (tel que cela est déterminé par l’organisateur, à sa seule discrétion) qui
                    adopte un tel comportement sera soumise à une disqualification, ainsi que les
                    votes et/ou les points correspondants auxquels ces votes se rapportent.
                  </li>
                </ol>
                <ol>
                  <li>
                    <strong>CONDITIONS DE PARTICIPATION&nbsp;:</strong>
                  </li>
                </ol>
                <p>
                  Si l’organisateur découvre (à l’aide de preuves ou de toute information qui lui
                  serait remise ou qu’il obtient par ses propres moyens) qu’une personne a
                  tenté&nbsp;: (i)&nbsp;de dépasser les limites spécifiées dans les présents
                  règlements; (ii)&nbsp;d’utiliser plusieurs noms, identités, adresses de courriel,
                  comptes et/ou système automatisé ou robotisé, un script, une macro ou tout autre
                  moyen ou programme afin de participer au concours ou de perturber son
                  fonctionnement; et/ou de participer au concours d’une autre manière frauduleuse ou
                  trompeuse, il pourrait alors être disqualifié, à la seule discrétion de
                  l’organisateur. Les parties du concours, Instagram Inc. et chacun de leurs agents,
                  employés, directeurs, officiers, successeurs et ayant-droits respectifs
                  (collectivement, les «&nbsp;
                  <strong>renonciataires</strong>&nbsp;») n’acceptent aucune responsabilité
                  concernant tout matériel de participation en retard, perdu, mal acheminé, retardé,
                  incomplet ou incompatible (considéré comme annulé). Le matériel de participation
                  peut être rejeté si, à la seule discrétion de l’organisateur, s’il n’est pas
                  soumis et reçus conformément aux présents règlements (y compris, mais sans s’y
                  limiter, les exigences de participation énumérées ci-dessous dans la règle&nbsp;8
                  et/ou les règles applicables de la plateforme sociale).
                </p>
                <ol>
                  <li>
                    <strong>PROCESSUS DE VÉRIFICATION&nbsp;:</strong>
                  </li>
                </ol>
                <p>
                  Le matériel de participation, les équipes, les représentants d’équipe, l’organisme
                  ou l’organisation de bienfaisance, les personnes qui votent et les participantes
                  et participants peuvent être soumis à une vérification à tout moment et pour
                  quelque raison que ce soit. L’organisateur se réserve le droit, à sa seule et
                  entière discrétion, d’exiger une preuve d’identité et/ou d’admissibilité (sous une
                  forme acceptable pour l’organisateur du concours, y compris, sans s’y limiter, une
                  pièce d’identité délivrée par une entité gouvernementale)&nbsp;: (i)&nbsp;afin de
                  valider l’admissibilité d’un participant ou d’une participante au concours;
                  (ii)&nbsp;afin de valider l’admissibilité et/ou la légitimité du matériel de
                  participation, des équipes, des représentants d’équipe, des organismes ou des
                  organisations de bienfaisance et/ou des données pertinentes entrées (ou
                  présumément entrées) dans le cadre du présent concours; et/ou (iii)&nbsp;pour
                  toute autre raison jugée nécessaire par l’organisateur, à sa seule discrétion, aux
                  fins d’administration du concours, conformément aux dispositions et à l’esprit des
                  présents règlements officiels. Le refus ou l’échec de présenter une telle preuve
                  de manière satisfaisante dans les délais indiqués par l’organisateur risque
                  d’entraîner, à la seule discrétion de l’organisateur, une disqualification des
                  votes, des points, de l’inscription, de l’organisme ou de l’organisateur de
                  bienfaisance et/ou de l’équipe en question, selon le cas. La date et l’heure
                  devant servir à établir la validité d’une participation au concours seront
                  déterminées uniquement par le ou les dispositifs de mesure du temps utilisés par
                  l’organisateur.
                </p>
                <ol>
                  <li>
                    <strong>EXIGENCES DE PARTICIPATION&nbsp;:</strong>
                  </li>
                </ol>
                <p>
                  EN PARTICIPANT AU CONCOURS, VOUS CONVENEZ QUE LE MATÉRIEL DE PARTICIPATION EST
                  CONFORME À TOUTES LES CONDITIONS ÉNONCÉES DANS LES PRÉSENTS RÈGLEMENTS OFFICIELS.
                  LES RENONCIATAIRES N’ASSUMENT AUCUNE RESPONSABILITÉ CONCERNANT LES ÉLÉMENTS
                  SUIVANTS&nbsp;: (I) L’UTILISATION DE VOTRE MATÉRIEL DE PARTICIPATION (OU DE TOUT
                  COMPOSANT DE CELUI-CI); (II) LA PARTICIPATION À TOUTE ACTIVITÉ RELATIVE AU
                  CONCOURS; (III) L’UTILISATION, LA COLLECTE, LE STOCKAGE ET LA DIVULGATION DE
                  RENSEIGNEMENTS PERSONNELS; ET/OU (IV) TOUT PRIX, LE CAS ÉCHÉANT (Y COMPRIS TOUTE
                  UTILISATION, CORRECTE OU INCORRECTE DE CELUI-CI). LES RENONCIATAIRES NE POURRONT
                  ÊTRE TENUS RESPONSABLES SI L’ON DÉCOUVRE ULTÉRIEUREMENT QUE VOUS N’AVEZ PAS
                  RESPECTÉ, EN TOTALITÉ OU EN PARTIE, UN OU PLUSIEURS DES PRÉSENTS RÈGLEMENTS OU LES
                  RÈGLES DE LA PLATEFORME SOCIALE UTILISÉE. LES PRÉSENTES RENONCIATIONS RESTERONT EN
                  VIGUEUR MÊME APRÈS LA FIN DU CONCOURS ET/OU LA REMISE DE TOUT PRIX.&nbsp;
                </p>
                <p>
                  En participant au concours, chaque participant et chaque participante garantit et
                  déclare par la présente que le matériel de participation qu’il ou qu’elle
                  soumet&nbsp;:
                </p>
                <ol>
                  <li>
                    ne contient aucune image ni ressemblance de marques ou de produits qui sont des
                    concurrents de l’organisateur;
                  </li>
                  <li>
                    a été créé par lui-même, et qu’il a obtenu toutes les permissions nécessaires
                    concernant le matériel pour les fins de participation au concours (y compris,
                    mais sans s’y limiter, les consentements ou les autorisations nécessaires d’un
                    organisme ou d’une organisation de bienfaisance associé à son matériel de
                    participation);
                  </li>
                  <li>ne viole aucun règlement, loi ni décret applicables;</li>
                  <li>
                    ne contient aucune référence ni ressemblance attribuable un tiers identifiable,
                    à moins que celui-ci (ou son parent ou tuteur légal, si cette personne est
                    mineure là où elle habite) ait accordé sa permission;
                  </li>
                  <li>
                    ne donnera lieu à aucune réclamation, y compris, mais sans s’y limiter, toute
                    réclamation de violation de droits d’auteur ou d’atteinte à la vie privée; ne
                    viole ni les droits ni les intérêts d’un tiers; ne présente aucun risque de
                    poursuite judiciaire quelconque; et
                  </li>
                  <li>
                    n’est nullement diffamatoire, pornographique ni obscène et ne contient, présente
                    ou affiche, sans s’y limiter, un ou plusieurs des éléments suivants&nbsp;:
                    Violence; nudité; consommation d’alcool, de drogue ou de tabac; activité
                    sexuelle; connotation sexuelle; langage ou symbole grossier, vulgaire ou
                    offensif; caractérisation dérogatoire de tout groupe ethnique, race, sexe,
                    orientation sexuelle, religion ou tout autre type de groupe (y compris, mais
                    sans s’y limiter, les concurrents de l’organisateur; contenu qui appuie, tolère,
                    illustre et/ou mentionne tout comportement illégal, inapproprié ou risqué;
                    renseignements personnels comme, sans s’y limiter, des noms, des numéros de
                    téléphone et des adresses (civiques ou électroniques); messages de nature
                    commerciale, comparaisons ou sollicitations concernant des produits ou des
                    services autres que ceux de l’organisateur du concours; produits, marques de
                    commerce, marques et/ou logos autres que ceux de l’organisateur (à moins d’avoir
                    obtenu les consentements nécessaires); comportement ou activité qui vont à
                    l’encontre des présents règlements; et/ou tout autre contenu qui est, ou
                    pourrait être considéré comme, faux, trompeur, inapproprié ou offensif
                    (l’organisateur du concours se réserve le droit, à sa seule discrétion, de
                    déterminer la nature du contenu des participations reçues).
                  </li>
                </ol>
                <p>
                  L’organisateur et/ou son agence de promotion ou son modérateur désigné (le «&nbsp;
                  <strong>modérateur&nbsp;</strong>») se réservent le droit de passer en revue tout
                  le matériel de participation. Tout matériel de participation qui, selon le
                  modérateur, à sa seule discrétion, viole les conditions d’utilisation définies
                  dans les présents règlements, pourra être disqualifié. Chaque participant ou
                  participante s’engage par les présentes à indemniser et à dégager de toute
                  responsabilité les renonciataires concernant toute réclamation contraire aux
                  déclarations et aux garanties ci-dessus. Le modérateur se réserve le droit, à sa
                  seule discrétion, en tout temps pour toute raison, de supprimer tout matériel de
                  participation (ou une partie de celui-ci) et/ou de demander à un participant ou à
                  une participante de modifier ou de soumettre à nouveau son matériel de
                  participation (ou une partie de celui-ci) afin de s’assurer que le matériel de
                  participation soit conforme aux présents règlements, ou pour toute autre raison.
                  Si une telle action s’avère nécessaire à un certain point durant ou après le
                  concours, alors l’organisateur se réserve le droit, à sa seule discrétion,
                  d’entreprendre toute mesure qu’il juge nécessaire en fonction des circonstances (y
                  compris, mais sans s’y limiter, la disqualification du matériel de participation
                  et, par conséquent, l’équipe, les votes et/ou les participantes ou participants
                  correspondants) afin de s’assurer que le concours se déroule conformément aux
                  dispositions et à l’esprit des présents règlements.
                </p>
                <ol>
                  <li>
                    <strong>DROITS ACCORDÉS&nbsp;:</strong>
                  </li>
                </ol>
                <p>
                  En participant au concours et en soumettant du matériel de participation, tous les
                  participants et toutes les participantes&nbsp;: (i)&nbsp;accordent à
                  l’organisateur, sans limiter les règles applicables des plateformes sociales, le
                  cas échéant, un droit perpétuel, non exclusif et à l’échelle mondiale de publier,
                  d’afficher, de reproduire, de modifier, de partager avec le grand public à l’aide
                  de systèmes de télécommunications et d’utiliser d’une quelconque manière son
                  matériel de participation (et chacun de ses composants), en tout ou en partie, à
                  des fins de publicité ou de promotion du concours ou pour toute autre raison, sous
                  quelque forme que ce soit; (ii)&nbsp;renoncent à tout droit moral concernant son
                  matériel de participation (et chacun de ses composants) en faveur de
                  l’organisateur (et toute personne ou entité autorisée par l’organisateur pour
                  utiliser le matériel de participation en question); et (iii)&nbsp;acceptent
                  d’indemniser les renonciataires de tout coût, réclamation, responsabilité, dommage
                  et dépense découlant de l’utilisation de son matériel de participation (ou d’une
                  partie de celui-ci), y compris, mais sans s’y limiter, toute forme de réclamation
                  pour non-respect des droits publicitaires, diffamation, atteinte à la vie privée,
                  violation des droits d’auteur, violation des marques de commerce, violation de
                  propriété intellectuelle ou toute autre cause d’action.
                </p>
                <ol>
                  <li>
                    <strong>PRIX&nbsp;:</strong>
                  </li>
                </ol>
                <p>
                  Un total de trois (3) prix (chacun, un «&nbsp;<strong>prix</strong>
                  &nbsp;» et collectivement, les «&nbsp;<strong>prix</strong>&nbsp;») peuvent être
                  gagnés, comme suit&nbsp;:
                </p>
                <ol>
                  <li>
                    <strong>
                      <u>
                        Grand prix (1<sup>re</sup>&nbsp;place)
                      </u>
                    </strong>
                    <strong>:</strong>&nbsp;Un&nbsp;(1)&nbsp;grand prix (le «&nbsp;
                    <strong>grand prix</strong>&nbsp;») peut être gagné, consistant en un chèque de
                    100 000&nbsp;$&nbsp;CA payable à un organisme ou une organisation de
                    bienfaisance admissible.
                  </li>
                  <li>
                    <strong>
                      <u>
                        2<sup>e</sup>&nbsp;place
                      </u>
                    </strong>
                    <strong>:</strong>&nbsp;Un&nbsp;(1) prix pour la deuxième place peut être gagné,
                    consistant en un chèque de 20 000&nbsp;$ CA payable à un organisme ou une
                    organisation de bienfaisance admissible.
                  </li>
                  <li>
                    <strong>
                      <u>
                        3<sup>e</sup>&nbsp;place
                      </u>
                    </strong>
                    <strong>:</strong>&nbsp;Un&nbsp;(1) prix pour la troisième place peut être
                    gagné, consistant en un chèque de 10 000&nbsp;$ CA payable à un organisme ou une
                    organisation de bienfaisance admissible.
                  </li>
                </ol>
                <ol>
                  <li>
                    <strong>PROCESSUS DE SÉLECTION DES SOMMETS GAGNANTS ADMISSIBLES&nbsp;:</strong>
                  </li>
                </ol>
                <p>L’organisateur déterminera les gagnants admissibles comme suit&nbsp;:</p>
                <ol>
                  <li>
                    <strong>
                      <u>Grand prix</u>
                    </strong>
                    <strong>:</strong>&nbsp;Les sommets qui ont obtenu l’un&nbsp;(1) des
                    trois&nbsp;(3)&nbsp;meilleurs pointages au total à la fin de la période du vote
                    au Canada, ainsi que chacune de leurs équipes respectives, seront jugés pendant
                    la période d’évaluation afin de déterminer le gagnant admissible du grand prix.
                    Un groupe de juges (les «&nbsp;
                    <strong>juges du concours</strong>&nbsp;») nommés par l’organisateur, à sa seule
                    discrétion, jugeront les sommets admissibles et lui attribueront un pointage (le
                    «&nbsp;<strong>pointage du sommet</strong>&nbsp;») en fonction des critères
                    pondérés suivants (les «&nbsp;
                    <strong>critères d’évaluation des sommets</strong>&nbsp;»)&nbsp;:
                  </li>
                </ol>
                <table>
                  <tbody>
                    <tr>
                      <td width={289}>
                        <p>
                          <strong>Critères d’évaluation des sommets</strong>
                        </p>
                      </td>
                      <td width={286}>
                        <p>
                          <strong>Pondération</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width={289}>
                        <p>
                          Qualité des inscriptions et des fichiers multimédias admissible de
                          l’équipe associée
                        </p>
                      </td>
                      <td width={286}>
                        <p>60%</p>
                      </td>
                    </tr>
                    <tr>
                      <td width={289}>
                        <p>Nombre de points gagnés</p>
                      </td>
                      <td width={286}>
                        <p>30%</p>
                      </td>
                    </tr>
                    <tr>
                      <td width={289}>
                        <p>Initiative en faveur de l'environnement</p>
                      </td>
                      <td width={286}>
                        <p>10%</p>
                      </td>
                    </tr>
                   
                    <tr>
                      <td width={289}>
                        <p>
                          <strong>Pointage total du sommet</strong>
                        </p>
                      </td>
                      <td width={286}>
                        <p>Maximum 100%</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  L’organisation de bienfaisance ou l’organisme associé à l’équipe ayant obtenu le
                  pointage le plus élevé, en fonction des critères d’évaluation des juges ci-dessus,
                  pourra recevoir le grand prix. Pour plus de certitude et pour éviter tout doute,
                  le grand prix sera attribué uniquement à l’organisme ou à l’organisation de
                  bienfaisance en question, et non à une équipe, à un sommet ou à un représentant
                  d’équipe. Les chances de gagner dépendent du nombre de points et de votes reçus
                  par sommet, et du calibre des inscriptions et des fichiers multimédias admissibles
                  reçus pendant la période du concours.
                </p>
                <ol>
                  <li>
                    <strong>
                      <u>Prix</u>
                    </strong>
                    <strong>:</strong>&nbsp;Le 9 mars 2024 (la&nbsp;
                    <strong>« date du tirage »</strong>) à Toronto, en Ontario, vers TBD (HNE),
                    l’organisateur informera le sommet ayant obtenu le meilleur « pointage du
                    sommet » qu’il a été sélectionné pour recevoir le grand prix de 100 000&nbsp;$.
                  </li>
                  <li>
                    <strong>
                      <u>Prix secondaires</u>
                    </strong>
                    <strong>:</strong>&nbsp;Le 9 mars 2024 (la « <strong>date du tirage</strong>
                     ») à Toronto, en Ontario, vers TBD (HNE), l’organisateur attribuera des prix
                    secondaires aux deux&nbsp;(2) sommets restants parmi les trois&nbsp;(3) premiers
                    au classement. Le total des prix à attribuer est le suivant&nbsp;:
                  </li>
                </ol>
                <table width={1005}>
                  <tbody>
                    <tr>
                      <td width={300}>
                        <p>Rang final des pointages les plus élevés</p>
                      </td>
                      <td width={300}>
                        <p>Valeur du prix ($)</p>
                      </td>
                    </tr>
                    <tr>
                      <td width={300}>
                        <p>
                          2<sup>e</sup>
                        </p>
                      </td>
                      <td width={300}>
                        <p>20 000&nbsp;$ CA</p>
                      </td>
                    </tr>
                    <tr>
                      <td width={300}>
                        <p>
                          3<sup>e</sup>
                        </p>
                      </td>
                      <td width={300}>
                        <p>10 000&nbsp;$ CA</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ol>
                  <li>
                    <strong>
                      <u>Prix tertiaires</u>
                    </strong>
                    <strong>:</strong>&nbsp;Le 9 mars&nbsp;2024 (la &laquo;&thinsp;
                    <strong>date du tirage</strong>&thinsp;&raquo;) &agrave; Toronto, en Ontario,
                    vers TBD (HNE).
                  </li>
                </ol>
                <p>
                  Dans le cas où un gagnant potentiel du prix serait disqualifié pour ne pas s’être
                  conformé aux présents règlements (ou, dans le cas où un organisme ou une
                  organisation de bienfaisance serait disqualifié conformément à la règle&nbsp;5
                  ci-dessus), comme le détermine l’organisateur à sa seule discrétion,
                  l’organisateur se réserve le droit, à sa seule discrétion, de faire ce qui
                  suit&nbsp;: (I)&nbsp;sélectionner l’organisme ou l’organisation de bienfaisance
                  associé à l’équipe qui a obtenu le deuxième pointage le plus élevé, parmi toutes
                  les équipes associées au même sommet ayant obtenu le pointage le plus élevé
                  conformément à la procédure décrite ci-dessus, afin de déterminer un organisme ou
                  une organisation de bienfaisance admissible de remplacement; ou (ii) sélectionner
                  un autre participant ou une autre participante admissible au moyen d’un tirage au
                  sort parmi les autres billets et les billets de défi admissibles reçus
                  conformément aux présents règlements (le cas échéant, et comme le détermine
                  l’organisateur, à sa seule discrétion).
                </p>
                <ol>
                  <li>
                    <strong>PROCESSUS DE NOTIFICATION DES GAGNANTS ADMISSIBLES&nbsp;:</strong>
                  </li>
                </ol>
                <p>
                  L’organisateur ou son représentant désigné effectuera un minimum de trois (3)
                  tentatives pour communiquer avec le gagnant potentiel par téléphone, après
                  l’annonce en direct sur la chaîne de télévision CBC. Si le gagnant potentiel ne
                  peut pas être joint dans un tel délai (pour quelque raison que ce soit, y compris,
                  mais sans s’y limiter, en ce qui concerne le grand prix, si le représentant de
                  l’équipe ne fournit pas les coordonnées exactes et opportunes du gagnant
                  potentiel), ou en cas de notification de message non livrable, alors
                  l’organisateur peut, à sa seule discrétion, le disqualifier (le cas échéant, il
                  perdra tout droit concernant le prix applicable) et se réserve le droit, à sa
                  seule discrétion et si le temps le permet, de sélectionner un autre gagnant
                  potentiel admissible conformément à la procédure de sélection d’un autre gagnant
                  (le cas échéant, les dispositions de cette section s’appliqueront au nouveau
                  gagnant potentiel).&nbsp;
                </p>
                <ol>
                  <li>
                    <strong>PROCESSUS DE CONFIRMATION DES GAGNANTS ADMISSIBLES&nbsp;:</strong>
                  </li>
                </ol>
                <p>
                  PERSONNE NE SERA DÉCLARÉ GAGNANT JUSQU’À CE QUE L’ORGANISATEUR L’AIT
                  OFFICIELLEMENT CONFIRMÉ COMME GAGNANT, CONFORMÉMENT AUX PRÉSENTS RÈGLEMENTS. Avant
                  d’être déclaré GAGNANT D’UN PRIX CONFIRMÉ, chaque gagnant potentiel (dans le cas
                  d’un organisme ou d’une organisation de bienfaisance admissible, un représentant
                  autorisé désigné) devra&nbsp;: a) répondre correctement à une question de
                  connaissance mathématique sans aide d’aucune sorte, mécanique ou autre (qui peut,
                  à la seule et entière discrétion de l’organisateur, être administrée en ligne, par
                  courriel ou par un autre moyen électronique, par téléphone ou dans le formulaire
                  de déclaration et de quittance de l’organisateur); et b) signer et retourner, dans
                  les deux&nbsp;(2)&nbsp;jours ouvrables suivant la notification, le formulaire de
                  déclaration et de quittance, qui (entre autres choses)&nbsp;: (i) confirme le
                  respect des présents règlements; (ii) confirme l’acceptation du prix décerné;
                  (iii) dégage les renonciataires de toute responsabilité concernant le concours, la
                  participation à celui-ci, l’attribution du prix et l’utilisation bonne ou mauvaise
                  du prix ou d’une partie de celui-ci; (iv) confirme l’engagement à indemniser les
                  renonciataires en cas de réclamation, dommage, responsabilité, coûts et charges
                  attribuables à l’utilisation du matériel de participation ou de toute partie de
                  ceux-ci; et (v) établit le consentement à la publication, la reproduction et/ou
                  toute autre utilisation de son nom, du nom du sommet, du nom de l’organisation ou
                  de l’organisme de bienfaisance (le cas échéant), la ville ou le lieu de résidence,
                  la voix, les déclarations concernant le concours, toute image ou ressemblance de
                  sa personne sans préavis ou compensation, dans toute publicité ou annonce créée
                  par l’organisateur ou en son nom, que celle-ci soit imprimée, diffusée par les
                  ondes ou publiée sur Internet, à l’échelle mondiale et à perpétuité.&nbsp;
                </p>
                <p>
                  Si un gagnant potentiel&nbsp;: (a)&nbsp;répond incorrectement à la question
                  d’habileté mathématique; (b)&nbsp;ne retourne pas les documents requis signés dans
                  les délais prévus; (c)&nbsp;ne peut pas ou ne veut pas accepter le/un prix tel
                  qu’il est décerné pour quelque raison que ce soit; et/ou (d)&nbsp;viole les
                  présents règlements officiels (tel que cela est déterminé par l’organisateur, à sa
                  seule discrétion); il sera disqualifié du concours (il perdra alors tout droit
                  quant au prix) et l’organisateur se réserve le droit, à sa seule discrétion et si
                  le temps le permet, de sélectionner un autre gagnant potentiel selon la procédure
                  décrite ci-dessus, avec toute modification nécessaire (le cas échéant, les
                  dispositions de cette section s’appliqueront au nouveau gagnant potentiel). Le
                  refus par un organisme ou organisation de bienfaisance potentiellement gagnant du
                  grand prix d’accepter le grand prix conformément aux instructions de
                  l’organisateur dégage les renonciataires de toute responsabilité et obligation
                  envers cet organisme ou cette organisation, y compris, mais sans s’y limiter,
                  toutes les responsabilités et les obligations à l’égard du grand prix. En aucun
                  cas l’organisateur ne sera tenu de remettre plus de prix que le nombre indiqué
                  dans les présents règlements ou de remettre des prix autrement qu’en conformité
                  avec les présents règlements.
                </p>
                <RulesAppendixA />
              </>
            </div>
            {/* /.section__hint */}
          </div>

          {/* /.section__inner */}
        </div>
        {/* /.shell */}
      </section>
      {/* /.section-blocks */}
    </div>
  );
}

export default RulesFr;
