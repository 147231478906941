import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import RulesAppendixA from "../RulesAppendixA";

function RulesEn() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main rules">
      <div className="parallax parallax--different">
        <img src="/assets/images/decoration-2@2x.jpg" alt="image" />
      </div>
      {/* /.parallax */}

      <div className="hero hero--different hero--size1">
        <div className="shell shell--lg">
          <div className="hero__inner">
            <h2>OFFICIAL CONTEST RULES</h2>
          </div>
          {/* /.hero__inner */}
        </div>
        {/* /.shell */}

        <div className="hero__decoration">
          <img src="/assets/images/temp/mountain-2@2x.png" alt="image" />
        </div>
        {/* /.hero__decoration */}
      </div>
      {/* /.hero */}

      <section className="section-blocks">
        <div className="shell shell--lg">
          <div className="section__inner">
            <div className="section__hint">
              <>
                <p>
                  NO PURCHASE NECESSARY. A PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE YOUR
                  CHANCES OF WINNING.
                </p>
                <ol>
                  <li>KEY DATES:</li>
                </ol>
                <p>
                  The Mackenzie Top Peak Contest (the “Contest”) begins on January 8<sup>th</sup>,
                  2024 at 12:00 a.m. ET and ends on March 9, 2024, at 11:59 p.m. ET (the “Contest
                  Period”).
                </p>
                <p>
                  The Contest will consist of one (1) Nominate &amp; Rally period, one (1) Canada
                  Votes period, and one (1) Judging Period, as outlined in the following table:
                </p>
                <table>
                  <tbody>
                    <tr>
                      <td width={156}>
                        <p>PERIOD</p>
                      </td>
                      <td width={156}>
                        <p>OPEN</p>
                      </td>
                      <td width={156}>
                        <p>CLOSE</p>
                      </td>
                      <td width={156}>
                        <p>TIME (EST)</p>
                      </td>
                    </tr>
                    <tr>
                      <td width={156}>
                        <p>Nominate and Rally</p>
                      </td>
                      <td width={156}>
                        <p>
                          January 8<sup>th</sup>, 2024
                        </p>
                      </td>
                      <td width={156}>
                        <p>
                          February 4<sup>th</sup>, 2024
                        </p>
                      </td>
                      <td width={156}>
                        <p>11:59 PM (EST)</p>
                      </td>
                    </tr>
                    <tr>
                      <td width={156}>
                        <p>Top 10 Finalists</p>
                      </td>
                      <td width={156}>
                        <p>
                          February 5<sup>th</sup>, 2024
                        </p>
                      </td>
                      <td width={156}>
                        <p>N/A</p>
                      </td>
                      <td width={156}>
                        <p>TBD</p>
                      </td>
                    </tr>
                    <tr>
                      <td width={156}>
                        <p>Canada Votes</p>
                      </td>
                      <td width={156}>
                        <p>
                          February 5<sup>th</sup>, 2024
                        </p>
                      </td>
                      <td width={156}>
                        <p>
                          March 3<sup>rd</sup>, 2024
                        </p>
                      </td>
                      <td width={156}>
                        <p>11:59 PM (EST)</p>
                      </td>
                    </tr>
                    <tr>
                      <td width={156}>
                        <p>Top 3 Finalists</p>
                      </td>
                      <td width={156}>
                        <p>
                          March 4<sup>th</sup>, 2024
                        </p>
                      </td>
                      <td width={156}>
                        <p>N/A</p>
                      </td>
                      <td width={156}>
                        <p>TBD</p>
                      </td>
                    </tr>
                    <tr>
                      <td width={156}>
                        <p>Judging Period</p>
                      </td>
                      <td width={156}>
                        <p>
                          March 4<sup>th</sup>, 2024
                        </p>
                      </td>
                      <td width={156}>
                        <p>
                          March 8<sup>th</sup>, 2024
                        </p>
                      </td>
                      <td width={156}>
                        <p>N/A</p>
                      </td>
                    </tr>
                    <tr>
                      <td width={156}>
                        <p>Winner Reveal</p>
                      </td>
                      <td width={156}>
                        <p>
                          March 9<sup>th</sup>,2024
                        </p>
                      </td>
                      <td width={156}>
                        <p>N/A</p>
                      </td>
                      <td width={156}>
                        <p>TBD</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *IMPORTANT NOTE: Only Peaks (defined below) which obtain one (1) of the top ten
                  (10) highest number of total Points (defined below) as of the close of the
                  Nominate &amp; Rally Period, and each of their respective Teams (defined below),
                  will move on to the Canada Votes period. Peaks that obtain one (1) of the top
                  three (3) highest number of total Points (defined below) as of the close of the
                  Canada Votes period, and each of their respective Teams (defined below), will be
                  judged during the Judging period, which begins on March 4<sup>th</sup>, 2024, and
                  ends March 8<sup>th</sup>, 2024, at 11:59 p.m. (ET). The winner will then be
                  decided by the panel of judges, revealing the winner on March 9<sup>th</sup>.
                </p>
                <ol>
                  <li>ELIGIBILITY TO PARTICIPATE:</li>
                </ol>
                <p>
                  The Contest is open only to participation by residents of Canada who have reached
                  the legal age of majority in their province/territory of residence at the time of
                  entry, except employees (current and retired, and those with whom such persons are
                  living, whether related or not) of Mackenzie Financial Corporation (the “Sponsor”)
                  its representatives, mandataries, agents, sponsors, parent companies,
                  subsidiaries, affiliates, prize suppliers, advertising/promotion agencies and any
                  other individual(s), entity or entities involved in the development, production,
                  implementation, administration or fulfilment of the Contest (collectively, the
                  “Contest Parties”). For greater certainty and the avoidance of any doubt,
                  Mackenzie investment employees are not eligible to participate in the Contest (and
                  as such, are not eligible to register as part of an eligible Team, earn Points for
                  a Peak, or Vote in the Contest).
                </p>
                <ol>
                  <li>AGREEMENT TO BE LEGALLY BOUND BY RULES:</li>
                </ol>
                <p>
                  By participating in this Contest, you are signifying your agreement that you have
                  read and agree to be legally bound by these Official Rules and Regulations (the
                  “Rules”).
                </p>
                <ol>
                  <li>INSTAGRAM NOT INVOLVED:</li>
                </ol>
                <p>
                  The Contest is in no way sponsored, endorsed, or administered by, or associated
                  with Instagram (a “Social Platform”). The Social Platform is hereby completely
                  released of all liability by each entrant in this Contest. Any questions, comments
                  or complaints regarding the Contest must be directed to the Sponsor and not to a
                  Social Platform. To be eligible to participate via a Social Platform, your
                  applicable Account must be set to public and not private.
                </p>
                <ol>
                  <li>HOW TO PARTICIPATE:</li>
                </ol>
                <p>
                  NO PURCHASE NECESSARY. Eligible peaks, a list of which is available in Appendix A
                  (each, a “Peak”) will earn points (“Points”) based on their eligible Teams’ (each,
                  a “Team”) registration and participation in the Contest during the Contest Period,
                  as set out below.
                </p>
                <p>(I) REGISTRATION</p>
                <p>
                  To participate in the Contest, you must register for the Contest as part of an
                  eligible Team. There is a limit of one (1) Team per participating hill. To be
                  eligible, a Team must: (i) meet the eligibility criteria to participate in the
                  Contest set out in Rule 2; and (ii) designate one (1) Team member to act as your
                  Team’s authorized representative (the “Team Representative”), who must be
                  authorized by each member of your Team at the time of registration (and must
                  remain authorized during and after the Contest) to: (i) provide to Sponsor any and
                  all required information that relates to the Team and its members for the purposes
                  of administering the Contest in accordance with these Rules; (ii) register for the
                  Contest on behalf of the Team; and (iii) agree to be legally bound by these Rules
                  on behalf of each member of the Team.
                </p>
                <p>
                  To register for the Contest, a Team Representative must visit&nbsp;
                  <a href="http://www.mackenzietoppeak.ca/">www.mackenzietoppeak.ca</a>
                  &nbsp;(the “Website”) and follow the on-screen instructions to obtain the official
                  Contest entry form (the “Entry Form”) and fully complete the Entry Form with all
                  required information, which may include a requirement to enter the: (i) name of
                  one (1) Peak, which the Team will select to earn Points for during the Contest;
                  (ii) Team name; (iii) Team Representative’s name; (iv) Team Representative’s city
                  of residence, valid email address and phone number, year of birth and complete
                  mailing address (including postal code); (v) name of the Team’s selected Charity
                  or Organization (defined below); and (vi) answer to the questions presented
                  on-screen regarding the Team and the Team’s choice of Charity or Organization
                  (defined below). Once a Team Representative has fully completed the Entry Form
                  with all required information, he/she must signify his/her agreement (and each of
                  his/her Team member’s agreement) that they have read and agree to be legally bound
                  by the terms and conditions of these Rules, and follow the on-screen instructions
                  to submit the completed Entry Form (a “Registration”). To be eligible, a
                  Registration must be submitted and received in accordance with these Rules during
                  the Nomination Period. Limit of one (1) Registration per Team. Within forty-eight
                  (48) hours of receipt of a Registration, a designated representative of the
                  Sponsor will review and verify the information received. If the Registration is
                  approved (as determined by the Sponsor, in its sole and absolute discretion), the
                  Team associated with such Registration will be permitted to proceed to participate
                  in the Contest (NOTE: the Sponsor’s verification and approval at this stage: (a)
                  does not limit the Sponsor’s ability to verify and/or disqualify any participant,
                  Team or Entry Materials (defined below) at any time or for any reason in
                  accordance with Rule 7 below; (b) will not create any liability for the Released
                  Parties (defined below); and (c) will not derogate or limit in any way a Team’s
                  (including without limitation its Team Representative’s) obligations as outlined
                  in these Rules. For greater certainty, any such approval does not constitute a
                  representation, warranty, or any other kind of assurance that a Team (including
                  without limitation its Team Representative’s) is in compliance these Rules).
                </p>
                <p>
                  Prior to completing a Registration, each Team must select one (1) eligible charity
                  or organization which may receive the Grand Prize (defined below) in accordance
                  with the winner selection process set out in Rule 11.&nbsp;
                  <u>To be eligible, a selected charity or organization must</u>: (i) be a local
                  registered charity or non-profit organization that serves the ski community in
                  which the Team’s Peak is located (for example, this may be a local registered
                  charitable foundation, community youth ski club, or similar that supports
                  ski-related activities and initiatives); (ii) not be an individual or entity
                  (other than a registered charitable entity); (iii) have provided the Team
                  Representative authorization to be designated as the Team’s selected charity or
                  organization and submit all required information about the charity or organization
                  to the Sponsor for the purposes of the Contest, and confirmation that the charity
                  or organization agrees to accept the Grand Prize (defined below) and execute the
                  Sponsor’s form of declaration and release in the event that the charity or
                  organization is eligible to receive such prize –&nbsp;IMPORTANT NOTE:&nbsp;to be
                  eligible, this authorization from the Team’s selected charity or organization must
                  be confirmed by the Team Representative (including without limitation in the form
                  of a written authorization received from a designated authorized representative of
                  the applicable charity or organization) at any time requested by the Sponsor and,
                  in any event, prior to the close of the Contest Period; and (iii) be verified and
                  approved by the Sponsor, in its sole and absolute discretion (a “Charity or
                  Organization”). For greater certainty and the avoidance of any doubt, the Sponsor
                  reserves the right, in its sole and absolute discretion, to disqualify a selected
                  Charity or Organization at any time and for any reason deemed necessary (including
                  without limitation, during the eligible winner selection and notification process,
                  as set out in Rules 11-13 below). Limit of one (1) Charity or Organization per
                  Registration. By submitting a Registration, the Team Representative agrees to
                  facilitate the Sponsor in awarding the Grand Prize (defined below) to his/her
                  Team’s selected Charity or Organization (in the event that the Charity or
                  Organization is eligible to receive such prize, in accordance with the winner
                  selection process below – for certainty, this includes facilitating the Sponsor
                  with coordinating a designated authorized representative of the Charity or
                  Organization to complete the winner confirmation process set out in Rule 13
                  below).
                </p>
                <p>
                  *IMPORTANT NOTE: Each Peak will only have one (1) team eligible to participate on
                  behalf of that Peak. Should a second Team Register on behalf of that Peak, you
                  will be contacted by a representative from The Sponsor to consolidate teams under
                  the respective Peak.
                </p>
                <p>
                  Upon completing and submitting a Registration in accordance with the requirements
                  above, a Team must follow the on-screen instructions to submit at least one (1)
                  eligible media (photo or video) files (each, an “Eligible Media File”) to the
                  Team’s Peak page. Each Eligible Media File must: (i) depict, describe, or
                  otherwise reflect the Team’s selected Peak or a winter theme; (ii) be in
                  accordance with these Rules, including, but not limited to, the specific
                  Participation Requirements listed below in Rule 8; and (iii) follow the below
                  guidelines:
                </p>
                <ul>
                  <li>Be original;</li>
                  <li>
                    A video must be in avi, dv, mov, qt, mpg, mpg2, mpg4, 3gp, 3g2, asf, or wmv
                    format;
                  </li>
                  <li>A video must not exceed one hundred (100) MB in size;</li>
                  <li>A video must not exceed two (2) minutes in length;</li>
                  <li>A photograph must be in, .jpeg, .jpg or .png format; and,</li>
                  <li>A photograph must not exceed one hundred (100) MB in size.</li>
                </ul>
                <p>(II) EARNING POINTS</p>
                <p>
                  Upon completing and submitting a Registration in accordance with these Rules,
                  Teams will be eligible to earn Points for their respective Peaks (as identified in
                  each Team’s Entry Form) during the Nominate &amp; Rally Period as follows:
                </p>
                <ol>
                  <li>
                    <u>Video Upload</u>: Each Team can upload one (1) video to their Top Peak
                    webpage on the Website. This video should tell Canada more about the team
                    members, the Peak they are competing on behalf, and the Charity or Organization
                    to whom the prize would be donated. This video will earn the Peak 1,000 points.
                    Additionally, if the video includes an explanation of the changes the Peak will
                    make towards sustainable practices, an additional 250 points will be awarded.
                    This will be decided at the discretion of the judges.
                  </li>
                  <li>
                    <u>Nominate:</u>&nbsp;During the Nominate &amp; Rally Period, eligible
                    participants will be able to visit the Website and submit a nomination (each, a
                    “Nomination”) for their favourite Team by following the on-screen instructions.
                    For everyone (1) Nomination received by a Team, the Team’s selected Peak (as
                    identified within the Team’s eligible Registration) will earn ten (10) Points.
                    There is no limit to the number of Nominations that may be received per Team or
                    the number of respective Points that may be received per Peak via this method,
                    however there is a limit of 1 Nomination per Team that may be submitted per
                    person. If it is discovered by the Sponsor (using any evidence or other
                    information made available to or otherwise discovered by the Sponsor) that any
                    person has attempted to: (i) exceed any of the Nomination limits stated in these
                    Rules; (ii) use any automated, script, macro, robotic or other program(s) to
                    submit Nominations; and/or (iii) engage in systematic repeated nominations from
                    the same computer/IP address; the Sponsor reserves the right, in its sole and
                    absolute discretion, to disqualify the Nominations and/or Points to which such
                    Nominations relate. Participants may encourage other eligible participants to
                    nominate a Team; however, no form of incentive, inducement, prize, or chance of
                    receiving any incentive, inducement, or prize may be offered as part of such
                    encouragement in furtherance of receiving such individual’s Nomination for any
                    Team. Any individual and/or Team determined by the Sponsor to be engaging in
                    such behaviour is subject to disqualification (as determined by the Sponsor in
                    its sole and absolute discretion) as well as the corresponding Nominations
                    and/or Points to which such Nominations relate.
                  </li>
                  <li>
                    <u>Challenges:</u>&nbsp;During the Nominate &amp; Rally period, four (4) Team
                    Challenges will be released via the Website and Sponsor Social Media. The
                    Sponsor will publish instructions on the Website and Social Media detailing how
                    to complete each challenge (each, a “Challenge”). Participants may earn Points
                    for a Peak by completing any or all the Challenges during the Nominate &amp;
                    Rally Period. Each Challenge will require participants to post an Instagram feed
                    post from their applicable Account that: (i) includes the applicable Challenge
                    hashtag, as indicated on the Website by the Sponsor; (ii) includes a Peak
                    Hashtag; and (iii) reflects the relevant theme, and includes any other
                    requirements, for such Challenge as indicated on the Website by the Sponsor (a
                    “Challenge Post”). The Peak associated with the Peak Hashtag indicated within a
                    Challenge Post will earn one hundred (100) Points per Challenge Post, subject to
                    a limit of one hundred (100) Points via this method per Account per Day.
                  </li>
                </ol>
                <p>
                  IMPORTANT NOTE:&nbsp;To be eligible, all content and materials associated with an
                  Entry Form, Registration, Eligible Media File, Posts, Challenge Posts, Vote,
                  and/or any other information entered (or purportedly entered) for the purposes of
                  this Contest (collectively, “Entry Materials”) must: (i) be submitted and received
                  in accordance with these Rules; (ii) include all required components and materials
                  noted above; (iii) be in accordance with these Rules, including, but not limited
                  to, the specific Participation Requirements listed below in Rule 8; and (iv) be in
                  accordance with the applicable terms, rules, policies and guidelines of a Social
                  Platform (the “Social Platform Rules”), as applicable (all as determined by
                  Sponsor in its sole and absolute discretion). For greater certainty and the
                  avoidance of any doubt, Points will only be awarded to Peaks in the Contest, not
                  to individual Teams. Teams may only receive Votes, as set out above. Standard data
                  rates apply to participants who choose to participate in the Contest via a mobile
                  device. Please contact your service provider for pricing and service plan
                  information and rates before mobile device participation.
                </p>
                <p>(III) CANADA VOTES</p>
                <p>
                  On February 5<sup>th</sup>, 2024, The Top ten (10) Finalists will be announced on
                  the Website and Sponsor social media. The Top Ten (10) Finalists will then enter
                  the Canada Votes Period, where they will earn additional points for each vote for
                  their Peak. Only the Top Ten (10) Finalists will be eligible to participate in
                  this Period.&nbsp;
                </p>
                <ol>
                  <li>
                    <u>Votes:</u>&nbsp;During the Canada Votes Period, eligible participants will be
                    able to visit the Website and submit a vote (each, a “Vote”) for their favourite
                    Team by following the on-screen instructions. For everyone (1) Vote received by
                    a Team, the Team’s selected Peak (as identified within the Team’s eligible
                    Registration) will earn one point. There is no limit to the number of Votes that
                    may be received per Team or the number of respective Points that may be received
                    per Peak via this method, however there is a limit of 1 Vote per Team that may
                    be submitted per person per day. If it is discovered by the Sponsor (using any
                    evidence or other information made available to or otherwise discovered by the
                    Sponsor) that any person has attempted to: (i) exceed any of the Vote limits
                    stated in these Rules; (ii) use any automated, script, macro, robotic or other
                    program(s) to submit Votes; and/or (iii) engage in systematic repeated voting
                    from the same computer/IP address; then Sponsor reserves the right, in its sole
                    and absolute discretion, to disqualify the Votes and/or Points to which such
                    Votes relate. Participants may encourage other eligible participants to vote for
                    a Team; however, no form of incentive, inducement, prize or chance of receiving
                    any incentive, inducement, or prize may be offered as part of such encouragement
                    in furtherance of receiving such individual’s Vote for any Team. Any individual
                    and/or Team determined by the Sponsor to be engaging in such behaviour is
                    subject to disqualification (as determined by the Sponsor in its sole and
                    absolute discretion) as well as the corresponding Votes and/or Points to which
                    such Votes relate.
                  </li>
                </ol>
                <ol>
                  <li>ENTRY CONDITIONS:</li>
                </ol>
                <p>
                  If it is discovered by the Sponsor (using any evidence or other information made
                  available to or otherwise discovered by the Sponsor) that any person has attempted
                  to: (i) exceed any of the limits stated in these Rules; (ii) use multiple names,
                  multiple identities, multiple e-mail addresses, multiple Accounts and/or any
                  automated, macro, script, robotic or other system(s) or program(s) to enter or
                  otherwise participate in or to disrupt the Contest; and/or (iii) disrupt or
                  participate in the Contest in any other fraudulent or misleading way, then he/she
                  may be disqualified from the Contest in the sole and absolute discretion of the
                  Sponsor. The Contest Parties, Instagram Inc., and each of their respective agents,
                  employees, directors, officers, successors, and assigns (collectively, the
                  “Released Parties”) are not responsible for, and accept no liability whatsoever in
                  relation to, any late, lost, misdirected, delayed, incomplete or incompatible
                  Entry Materials (all of which are void). Entry Materials may be rejected if, in
                  the sole and absolute discretion of the Sponsor, the Entry Materials are not
                  submitted and received in accordance with these Rules (including, but not limited
                  to, the specific Participation Requirements listed below in Rule 8 and/or the
                  applicable Social Platform Rules).
                </p>
                <ol>
                  <li>VERIFICATION:</li>
                </ol>
                <p>
                  All Entry Materials, Teams, Team Representatives, Charity or Organization, voters
                  and participants are subject to verification at any time and for any reason. The
                  Sponsor reserves the right, in its sole and absolute discretion, to require proof
                  of identity and/or eligibility (in a form acceptable to the Sponsor – including,
                  without limitation, government issued photo identification): (i) for the purposes
                  of verifying a participant’s eligibility to participate in this Contest; (ii) for
                  the purposes of verifying the eligibility and/or legitimacy of any Entry
                  Materials, Team, Team Representative, Charity or Organization, and/or other
                  information entered (or purportedly entered) for the purposes of this Contest;
                  and/or (iii) for any other reason the Sponsor deems necessary, in its sole and
                  absolute discretion, for the purposes of administering this Contest in accordance
                  with the letter and spirit of these Rules. Failure to provide such proof to the
                  complete satisfaction of the Sponsor within the timeline specified by the Sponsor
                  may result in disqualification in the sole and absolute discretion of the Sponsor
                  of the applicable Votes, Points, Registration, Charity or Organization and/or
                  Team, as applicable. The sole determinant of the time for the purposes of the
                  Contest will be the official time-keeping device(s) used by the Sponsor.
                </p>
                <ol>
                  <li>PARTICIPATION REQUIREMENTS:</li>
                </ol>
                <p>
                  BY PARTICIPATING IN THE CONTEST, YOU AGREE THAT THE ENTRY MATERIALS COMPLY WITH
                  ALL CONDITIONS STATED IN THESE RULES. THE RELEASED PARTIES WILL BEAR NO LIABILITY
                  WHATSOEVER REGARDING: (I) THE USE OF YOUR ENTRY MATERIALS; (II) PARTICIPATION IN
                  ANY CONTEST-RELATED ACTIVITIES; (III) ANY USE, COLLECTION, STORAGE AND DISCLOSURE
                  OF ANY PERSONAL INFORMATION; AND/OR (IV) A PRIZE, AS APLICABLE (INCLUDING ANY USE
                  OR MISUSE OF THE PRIZE). THE RELEASED PARTIES SHALL BE HELD HARMLESS BY YOU IN THE
                  EVENT IT IS DISCOVERED THAT YOU HAVE DEPARTED FROM OR NOT OTHERWISE FULLY COMPLIED
                  WITH ANY OF THESE RULES AND/OR THE APPLICABLE SOCIAL PLATFORM RULES (AS
                  APPLICABLE). THIS RELEASE AND INDEMNITY SHALL CONTINUE IN FORCE FOLLOWING THE
                  TERMINATION OF THE CONTEST AND/OR AWARDING OF ANY PRIZES.&nbsp;
                </p>
                <p>
                  By participating in the Contest, each participant hereby warrants and represents
                  that any Entry Materials he/she submits:
                </p>
                <ol>
                  <li>
                    does not contain images or likenesses of brands or products which are
                    competitors of the Sponsor;
                  </li>
                  <li>
                    has not been previously published and is/are original to him/her and that the
                    entrant has obtained all necessary rights in and to the Entry Materials for the
                    purposes of entering such Entry Materials in the Contest (including, without
                    limitation, all necessary consents or authorization from a Charity or
                    Organization associated with his/her Entry Materials);
                  </li>
                  <li>does not violate any law, statute, ordinance or regulation;</li>
                  <li>
                    does not contain any reference to or likeness of any identifiable third parties,
                    unless consent has been obtained from all such individuals and their
                    parent/legal guardian if they are under the age of majority in their
                    jurisdiction of residence;
                  </li>
                  <li>
                    will not give rise to any claims whatsoever, including, without limitation,
                    claims of infringement, invasion of privacy or publicity, or infringe on any
                    rights and/or interests of any third party; and
                  </li>
                  <li>
                    is/are not defamatory, trade libelous, pornographic or obscene, and further that
                    it will not contain, depict, include, discuss or involve, without limitation,
                    any of the following: violence; nudity; alcohol/drug consumption or smoking;
                    explicit or graphic sexual activity, or sexual innuendo; crude, vulgar or
                    offensive language and/or symbols; derogatory characterizations of any ethnic,
                    racial, sexual, religious or other groups (including, without limitation, any
                    competitors of Sponsor); that endorses, condones and/or discusses any illegal,
                    inappropriate or risky behaviour or conduct; personal information of
                    individuals, including, without limitation, names, telephone numbers and
                    addresses (physical or electronic); commercial messages, comparisons or
                    solicitations for products or services other than products of Sponsor; any
                    identifiable third party products, trade-marks, brands and/or logos, other than
                    those of Sponsor; conduct or other activities in violation of these Rules;
                    and/or any other materials that are or could be considered false, misleading,
                    inappropriate, unsuitable or offensive, all as determined by the Sponsor in its
                    sole and absolute discretion.
                  </li>
                </ol>
                <p>
                  The Sponsor and/or its promotional agency or designated content moderator (the
                  “Reviewer”) reserves the right to screen all Entry Materials. Any Entry Materials
                  that the Reviewer deems, in its sole and absolute discretion, to violate the terms
                  and conditions set forth in these Rules are subject to disqualification. Each
                  participant hereby agrees to indemnify and hold harmless Released Parties from any
                  claim contrary to the representations and warranties above. The Reviewer reserves
                  the right, in its sole and absolute discretion at any time and for any reason, to
                  remove any Entry Materials (or any part thereof) and/or to request an entrant to
                  modify, edit and/or re-submit his or her Entry Materials (or any part thereof) to
                  ensure that the Entry Materials comply with these Rules, or for any other reason.
                  If such an action is necessary at any point during or after the Contest, then the
                  Sponsor reserves the right, in its sole discretion, to take whatever action it
                  deems necessary based on the circumstances – including, without limitation,
                  disqualifying the Entry Materials (and therefore the corresponding Team, Votes,
                  Points and/or the associated participant(s)) – to help ensure that the Contest is
                  being conducted in accordance with the letter and spirit of these Rules.
                </p>
                <ol>
                  <li>LICENCE:</li>
                </ol>
                <p>
                  By participating in the Contest and submitting Entry Materials, each participant:
                  (i) without limiting the Social Platform Rules, as applicable, grants to the
                  Sponsor, in perpetuity, a worldwide, non-exclusive, license to publish, display,
                  reproduce, modify, edit, make available, communicate to the public by
                  telecommunication and otherwise use his/her Entry Materials (and each component
                  thereof), in whole or in part, for advertising or promoting the Contest or for any
                  other reason in any type of media; (ii) waives all moral rights in and to his/her
                  Entry Materials (and each component thereof) in favour of the Sponsor (and anyone
                  authorized by the Sponsor to use such Entry Materials); and (iii) agrees to
                  release and hold harmless the Released Parties from and against any and all
                  claims, damages, liabilities, costs, and expenses arising from use of his/her
                  Entry Materials (or any component thereof), including, without limitation, any
                  claim based on publicity rights, defamation, invasion of privacy, copyright
                  infringement, trade-mark infringement or any other intellectual property related
                  or other cause of action whatsoever.
                </p>
                <ol>
                  <li>PRIZES:</li>
                </ol>
                <p>
                  There are three (3) prizes (each, a “Prize” and collectively, “Prizes”) available
                  to be won, as follows:
                </p>
                <ol>
                  <li>
                    <u>
                      Grand Prize (1<sup>st</sup>&nbsp;Place):
                    </u>
                    &nbsp;There is one (1) grand prize (the “Grand Prize”) available to be won,
                    consisting of a $100,000 CAD cheque payable to an eligible Charity or
                    Organization.
                  </li>
                  <li>
                    <u>
                      2<sup>nd</sup>&nbsp;Place:
                    </u>
                    &nbsp;There is one (1) 2<sup>nd</sup>&nbsp;Place prize available to be won,
                    consisting of a $20,000 CAD cheque payable to an eligible Charity or
                    Organization.
                  </li>
                  <li>
                    <u>
                      3<sup>rd</sup>&nbsp;Place:
                    </u>
                    &nbsp;There is one (1) 3<sup>rd</sup>&nbsp;Place prize available to be won,
                    consisting of a $10,000 CAD cheque payable to an eligible Charity or
                    Organization.
                  </li>
                </ol>
                <p>
                  Each Prize must be accepted as awarded and is not transferable, assignable, or
                  convertible (except as may be specifically permitted by Sponsor in its sole and
                  absolute discretion). No substitutions are permitted, except at Sponsor’s option.
                  The Sponsor reserves the right to substitute any Prize in whole or in part with a
                  prize or prize component(s) of equal or greater retail value. All characteristics
                  and features of each Prize, except as otherwise explicitly stated above, are at
                  the Sponsor’s sole and absolute discretion. Each Prize winner is solely
                  responsible for all costs not expressly described herein. Each Prize winner
                  (whether a Charity or Organization winner of the Grand Prize or individual winner
                  of a Secondary Prize and his/her child, as applicable), is solely responsible for
                  reporting and paying any tax that may be payable in connection with the applicable
                  Prize.
                </p>
                <p>
                  ---------------------------------------------------------------------------------------------------------------------
                </p>
                <ol>
                  <li>
                    <strong>ELIGIBLE WINNER SELECTION PROCESS:</strong>
                  </li>
                </ol>
                <p>The Sponsor will determine the eligible Prize winners as follows:</p>
                <ol>
                  <li>
                    <strong>
                      <u>Grand Prize:</u>
                    </strong>
                    &nbsp;The Peaks that obtain one (1) of the top three (3) highest number of total
                    Points as of the close of the Canada Votes Period, and each of their respective
                    Teams, will be judged during the Judging Period to determine an eligible Grand
                    Prize winner. A panel of judges (the “<strong>Contest Judges</strong>”)
                    appointed by the Sponsor at its sole and absolute discretion will judge and
                    assign a score to each eligible Peak (a “<strong>Peak Score</strong>”) based on
                    the following weighted criteria (the “<strong>Peak Judging Criteria</strong>”):
                  </li>
                </ol>
                <table>
                  <tbody>
                    <tr>
                      <td width={289}>
                        <p>Peak Judging Criteria</p>
                      </td>
                      <td width={286}>
                        <p>Quality of Weighting</p>
                      </td>
                    </tr>
                    <tr>
                      <td width={289}>
                        <p>Creativity, engagement, and Application</p>
                      </td>
                      <td width={286}>
                        <p>60%</p>
                      </td>
                    </tr>
                    <tr>
                      <td width={289}>
                        <p>Number of Points Earned</p>
                      </td>
                      <td width={286}>
                        <p>30%</p>
                      </td>
                    </tr>
                    <tr>
                      <td width={289}>
                        <p>Sustainability / Eco-Initiative</p>
                      </td>
                      <td width={286}>
                        <p>10%</p>
                      </td>
                    </tr>
                    <tr>
                      <td width={289}>
                        <p>Top Peak Score</p>
                      </td>
                      <td width={286}>
                        <p>Maximum 100%</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  The Charity or Organization associated with the Team that obtains the highest Peak
                  Score, based on the Peak Judging Criteria above, will be eligible to receive the
                  Grand Prize. For greater certainty and the avoidance of any doubt, the Grand Prize
                  will only be awarded to the applicable Charity or Organization, and not to a Team,
                  Peak or Team Representative. The odds of winning depend on the number of Points
                  and Votes received per Peak, and caliber of Registrations and Eligible Media Files
                  received during the Contest Period.
                </p>
                <ol>
                  <li>
                    <strong>
                      <u>Award</u>
                    </strong>
                    <u>:</u>&nbsp;On March 9<sup>th</sup>, 2024 (the Winner Reveal) in Toronto,
                    Ontario at approximately TBD ET, the Sponsor will notify the peak with the top
                    “Peak Score” that they have been selected to receive the $100,000 grand prize.
                  </li>
                  <li>
                    <strong>
                      <u>Secondary Prizing</u>
                    </strong>
                    <u>:</u>&nbsp;On March 9<sup>th</sup> ET, 2024 (the Winner Reveal) in Toronto,
                    Ontario at approximately TBD ET, the Sponsor will award the remaining two (2)
                    Peaks from the Top Three (3) with secondary prizing. The total prizes to be
                    distributed are as follows:
                    <table>
                      <tbody>
                        <tr>
                          <td width={312}>
                            <p>Top Peak Score Position</p>
                          </td>
                          <td width={312}>
                            <p>Prize Value ($)</p>
                          </td>
                        </tr>
                        <tr>
                          <td width={312}>
                            <p>1st</p>
                          </td>
                          <td width={312}>
                            <p>100,000</p>
                          </td>
                        </tr>
                        <tr>
                          <td width={312}>
                            <p>2nd</p>
                          </td>
                          <td width={312}>
                            <p>20,000</p>
                          </td>
                        </tr>
                        <tr>
                          <td width={312}>
                            <p>3rd</p>
                          </td>
                          <td width={312}>
                            <p>10,000</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </li>

                  <li>
                    <strong>
                      <u>Tertiary Prizing: </u>
                    </strong>
                    On March 9<sup>th</sup>, 2024 (the “Selection Date”) in Toronto, Ontario at
                    approximately TBD ET.
                  </li>
                </ol>
                <p>
                  &nbsp;In the event a potential Prize winner is disqualified for failing to comply
                  with these Rules (or, in the event a potential winning Charity or Organization is
                  disqualified in accordance with Rule 5 above), as determined by the Sponsor at its
                  sole discretion, the Sponsor reserves the right in its sole discretion, to: (i)
                  select the Charity or Organization associated with the Team which obtains the
                  second highest Team Score, from among all Teams associated with the Peak which
                  obtains the highest Peak Score in accordance with the procedure above, to
                  determine an alternate eligible Charity or Organization; or (ii) select an
                  alternate eligible participant through a random draw from among the remaining
                  applicable eligible Posts and Challenge Posts received in accordance with these
                  Rules (as applicable, and as determined by the Sponsor at its sole discretion).
                </p>
                <ol>
                  <li>
                    <strong>ELIGIBLE WINNER NOTIFICATION PROCESS:</strong>
                  </li>
                </ol>
                <p>
                  The Sponsor or its designated representative will make a minimum of three (3)
                  attempts to contact each potential winner by telephone, after the live
                  announcement on CBC television. If the potential winner cannot be contacted within
                  such time (for any reason whatsoever, including without limitation, with respect
                  to the Grand Prize, if the Team Representative fails to provide accurate and
                  timely contact information for the potential winner), or if undeliverable; then
                  he/she may, in the sole and absolute discretion of the Sponsor, be disqualified
                  (and, if disqualified, will forfeit all rights to the applicable Prize) and the
                  Sponsor reserves the right, in its sole and absolute discretion and time
                  permitting, select an alternate potential Prize winner in accordance with the
                  alternate winner selection procedure above (in which case the foregoing provisions
                  of this section shall apply to such newly selected potential winner).&nbsp;
                </p>
                <ol>
                  <li>
                    <strong>ELIGIBLE WINNER CONFIRMATION PROCESS:</strong>
                  </li>
                </ol>
                <p>
                  NO ONE IS A WINNER UNLESS AND UNTIL THE SPONSOR OFFICIALLY CONFIRMS HIM/HER AS A
                  WINNER IN ACCORDANCE WITH THESE RULES. Before being declared A CONFIRMED PRIZE
                  winner, each potential winner (in the case of an eligible Charity or Organization,
                  a designated authorized repetitive) will be required to: (a) correctly answer a
                  mathematical skill-testing question without mechanical or other aid (which may, in
                  the sole and absolute discretion of the Sponsor, be administered online, by email
                  or other electronic means, by telephone, or in the Sponsor’s form of declaration
                  and release); and (b) sign and return within two (2) business days of notification
                  the Sponsor’s declaration and release form, which (among other things): (i)
                  confirms compliance with these Rules; (ii) acknowledges acceptance of the Prize
                  (as awarded); (iii) releases the Released Parties from any and all liability in
                  connection with this Contest, his/her participation therein and/or the awarding
                  and use/misuse of the Prize or any portion thereof; (iv) agrees to indemnify the
                  Released Parties against any and all claims, damages, liabilities, costs, and
                  expenses arising from use of his/her Entry Materials or any portion(s) thereof;
                  and (v) agrees to the publication, reproduction and/or other use of his/her name,
                  Peak name, Charity or Organization name (as applicable), city/jurisdiction of
                  residence, voice, statements about the Contest and/or photograph or other likeness
                  without further notice or compensation, in any publicity or advertisement carried
                  out by or on behalf of the Sponsor in any manner or medium whatsoever, including
                  print, broadcast or the internet, worldwide and in perpetuity.&nbsp;
                </p>
                <p>
                  If a potential winner: (a) fails to correctly answer the skill-testing question;
                  (b) fails to return the properly executed Contest documents within the specified
                  time; (c) cannot accept (or is unwilling to accept) a Prize (as awarded) in
                  accordance with the Sponsor’s instructions for any reason; and/or (d) is
                  determined to be in violation of these Rules (all as determined by the Sponsor in
                  its sole and absolute discretion); then he/she will be disqualified (and will
                  forfeit all rights to the applicable Prize) and the Sponsor reserves the right, in
                  its sole and absolute discretion and time permitting, select an alternate
                  potential Prize winner in accordance with the alternate winner selection procedure
                  above (in which case the foregoing provisions of this section shall apply to such
                  newly selected potential winner). Refusal by a potential Grand Prize-winning
                  Charity or Organization to accept the Grand Prize in accordance with the Sponsor’s
                  instructions releases the Released Parties from all responsibility and obligations
                  towards such Charity or Organization, including without limitation, all
                  responsibility and obligations in respect of the Grand Prize. In no event shall
                  the Sponsor be held to award more prizes than what is mentioned in these Rules or
                  to award prizes otherwise than in compliance with these Rules.
                </p>
                <ol>
                  <li>
                    <strong>GENERAL CONDITIONS:</strong>
                  </li>
                </ol>
                <p>
                  This Contest is subject to all applicable federal, provincial, and municipal laws.
                  The decisions of the Sponsor with respect to all aspects of this. Contest are
                  final and binding on all entrants without right of appeal. ANYONE DEEMED BY THE
                  SPONSOR TO BE IN VIOLATION OF THE LETTER AND/OR SPIRIT OF THESE RULES FOR ANY
                  REASON IS SUBJECT TO DISQUALIFICATION IN THE SOLE AND ABSOLUTE DISCRETION OF THE
                  SPONSOR AT ANY TIME.&nbsp;
                </p>
                <p>
                  The Released Parties will not be liable for: (i) any failure of any website or
                  platform during the Contest; (ii) any technical malfunction or other problems of
                  any nature whatsoever, including, without limitation, those relating to the
                  telephone network or lines, computer on-line systems, servers, access providers,
                  computer equipment or software; (iii) the failure of any Entry Materials and/or
                  other information to be received, captured or recorded for any reason whatsoever,
                  including, but not limited to, technical problems or traffic congestion on the
                  Internet or at any website, including the online Entry Form; (iv) any injury or
                  damage to an entrant’s or any other person’s computer or other device related to
                  or resulting from participating in the Contest; (v) anyone being incorrectly
                  and/or mistakenly identified as a winner or eligible winner; and/or (vi) any
                  combination of the above.
                </p>
                <p>
                  The Sponsor reserves the right, subject only to the approval of the Régie des
                  alcools, des courses et des jeux (the “<strong>Régie</strong>”) in Québec, to
                  cancel, withdraw, amend or suspend the Contest (or to amend these Rules) in any
                  way, in the event of any cause beyond the reasonable control of the Sponsor that
                  interferes with the proper conduct of the Contest as contemplated by these Rules,
                  including, without limitation, any error, problem, computer virus, bugs,
                  tampering, unauthorized intervention, health risks or governmental restrictions
                  (such as those that may be implemented to mitigate COVID-19 transmission) fraud or
                  failure of any kind whatsoever. Any attempt to undermine the legitimate operation
                  of the Contest in any way (as determined by Sponsor in its sole and absolute
                  discretion) may be a violation of criminal and civil laws and should such an
                  attempt be made; the Sponsor reserves the right to seek remedies and damages to
                  the fullest extent permitted by law. The Sponsor reserves the right, subject only
                  to the approval of the Régie in Québec, to cancel, amend or suspend the Contest,
                  or to amend these Rules, in any way without prior notice or obligation, in the
                  event of any accident, printing, administrative, or other error of any kind, or
                  for any other reason whatsoever. The Sponsor reserves the right, in its sole and
                  absolute discretion, to administer an alternate test of skill as it deems
                  appropriate based on the circumstances and/or to comply with applicable law.
                </p>
                <p>
                  The Sponsor reserves the right, subject only to the approval of the Régie in
                  Québec, to adjust any of the dates, timeframes and/or other Contest mechanics
                  stipulated in these Rules, to the extent deemed necessary by Sponsor, for purposes
                  of verifying compliance by any entrant, Entry and/or other information with these
                  Rules, or as a result of any technical or other problems, or in light of any other
                  circumstances which, in the opinion of the Sponsor, in its sole and absolute
                  discretion, affect the proper administration of the Contest as contemplated in
                  these Rules, or for any other reason.
                </p>
                <p>
                  FOR RESIDENTS OF QUÉBEC ONLY:&nbsp;
                  <em>
                    Any litigation respecting the conduct or organization of a publicity contest may
                    be submitted to the Régie des alcools, des courses et des jeux for a ruling. Any
                    litigation respecting the awarding of a prize may be submitted to the board only
                    for the purpose of helping the parties reach a settlement.
                  </em>
                </p>
                <p>
                  By participating in this Contest, each participant expressly consents to the
                  Sponsor, its agents and/or representatives, storing, sharing and using the
                  personal information submitted only for the purpose of administering the Contest
                  and in accordance with Sponsor’s privacy policy (available at:
                  https://www.mackenzieinvestments.com/en/legal-and-privacy/legal-disclaimers#privacy-protection-notice).This
                  section does not limit any other consent(s) that an individual may provide the
                  Sponsor or others in relation to the collection, use and/or disclosure of their
                  personal information.
                </p>
                <p>
                  In the event of any discrepancy or inconsistency between the terms and conditions
                  of these English Rules and disclosures or other statements contained in any
                  Contest-related materials, including, but not limited to, the French version of
                  these Rules, the Website, point of sale, television, print or online advertising
                  and/or any instructions or interpretations of these Rules given by any
                  representative of the Sponsor, the terms and conditions of these English Rules
                  shall prevail, govern and control to the fullest extent permitted by law.
                </p>
                <p>
                  The invalidity or unenforceability of any provision of these Rules shall not
                  affect the validity or enforceability of any other provision. If any provision is
                  determined to be invalid or otherwise unenforceable or illegal, these Rules shall
                  otherwise remain in effect and shall be construed in accordance with the terms as
                  if the invalid or illegal provision were not contained herein.
                </p>
                <p>
                  To the fullest extent permitted by applicable law, all issues and questions
                  concerning the construction, validity, interpretation and enforceability of these
                  Rules or the rights and obligations of participants, Sponsor or any of the other
                  the Released Parties in connection with the Contest will be governed by and
                  construed in accordance with the domestic laws of the Province of Ontario and the
                  federal laws of Canada applicable therein, without giving effect to any choice of
                  law or conflict of law rules or provisions that would cause the application of any
                  other jurisdiction’s laws. The parties hereby consent to the exclusive
                  jurisdiction and venue of the courts located in Ontario in any action to enforce
                  (or otherwise relating to) these Rules or relating to the Contest.
                </p>
                <RulesAppendixA />
              </>
            </div>
            {/* /.section__hint */}
          </div>

          {/* /.section__inner */}
        </div>
        {/* /.shell */}
      </section>
      {/* /.section-blocks */}
    </div>
  );
}

export default RulesEn;
