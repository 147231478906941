import { Route } from "react-router-dom";
import Closed from "../Pages/Closed";
import Open from "../Pages/Open";
import "../i18n";
import PreviousWinners from "../Pages/Open/PreviousWinners";
import RulesEn from "../Pages/RulesEn";
import RulesFr from "../Pages/RulesFr";

import HowItWorks from "../Pages/Open/HowItWorks";
import Faqs from "../Pages/Open/Faqs";
import Landing from "../Pages/Open/Landing";
import Landing4 from "../Pages/Open/Phase4/Landing";

export default (
  <>
    <Route path="/" element={<Open />}>
      <Route path="/" element={<Landing4 />} />
      <Route path="howitworks" element={<HowItWorks />} />

      <Route path="faqs" element={<Faqs />} />
      <Route path="pastwinners" element={<PreviousWinners />} />
    </Route>

    <Route path="rulesen" element={<RulesEn />} />
    <Route path="rulesfr" element={<RulesFr />} />

    <Route path="closed" element={<Closed />} />
    <Route path="coming" element={<Landing />}></Route>
  </>
);
