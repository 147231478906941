import { useTranslation, Trans } from "react-i18next";
import { defaultStorageHelper } from "@spry/campaign-client";
import "./index.css";
import { changeLanguage } from "i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const languageToggle = () => {
    const ln = i18n.language === "en" ? "fr" : "en";
    defaultStorageHelper.set("lang", ln);
    changeLanguage(ln);
    if (window.location.pathname.includes("rules")) {
      window.location.replace(window.location.origin + "/rules" + ln);
    }
  };
  return (
    <>
      <footer className="footer">
        <div className="shell footer__shell">
          <div className="footer__content">
            <h3>{t("Footer.beinvested")}</h3>

            <h2>
              Ski <img src="/assets/images/svg/ico-plus.svg" alt="icon" /> Mackenzie
            </h2>
          </div>
          {/* /.footer__content */}

          <div className="footer__inner">
            <div className="partners footer__partners">
              <div className="partners__head" style={{ display: "flex", justifyContent: "center" }}>
                <h5>
                  {t("Footer.proud")} <br />
                  {t("Footer.partners")}
                </h5>
              </div>
              {/* /.partners__head */}

              <div className="partners__body">
                <ul>
                  <li>
                    <a
                      href={
                        i18n.language === "en"
                          ? "https://www.cbc.ca/sports"
                          : "https://ici.radio-canada.ca/quebec/theme/sports"
                      }
                      target="_blank"
                      rel="noreferrer">
                      <img
                        src={
                          i18n.language === "fr"
                            ? "/assets/images/radio_sports.png"
                            : "/assets/images/temp/partner-1@2x.png"
                        }
                        alt="CBC Sports"
                        width="284"
                        height="39"
                      />
                    </a>
                  </li>

                  <li>
                    <a href="https://alpinecanada.org/" target="_blank" rel="noreferrer">
                      <img
                        src="/assets/images/temp/partner-3@2x.png"
                        alt="Alpine Canada"
                        width="278"
                        height="51"
                      />
                    </a>
                  </li>

                  <li>
                    <a
                      href={
                        i18n.language === "en"
                          ? "https://skimuseum.ca/"
                          : "https://skimuseum.ca/fr/"
                      }
                      target="_blank"
                      rel="noreferrer">
                      <img
                        src={
                          i18n.language === "fr"
                            ? "/assets/images/footerlink5.png"
                            : "/assets/images/footerlink6.png"
                        }
                        alt="Canadian Ski Hall of Fame & Museum"
                        width="278"
                        height="61"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.skiquebec.qc.ca/" target="_blank" rel="noreferrer">
                      <img src="/assets/images/ski-quebec.png" alt="Ski Quebec" width="300" />
                    </a>
                  </li>
                  <li>
                    <a href="https://alpineontario.ca/" target="_blank" rel="noreferrer">
                      <img
                        src="/assets/images/AOA-logo-horizontal.png"
                        alt="Alpine Ontario"
                        width="300"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              {/* /.partners__body */}
            </div>

            {/* /.partners */}

            <div className="footer__socials">
              <div className="socials">
                <h6>{t("Footer.follow")}</h6>

                <ul>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/mackenzie-investments/"
                      rel="noreferrer"
                      target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="31.347"
                        height="31.355"
                        viewBox="0 0 31.347 31.355">
                        <path
                          id="Path_1592"
                          data-name="Path 1592"
                          d="M820.034,2420.475H792.358c-.1-.039-.2-.083-.3-.119a2.234,2.234,0,0,1-1.53-2.109q0-13.451,0-26.9a2.229,2.229,0,0,1,2.221-2.221q13.449,0,26.9,0a2.232,2.232,0,0,1,2.109,1.53c.035.1.078.2.118.3v27.68c-.039.1-.083.2-.118.3a2.23,2.23,0,0,1-1.273,1.359c-.146.063-.3.116-.445.174m-16.257-19.116V2412.8h3.781c0-.147,0-.269,0-.391q0-2.986,0-5.971a2.366,2.366,0,0,1,.5-1.6,1.994,1.994,0,0,1,2.08-.71,1.691,1.691,0,0,1,1.316,1.41,3.885,3.885,0,0,1,.12.875c.012,2.011.009,4.021.01,6.031v.355h3.805v-.386c0-1.979.005-3.958,0-5.939a11.523,11.523,0,0,0-.072-1.4c-.255-1.983-1.356-3.774-3.877-3.984a3.824,3.824,0,0,0-3.57,1.456c-.084.11-.166.222-.25.333l-.065-.012v-1.5Zm-5.932,11.447h3.762V2401.36h-3.762Zm-.231-15.037a1.971,1.971,0,0,0,2.118,1.993,1.989,1.989,0,1,0-2.118-1.993"
                          transform="translate(-790.523 -2389.121)"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.facebook.com/mackenzieinvest/"
                      target="_blank"
                      rel="noreferrer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30.943"
                        height="30.936"
                        viewBox="0 0 30.943 30.936">
                        <path
                          id="Path_1593"
                          data-name="Path 1593"
                          d="M835.6,2418.727v-28.279c.02-.02.048-.036.057-.06a1.705,1.705,0,0,1,1.826-1.267q13.6,0,27.191,0a2.422,2.422,0,0,1,.511.036,1.713,1.713,0,0,1,1.358,1.814v27.215c0,.07,0,.141,0,.211a1.707,1.707,0,0,1-.383,1.026,2.119,2.119,0,0,1-1,.632h-8.219v-12h4.033c.2-1.566.4-3.086.6-4.657h-4.614c0-1.142-.038-2.241.009-3.335a1.727,1.727,0,0,1,1.9-1.88c.844-.041,1.691-.033,2.535-.047.106,0,.211-.01.306-.015v-4.149c-.221-.016-.421-.03-.619-.046a19.819,19.819,0,0,0-4.128-.035,5.371,5.371,0,0,0-2.843,1.218,5.642,5.642,0,0,0-1.941,4.262c-.058,1.245-.028,2.5-.022,3.744,0,.238-.067.309-.306.307-1.129-.012-2.256-.006-3.384-.006h-.325v4.661h4.01v.412q0,5.587,0,11.175c0,.13.013.26.02.391h-15.23a9.061,9.061,0,0,1-.976-.7,2.266,2.266,0,0,1-.354-.635"
                          transform="translate(-835.598 -2389.121)"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.instagram.com/mackenzieinvest/"
                      target="_blank"
                      rel="noreferrer">
                      <svg
                        id="Component_15_1"
                        data-name="Component 15 – 1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="31.589"
                        height="31.588"
                        viewBox="0 0 31.589 31.588">
                        <path
                          id="Path_1595"
                          data-name="Path 1595"
                          d="M956.941,2391.1v27.657a.359.359,0,0,0-.038.081,2.272,2.272,0,0,1-2.311,1.86H927.683a2.258,2.258,0,0,1-2.33-2.333q0-13.489,0-26.979a2.15,2.15,0,0,1,1.094-1.934,5.191,5.191,0,0,1,.847-.341H955a.915.915,0,0,0,.126.047,2.252,2.252,0,0,1,1.278.765,2.619,2.619,0,0,1,.538,1.177m-6.711,13.826h-.04c0-.979.023-1.959-.008-2.937a13.616,13.616,0,0,0-.186-2.09,4.592,4.592,0,0,0-2.882-3.573,7.429,7.429,0,0,0-2.831-.457q-2.52-.043-5.041-.013a20.825,20.825,0,0,0-2.9.161,4.591,4.591,0,0,0-3.782,2.945,7.708,7.708,0,0,0-.449,2.879q-.025,3.133,0,6.266a7.944,7.944,0,0,0,.32,2.371,4.548,4.548,0,0,0,2.95,3.077,8.142,8.142,0,0,0,2.637.389c1.872.016,3.746.025,5.619.009a18,18,0,0,0,2.256-.153,4.682,4.682,0,0,0,3.262-1.892,5.347,5.347,0,0,0,.982-3.007c.061-1.324.066-2.649.094-3.975"
                          transform="translate(-925.352 -2389.107)"
                          fill="currentColor"
                        />
                        <path
                          id="Path_1596"
                          data-name="Path 1596"
                          d="M948.748,2405.06c-.029,1.3-.038,2.6-.091,3.9a3.786,3.786,0,0,1-.66,2.107,3.048,3.048,0,0,1-2.425,1.29c-1.73.084-3.464.094-5.2.112-.916.01-1.833-.031-2.75-.051a4.4,4.4,0,0,1-2.049-.472,3.117,3.117,0,0,1-1.606-2.574c-.067-.634-.1-1.275-.1-1.913-.011-1.812-.016-3.623.013-5.433a9.108,9.108,0,0,1,.2-1.9,2.914,2.914,0,0,1,2.4-2.307,9.128,9.128,0,0,1,1.764-.186q3.132-.03,6.264.009a7.888,7.888,0,0,1,1.689.209,2.953,2.953,0,0,1,2.361,2.588,12.373,12.373,0,0,1,.14,1.749c.022.954.006,1.911.006,2.866h.038m-7.469,4.64a4.652,4.652,0,1,0-4.648-4.8,4.663,4.663,0,0,0,4.648,4.8m4.841-8.417a1.074,1.074,0,0,0,1.093-1.07,1.086,1.086,0,0,0-1.074-1.1,1.073,1.073,0,0,0-1.086,1.054,1.06,1.06,0,0,0,1.068,1.112"
                          transform="translate(-925.497 -2389.248)"
                          fill="currentColor"
                        />
                        <path
                          id="Path_1597"
                          data-name="Path 1597"
                          d="M941.36,2408.148a3.024,3.024,0,1,1,3.034-3.014,3.026,3.026,0,0,1-3.034,3.014"
                          transform="translate(-925.574 -2389.322)"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
              {/* /.socials */}
            </div>
            {/* /.footer__socials */}
          </div>
          {/* /.footer__inner */}
          <div style={{ marginBottom: "2rem", textAlign: "center", fontSize: "1.4rem" }}>
            <p>{t("Footer.desclaimer1")}</p>
            <p>
              {t("Footer.desclaimer2")}
              <a
                rel="noreferrer"
                href={i18n.language === "fr" ? "/rulesFr" : "rulesEn"}
                target="_blank">
                {t("Footer.8")}
              </a>
              . {t("Footer.9")}
            </p>
            <p>
              {t("Footer.desclaimer3")}
              <br />
              <a
                target="_blank"
                href={
                  i18n.language === "en"
                    ? "https://www.mackenzieinvestments.com/en/legal-and-privacy/legal-disclaimers"
                    : "https://www.mackenzieinvestments.com/fr/legal-and-privacy/legal-disclaimers"
                }>
                {t("Footer.10")}
              </a>{" "}
              | {t("Footer.11")}{" "}
            </p>
          </div>
        </div>
        {/* /.shell */}

        <div className="footer__bar">
          <div className="shell">
            <ul>
              <li>
                <a
                  rel="noreferrer"
                  href={i18n.language === "fr" ? "/rulesFr" : "/rulesEn"}
                  target="_blank">
                  {t("Footer.rules")}
                </a>
              </li>

              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={
                    i18n.language === "en"
                      ? "https://www.mackenzieinvestments.com/en/legal-and-privacy/legal-disclaimers"
                      : "https://www.mackenzieinvestments.com/fr/legal-and-privacy/legal-disclaimers "
                  }>
                  {t("Footer.policy")}
                </a>
              </li>

              <li>
                <a href="mailto:info@mackenzietoppeak.ca">{t("Footer.contactus")}</a>
              </li>

              <li>
                <a target="_self" onClick={languageToggle}>
                  {t("Footer.language")}
                </a>
              </li>
            </ul>
          </div>
          {/* /.shell */}
        </div>
        {/* /.footer__bar */}
      </footer>
      {/* /.footer */}
    </>
  );
};

export default Footer;
