import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Faqs = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="main">
        <div className="parallax parallax--different">
          <img src="/assets/images/decoration-2@2x.jpg" alt="image" />
        </div>
        {/* /.parallax */}

        <div className="hero hero--different hero--size1">
          <div className="shell shell--lg">
            <div className="hero__inner">
              <a href="/" className="hero__link">
                {t("faqs.text1")}
              </a>
              <h2>{t("faqs.text2")}</h2>
            </div>
            {/* /.hero__inner */}
          </div>
          {/* /.shell */}

          <div className="hero__decoration">
            <img src="/assets/images/temp/mountain-2@2x.png" alt="image" />
          </div>
          {/* /.hero__decoration */}
        </div>
        {/* /.hero */}
        <section className="section-blocks">
          <div className="shell shell--lg">
            <div className="section__inner">
              <div className="section__hint">
                <h6>{t("faqs.text100")}</h6>
                <p>{t("faqs.text101")}</p>
                <h6>{t("faqs.text3")}</h6>
                <p>{t("faqs.text4")}</p>
                <h6>{t("faqs.text5")}</h6>
                <p>{t("faqs.text6")}</p>
                <p>{t("faqs.text7")}</p>
                <p>{t("faqs.text7i1")}</p>
                <p>{t("faqs.text8")}</p>
                <p>
                  {t("faqs.text9")} {t("faqs.challenges")}
                  {t("faqs.text9i1")}
                </p>
                <p>{t("faqs.text10")}</p>
                <p>
                  <strong>{t("faqs.text11")}</strong>
                </p>
                <div className="section__block" style={{ marginBottom: "3rem" }}>
                  <p>
                    {t("submissionconfirm.text5")} = <span>{t("submissionconfirm.text6")}</span>
                  </p>
                  <p>
                    {t("faqs.1")} = <span>{t("faqs.2")}</span>
                  </p>
                  <p>
                    {t("faqs.texti11")} = <span>{t("submissionconfirm.text8")}</span>
                  </p>
                  <p>
                    {t("faqs.texti12")} = <span>{t("submissionconfirm.text1i0")} </span>{" "}
                    {t("faqs.texti13")}
                  </p>
                </div>
                {/* /.section__block */}
                <h6>{t("faqs.text12")}</h6>
                <p>{t("faqs.text13")}</p>
                <h6>{t("faqs.text14")}</h6>
                <p>{t("faqs.text15")}</p>
                <p>{t("faqs.text16")}</p>
                <p>{t("faqs.text17")}</p>
                <p>{t("faqs.text18")}</p>
                <p>{t("faqs.text19")}</p>
                <p>{t("faqs.3")}</p>
                <p>{t("faqs.text20")}</p>
                <h6>{t("faqs.text21")}</h6>
                <p>{t("faqs.text22")}</p>
                <p>{t("faqs.text23")}</p>
                <p>{t("faqs.text24")}</p>
                <p>
                  1<sup className="super">{t("faqs.texti67")} </sup>
                  {t("faqs.text25")}
                </p>
                <p>
                  2<sup className="super">{t("faqs.texti68")} </sup>
                  {t("faqs.text26")}
                </p>
                <p>
                  3<sup className="super">{t("faqs.texti69")} </sup>
                  {t("faqs.text27")}
                </p>

                <h6>{t("faqs.text35")}</h6>
                <p>{t("faqs.text36")}</p>
                <h6>{t("faqs.text37")}</h6>
                <p>{t("faqs.text38")}</p>
                {/* /.section__actions */}
              </div>
              {/* /.section__hint */}
            </div>
            {/* /.section__inner */}
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-blocks */}
      </div>
      {/* /.main */}
    </>
  );
};

export default Faqs;
