import { Outlet } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import {useLocation} from "react-router-dom";
import { getSessionClient } from "../api";


type Phases = {
  phase: string;
  timer?: string;
};

type LocationReport = { path: string; hash?: string; search?: string };
let lastLocationReport: LocationReport = { path: "" };

export default function Pages({ currentPhase }: { currentPhase: Phases | undefined }) {
  const { t, i18n } = useTranslation();

  const location = useLocation();

  async function reportLocation(currentLocation: LocationReport) {
    try {
      await getSessionClient()
        .createFlowBuilder()
        .setTag("navigation")
        .addMetadata(currentLocation)
        .commit();
    } catch {}
  }

  useEffect(() => {
    const currentLocation: LocationReport = { path: location.pathname };
    if (location.hash && location.hash !== "#") {
      currentLocation.hash = location.hash;
    }
    if (location.search && location.search !== "?") {
      currentLocation.search = location.search;
    }
    if (
      lastLocationReport.path !== currentLocation.path ||
      lastLocationReport.hash !== currentLocation.hash ||
      lastLocationReport.search !== currentLocation.search
    ) {
      reportLocation(currentLocation);
      lastLocationReport = currentLocation;
    }
  });

  useEffect(() => {
    if (i18n.language === "en") {
      document.title = `Mackenzie Top Peak`;
    } else {
      document.title = `Au Sommet avec Mackenzie`;
    }
  }, [i18n.language]);
  return (
    <div className="main-wrapper">
      <Header currentPhase={currentPhase} />
      <section>
        <Outlet context={{ currentPhase }} />
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
}
