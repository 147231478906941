import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { campaignClient } from "../../../api";
import Loading from "../../../Components/Loading";
import "./index.css";

export type ChallengesReturns = {
  challengeKey: string;
  title_en: string;
  desc_en: string;
  video_en: string;
  seq_en: number;
  title_fr: string;
  desc_fr: string;
  video_fr: string;
  seq: number;
}[];
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const Challenges = () => {
  const { t, i18n } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [challenges, setChallenges] = useState<ChallengesReturns | undefined>();
  const language = i18n.language;
  const [videoPlay, setVideoPlay] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getChallenges();
  }, []);

  const getChallenges = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    try {
      const result: { challenges: ChallengesReturns } = await campaignClient.call("challenges", {});
      if (result.challenges) {
        setChallenges(result.challenges);
      }
    } catch (e) {
      setSubmitting(false);
      window.vex.dialog.alert({
        unsafeMessage: "sorry,something went wrong!Please try again later.",
        callback: () => {
          return;
        },
      });
    }
    setSubmitting(false);
  };
  return (
    <>
      {submitting && <Loading />}
      <div className="main challenges">
        <div className="parallax parallax--different">
          <img src="/assets/images/decoration-2@2x.jpg" alt="image" />
        </div>
        {/* /.parallax */}

        <div className="hero hero--different hero--size1">
          <div className="shell shell--lg">
            <div className="hero__inner">
              <h2>{t("challenges.text1")}</h2>
            </div>
            {/* /.hero__inner */}
          </div>
          {/* /.shell */}

          <div className="hero__decoration">
            <img src="/assets/images/temp/mountain-2@2x.png" alt="image" />
          </div>
          {/* /.hero__decoration */}
        </div>
        {/* /.hero */}

        <section className="section-blocks" style={{ textAlign: "center" }}>
          <div className="shell shell--lg">
            <div className="section__inner">
              <div className="section__hint">
                <p>{t("challenges.text2")}</p>

                <p> {t("challenges.text3")} </p>
                <p>
                  {t("challenges.text4")} {t("challenges.text5")}
                </p>

                <p>{t("challenges.text6")}</p>

                <p>{t("challenges.text7")}</p>

                {/* <p>{t("challenges.text2")}<span style={{color:"#E67524"}}>{t("challenges.text3")}</span>{t("challenges.text4")}<span style={{color:"#E67524"}}>{t("challenges.text5")}</span></p> */}
              </div>

              {challenges && challenges.length > 0 ? (
                challenges.map((challenge, index) => {
                  return (
                    <div className="challanges-section" key={index + 1}>
                      <h4
                        style={{ textDecoration: "underline #E67524", textUnderlineOffset: "5px" }}>
                        {" "}
                        {language === "fr" ? challenge.title_fr : challenge.title_en}
                      </h4>

                      <p className="desc">
                        {language === "fr" ? challenge.desc_fr : challenge.desc_en}
                      </p>
                      {language === "fr"
                        ? challenge.video_fr && (
                            <a
                              className="card__image"
                              onClick={() => {
                                setVideoPlay(true);
                              }}>
                              <ReactPlayer
                                url={challenge.video_fr}
                                playing={videoPlay}
                                volume={1}
                                className="videoPlayer"
                                controls
                                //onReady={() => console.log("ready now")}
                              />{" "}
                            </a>
                          )
                        : challenge.video_en && (
                            <a
                              className="card__image"
                              onClick={() => {
                                setVideoPlay(true);
                              }}>
                              <ReactPlayer
                                url={challenge.video_en}
                                playing={videoPlay}
                                volume={1}
                                className="videoPlayer"
                                controls
                                //onReady={() => console.log("ready now")}
                              />{" "}
                            </a>
                          )}
                    </div>
                  );
                })
              ) : (
                <p>{t("challenges.text8")} </p>
              )}
            </div>
            {/* /.section__inner */}
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-blocks */}
      </div>
      {/* /.main */}
    </>
  );
};

export default Challenges;
