// eslint-disable-next-line
export const emailRegex =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
export const looseNorthAmericanPhoneRegex =
  /^\+?1?[.() -]{0,2}[2-9][0-8][0-9][.() -]{0,2}[2-9][0-9]{2}[.() -]?[0-9]{4}$/
export const canadianPostalCodeRegex = /^[a-z][0-9][a-z][ -]?[0-9][a-z][0-9]$/i
export const americanZipCodeRegex = /^[0-9]{5}(-[0-9]{4})?$/
export const stringRegex = /^[a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ \-']{2,64}$/i
export const policyNumberRegex = /^(GR|gr|Gr|gR)\d{10}$/


export const birthYearRegex = /^(1[2-9]\d\d|20[0-4]\d|2050)$/i;
export const emailValidation = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/