import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";

const RallyKit = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const linkRef1 = useRef<HTMLAnchorElement>(null);
  const linkRef2 = useRef<HTMLAnchorElement>(null);
  const linkRef3 = useRef<HTMLAnchorElement>(null);
  const linkRef31 = useRef<HTMLAnchorElement>(null);
  const linkRef32 = useRef<HTMLAnchorElement>(null);

  const linkRef4 = useRef<HTMLAnchorElement>(null);
  const linkRef5 = useRef<HTMLAnchorElement>(null);
  const linkRef6 = useRef<HTMLAnchorElement>(null);
  const linkRef7 = useRef<HTMLAnchorElement>(null);
  const linkRef8 = useRef<HTMLAnchorElement>(null);
  const linkRef9 = useRef<HTMLAnchorElement>(null);
  const linkRef10 = useRef<HTMLAnchorElement>(null);
  const linkRef11 = useRef<HTMLAnchorElement>(null);
  const linkRef12 = useRef<HTMLAnchorElement>(null);
  const linkRef13 = useRef<HTMLAnchorElement>(null);
  const linkRef14 = useRef<HTMLAnchorElement>(null);
  const linkRef15 = useRef<HTMLAnchorElement>(null);
  const linkRef16 = useRef<HTMLAnchorElement>(null);

  async function downloadImage(e: any, type: string, name: string) {
    e.preventDefault();
    if (name == "teambackground") {
      if (linkRef1.current) {
        const src = linkRef1.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef1.current.href = URL.createObjectURL(imageBlob);
        linkRef1.current.download = `${name}.${type}`;
        linkRef1.current.click();
      }
    }
    if (name == "teambackgroundFr") {
      if (linkRef2.current) {
        const src = linkRef2.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef2.current.href = URL.createObjectURL(imageBlob);
        linkRef2.current.download = `${name}.${type}`;
        linkRef2.current.click();
      }
    }
    if (name === "emailsignature") {
      if (linkRef3.current) {
        const src = linkRef3.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef3.current.href = URL.createObjectURL(imageBlob);
        linkRef3.current.download = `${name}.${type}`;
        linkRef3.current.click();
      }
    }
    if (name === "emailsignatureFr") {
      if (linkRef4.current) {
        const src = linkRef4.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef4.current.href = URL.createObjectURL(imageBlob);
        linkRef4.current.download = `${name}.${type}`;
        linkRef4.current.click();
      }
    }
    if (name === "exchangeBanner") {
      if (linkRef31.current) {
        const src = linkRef31.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef31.current.href = URL.createObjectURL(imageBlob);
        linkRef31.current.download = `${name}.${type}`;
        linkRef31.current.click();
      }
    }
    if (name === "exchangeBannerFr") {
      if (linkRef32.current) {
        const src = linkRef32.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef32.current.href = URL.createObjectURL(imageBlob);
        linkRef32.current.download = `${name}.${type}`;
        linkRef32.current.click();
      }
    }

    if (name == "socialoverlay") {
      if (linkRef5.current) {
        const src = linkRef5.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef5.current.href = URL.createObjectURL(imageBlob);
        linkRef5.current.download = `${name}.${type}`;
        linkRef5.current.click();
      }
    }
    if (name === "socialoverlayFr") {
      if (linkRef6.current) {
        const src = linkRef6.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef6.current.href = URL.createObjectURL(imageBlob);
        linkRef6.current.download = `${name}.${type}`;
        linkRef6.current.click();
      }
    }
    if (name === "facebook") {
      if (linkRef7.current) {
        const src = linkRef7.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef7.current.href = URL.createObjectURL(imageBlob);
        linkRef7.current.download = `${name}.${type}`;
        linkRef7.current.click();
      }
    }
    if (name === "facebookFr") {
      if (linkRef8.current) {
        const src = linkRef8.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef8.current.href = URL.createObjectURL(imageBlob);
        linkRef8.current.download = `${name}.${type}`;
        linkRef8.current.click();
      }
    }
    if (name === "igfeed") {
      if (linkRef9.current) {
        const src = linkRef9.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef9.current.href = URL.createObjectURL(imageBlob);
        linkRef9.current.download = `${name}.${type}`;
        linkRef9.current.click();
      }
    }
    if (name === "igfeedFr") {
      if (linkRef10.current) {
        const src = linkRef10.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef10.current.href = URL.createObjectURL(imageBlob);
        linkRef10.current.download = `${name}.${type}`;
        linkRef10.current.click();
      }
    }
    if (name === "instagramstory") {
      if (linkRef11.current) {
        const src = linkRef11.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef11.current.href = URL.createObjectURL(imageBlob);
        linkRef11.current.download = `${name}.${type}`;
        linkRef11.current.click();
      }
    }
    if (name === "instagramstoryFr") {
      if (linkRef12.current) {
        const src = linkRef12.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef12.current.href = URL.createObjectURL(imageBlob);
        linkRef12.current.download = `${name}.${type}`;
        linkRef12.current.click();
      }
    }
    if (name === "poster1") {
      if (linkRef13.current) {
        const src = linkRef13.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef13.current.href = URL.createObjectURL(imageBlob);
        linkRef13.current.download = `${name}.${type}`;
        linkRef13.current.click();
      }
    }
    if (name === "poster1Fr") {
      if (linkRef14.current) {
        const src = linkRef14.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef14.current.href = URL.createObjectURL(imageBlob);
        linkRef14.current.download = `${name}.${type}`;
        linkRef14.current.click();
      }
    }
    if (name === "poster2") {
      if (linkRef15.current) {
        const src = linkRef15.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef15.current.href = URL.createObjectURL(imageBlob);
        linkRef15.current.download = `${name}.${type}`;
        linkRef15.current.click();
      }
    }
    if (name === "poster2Fr") {
      if (linkRef16.current) {
        const src = linkRef16.current.href;
        const imageBlob = await (await fetch(src)).blob();
        linkRef16.current.href = URL.createObjectURL(imageBlob);
        linkRef16.current.download = `${name}.${type}`;
        linkRef16.current.click();
      }
    }
  }

  return (
    <>
      <div className="main rallykit">
        <div className="parallax parallax--different">
          <img src="/assets/images/decoration-2@2x.jpg" alt="image" />
        </div>
        {/* /.parallax */}

        <div className="hero hero--different hero--size1">
          <div className="shell shell--lg">
            <div className="hero__inner">
              {/* <a href="#" className="hero__link">{t('leaderboard.text1')}</a> */}

              <h2>{t("rallyKit.text1")}</h2>
            </div>
            {/* /.hero__inner */}
          </div>
          {/* /.shell */}

          <div className="hero__decoration">
            <img src="/assets/images/temp/mountain-2@2x.png" alt="image" />
          </div>
          {/* /.hero__decoration */}
        </div>
        {/* /.hero */}

        <section className="section-blocks">
          <div className="shell shell--lg">
            <div className="section__inner">
              <div className="section__hint">
                <div className="sections section1">
                  <h6>{t("rallyKit.text2")}</h6>
                  <img
                    src={
                      i18n.language === "fr"
                        ? "/assets/images/FR_16x9_virtual-bg_2.jpg "
                        : "/assets/images/rallykit/Teamsbackground_EN.jpg"
                    }
                    alt="image"
                  />
                  <a
                    ref={linkRef1}
                    href={"/assets/images/rallykit/Teamsbackground_EN.jpg"}
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "jpg", "teambackground")}
                      className="btn link-btn">
                      {t("rallyKit.text10")}
                    </button>
                  </a>
                  <a
                    ref={linkRef2}
                    href={"/assets/images/FR_16x9_virtual-bg_2.jpg"}
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "jpg", "teambackgroundFr")}
                      className="btn link-btn">
                      {t("rallyKit.text11")}
                    </button>
                  </a>
                </div>
                <div className="sections section1">
                  <h6>{t("rallyKit.text3")}</h6>
                  <img
                    src={
                      i18n.language === "fr"
                        ? "/assets/images/rallykit/new/EmailSignature_FR.jpg "
                        : "/assets/images/rallykit/EmailSignature_EN.jpg"
                    }
                    alt="emailsignature"
                  />
                  <a
                    ref={linkRef3}
                    href={"/assets/images/rallykit/EmailSignature_EN.jpg"}
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "jpg", "emailsignature")}
                      className="btn link-btn">
                      {t("rallyKit.text10")}
                    </button>
                  </a>
                  <a
                    ref={linkRef4}
                    href="/assets/images/rallykit/new/EmailSignature_FR.jpg "
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "jpg", "emailsignatureFr")}
                      className="btn link-btn">
                      {t("rallyKit.text11")}
                    </button>
                  </a>
                </div>
                <div className="sections section1">
                  <h6>{t("rallyKit.text31")}</h6>
                  <img
                    src={
                      i18n.language === "fr"
                        ? "/assets/images/rallykit/new/FR_ExchangeBanners_Mackenzie_HomepageBanner.jpg"
                        : "/assets/images/rallykit/new/EN_ExchangeBanners_Mackenzie_HomepageBanner.jpg"
                    }
                    alt="exchangeBanner"
                  />
                  <a
                    ref={linkRef31}
                    href={
                      "/assets/images/rallykit/new/EN_ExchangeBanners_Mackenzie_HomepageBanner.jpg"
                    }
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "jpg", "exchangeBanner")}
                      className="btn link-btn">
                      {t("rallyKit.text10")}
                    </button>
                  </a>
                  <a
                    ref={linkRef32}
                    href="/assets/images/rallykit/new/FR_ExchangeBanners_Mackenzie_HomepageBanner.jpg"
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "jpg", "exchangeBannerFr")}
                      className="btn link-btn">
                      {t("rallyKit.text11")}
                    </button>
                  </a>
                </div>
                <div className="sections section1">
                  <h6>{t("rallyKit.text4")}</h6>
                  <img
                    src={
                      i18n.language === "fr"
                        ? "/assets/images/rallykit/SocialOverlay_FR2.png "
                        : "/assets/images/rallykit/SocialOverlay_EN2.png"
                    }
                    alt="socialoverlay"
                  />
                  <a
                    ref={linkRef5}
                    href="/assets/images/rallykit/SocialOverlay_EN2.png"
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "png", "socialoverlay")}
                      className="btn link-btn">
                      {t("rallyKit.text10")}
                    </button>
                  </a>
                  <a
                    ref={linkRef6}
                    href="/assets/images/rallykit/SocialOverlay_FR2.png"
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "png", "socialoverlayFr")}
                      className="btn link-btn">
                      {t("rallyKit.text11")}
                    </button>
                  </a>
                </div>

                <div className="sections section1">
                  <h6>{t("rallyKit.text6")}</h6>
                  <img
                    src={
                      i18n.language === "fr"
                        ? "/assets/images/rallykit/new/SocialPost_FR_FB.jpg "
                        : "/assets/images/rallykit/SocialPost_EN_FB.jpg"
                    }
                    alt="socailpost-fb"
                  />
                  <a
                    ref={linkRef7}
                    href={"/assets/images/rallykit/SocialPost_EN_FB.jpg"}
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "jpg", "facebook")}
                      className="btn link-btn">
                      {t("rallyKit.text10")}
                    </button>
                  </a>
                  <a
                    ref={linkRef8}
                    href={"/assets/images/rallykit/new/SocialPost_FR_FB.jpg"}
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "jpg", "facebookFr")}
                      className="btn link-btn">
                      {t("rallyKit.text11")}
                    </button>
                  </a>
                </div>
                <div className="sections section1">
                  <h6>{t("rallyKit.text12")}</h6>
                  <img
                    src={
                      i18n.language === "fr"
                        ? "/assets/images/rallykit/new/SocialPost_FR_IGFeed.jpg"
                        : "/assets/images/rallykit/SocialPost_EN_IGFeed.jpg"
                    }
                    alt="socialpost_ig"
                  />
                  <a
                    ref={linkRef9}
                    href={"/assets/images/rallykit/SocialPost_EN_IGFeed.jpg"}
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "jpg", "igfeed")}
                      className="btn link-btn">
                      {t("rallyKit.text10")}
                    </button>
                  </a>
                  <a
                    ref={linkRef10}
                    href={"/assets/images/rallykit/new/SocialPost_FR_IGFeed.jpg"}
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "jpg", "igfeedFr")}
                      className="btn link-btn">
                      {t("rallyKit.text11")}
                    </button>
                  </a>
                </div>

                <div className="sections section1">
                  <h6>{t("rallyKit.text14")}</h6>
                  <img
                    src={
                      i18n.language === "fr"
                        ? "/assets/images/rallykit/new/Poster1_FR.jpg "
                        : "/assets/images/rallykit/Poster1_EN.jpg"
                    }
                    alt="poster1"
                  />
                  <a
                    ref={linkRef13}
                    href={"/assets/images/rallykit/Poster1_EN.jpg"}
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "jpg", "poster1")}
                      className="btn link-btn">
                      {t("rallyKit.text10")}
                    </button>
                  </a>
                  <a
                    ref={linkRef14}
                    href={"/assets/images/rallykit/new/Poster1_FR.jpg "}
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "png", "poster1Fr")}
                      className="btn link-btn">
                      {t("rallyKit.text11")}
                    </button>
                  </a>
                </div>
                <div className="sections section1">
                  <h6>{t("rallyKit.text15")}</h6>
                  <img
                    src={
                      i18n.language === "fr"
                        ? "/assets/images/rallykit/new/Poster2_FR.jpg "
                        : "/assets/images/rallykit/Poster2_EN.jpg"
                    }
                    alt="poster2"
                  />
                  <a
                    ref={linkRef15}
                    href={"/assets/images/rallykit/Poster2_EN.jpg"}
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "jpg", "poster2")}
                      className="btn link-btn">
                      {t("rallyKit.text10")}
                    </button>
                  </a>
                  <a
                    ref={linkRef16}
                    href={"/assets/images/rallykit/new/Poster2_FR.jpg"}
                    download="download">
                    <button
                      onClick={(e) => downloadImage(e, "png", "poster2Fr")}
                      className="btn link-btn">
                      {t("rallyKit.text11")}
                    </button>
                  </a>
                </div>
              </div>
              {/* /.section__hint */}
              <p style={{ fontSize: "1.4rem" }}>{t("rallyKit.disclaimer")}</p>
            </div>
            {/* /.section__inner */}
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-blocks */}
      </div>
      {/* /.main */}
    </>
  );
};

export default RallyKit;
