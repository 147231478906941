import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { campaignClient } from "../../../api";
import styles from "../../Open/ReviewProfile/submission.module.css";
import Modal from "react-modal";
import { useOutletContext } from "react-router";
import Loading from "../../../Components/Loading";
import { convertcaps } from "../../../helpers/utils";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import dayjs from "dayjs";

declare const window: Window &
    typeof globalThis & {
        vex: any;
    };

const LandingWinnerComing = () => {
    const { t, i18n } = useTranslation();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState("");

    const { currentPhase }: { currentPhase: { phase: string; timer: string } } = useOutletContext();

    const WinnersOpenDate = currentPhase.timer;
    const [timeLeft, setTimeLeft] = useState<{
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
    } | null>();

    function calculateTimeLeft(comparedate: Date) {
        let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
        const difference = +comparedate - +new Date();

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
            return timeLeft;
        }
    }

    const difference = -dayjs().diff(new Date(WinnersOpenDate), "millisecond");

    useEffect(() => {
        const id = setTimeout(() => {
            if (WinnersOpenDate) {
                setTimeLeft(calculateTimeLeft(new Date(WinnersOpenDate)));
            }
        }, 1000);
    });

    const navigate = useNavigate();

    function afterOpenModal() {}
    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: "1000",
        },
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {
        data: peaks,
        isLoading,
        error,
        isError,
    } = useQuery("topPeaks", async () => {
        const result: { peaks: any[]; error: string; message: string } = await campaignClient.call("toptenpeaks", { sortNumber: 3 });
        return result.peaks;
    });

    if (isError) {
        window.vex.dialog.alert({
            unsafeMessage: `Error while fetching the peak details. Please try again!`,
            callback: () => {
                console.log(error);
                return;
            },
        });
    }

    return (
        <>
            {isLoading && <Loading />}
            <div className="main voting">
                <div className="parallax parallax--different">
                    <img src="/assets/images/decoration.jpg" alt="image" />
                </div>

                <div className="hero hero--different hero--centered">
                    <div className="shell">
                        <div className="hero__inner">
                            <h1>{t("winnerComing.1")}</h1>
                            <p>{t("winnerComing.2")}</p>
                        </div>
                        {/* /.hero__inner */}
                    </div>
                    {/* /.shell */}

                    <div className="hero__decoration">
                        <img src="/assets/images/temp/mountain@2x.png" alt="image" width="1280" height="560" />
                    </div>
                    {/* /.hero__decoration */}
                </div>
                {/* /.hero */}

                <section className="section-countdown">
                    <div className="shell">
                        <h4>{t("winnerComing.3")}</h4>

                        <ul>
                            <li>
                                <strong>{timeLeft?.days || 0}</strong>

                                <p>{t("Landing.text10")}</p>
                            </li>

                            <li>
                                <strong>{timeLeft?.hours || 0}</strong>

                                <p>{t("Landing.text11")}</p>
                            </li>

                            <li>
                                <strong>{timeLeft?.minutes || 0}</strong>

                                <p>{t("Landing.text12")}</p>
                            </li>

                            <li>
                                <strong>{timeLeft?.seconds || 0}</strong>

                                <p>{t("Landing.text13")}</p>
                            </li>
                        </ul>
                    </div>
                    {/* /.shell */}
                </section>

                <section className="section-media">
                    <div className="shell section__shell">
                        <div className="grid">
                            {peaks &&
                                peaks.map(
                                    (peak) =>
                                        peak && (
                                            <div className="grid__col grid__col--1of2" key={peak.peakCode}>
                                                <div className="media">
                                                    <button
                                                        className="h6"
                                                        style={{
                                                            border: "none",
                                                            background: "transparent",
                                                            minWidth: "100%",
                                                            textAlign: "left",
                                                        }}
                                                        onClick={() => {
                                                            navigate(`/teams/${peak.peakToken}`);
                                                        }}>
                                                        {convertcaps(peak.peakName)}
                                                    </button>

                                                    {peak?.teamDetails?.metadata?.videoKey && (
                                                        <img
                                                            src="/assets/images/svg/ic_play.svg"
                                                            alt="decoration"
                                                            className="videoplay_btn"
                                                            onClick={() => {
                                                                setIsOpen(true);
                                                                setSelectedVideo(peak.teamDetails?.metadata?.videoKey);
                                                            }}
                                                        />
                                                    )}
                                                    {peak?.teamDetails?.metadata?.thumbnailKey ? (
                                                        <img src={peak?.teamDetails?.metadata?.thumbnailKey} alt="thumbnail" className="thumbnail-img" width="560" height="315" />
                                                    ) : (
                                                        <div
                                                            className="thumbnail-img"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}>
                                                            <p>
                                                                {" "}
                                                                <i className="fas fa-exclamation-circle" /> {t("errors.imagenotavailable")}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                )}
                        </div>
                    </div>
                </section>
                <section className="section-conditions">
                    <div className="shell">
                        <div className="section__content">
                            <h6
                                style={{
                                    padding: "1rem",
                                    color: "#E67524",
                                    fontWeight: "bold",
                                }}>
                                {t("Landing.text48")}
                            </h6>
                            <div style={{ marginBottom: "1.6rem" }}>
                                {i18n.language === "fr" ? (
                                    <img src="./assets/images/rallykit/new/CBC_logo-white1.png" alt="CBC Sports" width="85" height="39" />
                                ) : (
                                    <img src="./assets/images/temp/partner-1@2x.png" alt="CBC Sports" width="284" height="39" />
                                )}
                            </div>

                            <ul>
                                <li>
                                    <h6>{t("Landing.text53")}</h6>

                                    <p>{t("Landing.text54")} </p>
                                </li>
                            </ul>
                        </div>
                        {/* /.section__content */}
                    </div>
                    {/* /.shell */}

                    <div className="section__decoration">
                        <img src="./assets/images/temp/mountain-lg@2x.png" alt="image" />
                    </div>
                    {/* /.section__decoration */}
                </section>
                {/* /.section-conditions */}

                <section className="section-dates">
                    <div className="shell">
                        <div className="section__inner">
                            <div className="section__content">
                                {/* /.section__items */}

                                <div className="section__entry">
                                    <h3>
                                        {t("Landing.text55")} <span style={{ color: "#E67524" }}>{t("Landing.text56")}</span> {t("Landing.text57")}
                                    </h3>

                                    <p>{t("Landing.text58")}</p>

                                    <div className="section__actions">
                                        <a
                                            target="_blank"
                                            href={i18n.language == "fr" ? "https://www.mackenzieinvestments.com/fr" : "https://www.mackenzieinvestments.com/en"}
                                            className="btn btn-success">
                                            {t("Landing.text59")} <br /> {t("Landing.text60")}
                                        </a>
                                    </div>
                                </div>

                                {/* /.section__entry */}
                            </div>
                            <div className="section__aside">
                                <div className="section__image">
                                    <img src="./assets/images/temp/landing_img2.png" alt="image" width="450" height="450" />
                                </div>
                                {/* /.section__image */}
                            </div>
                            {/* /.section__aside */}
                            {/* /.section__content */}
                        </div>
                        {/* /.section__inner */}
                    </div>
                    {/* /.shell */}
                </section>

                <section className="section-gallery" style={{ textAlign: "center" }}>
                    <h2>{t("Landing.text61")}</h2>
                    <div className="embedsocial-hashtag" data-ref="328b1f27a5cb923ed3f3a57cc37da981ef1acce9"></div>
                </section>
            </div>
            {/* /.main */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={() => {
                    setIsOpen(false);
                }}
                style={customStyles}
                contentLabel="Modal"
                className={styles.Modal}>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                        setIsOpen(false);
                        setSelectedVideo("");
                    }}
                    style={{ border: "none", background: "transparent", float: "right" }}>
                    <span aria-hidden="true">
                        <img src="/assets/images/ico-close-black.png" alt="close" />
                    </span>
                </button>
                <div className={styles.modal_content}>
                    {
                        <div className={styles.iframe_container}>
                            <ReactPlayer
                                id="player"
                                className={styles.video}
                                url={selectedVideo}
                                onPlay={() => {}}
                                onEnded={() => {}}
                                controls={true}
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: "nodownload",
                                            onContextMenu: (e: any) => e.preventDefault(),
                                        },
                                    },
                                }}
                            />
                        </div>
                    }
                </div>
            </Modal>
        </>
    );
};

export default LandingWinnerComing;
