import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { campaignClient } from "../../../api";
import Loading from "../../../Components/Loading";
import { Graph } from "./Graph";
import "./index.css";
import { convertcaps } from "../../../helpers/utils";

type Peaks = {
  peakName: string;
  points: string;
  rank?: number;
  peakToken: string;
  votePoints: string;
}[];

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const LeaderBoard = () => {
  const [submitting, setSubmitting] = useState(false);
  const [allpeaks, setAllPeaks] = useState<any[]>();
  const [top10, setTop10] = useState<any[]>();
  const { t } = useTranslation();

  function shuffle(array: any[]) {
    const newArray = [...array];
    const length = newArray.length;

    for (let start = 0; start < length; start++) {
      const randomPosition = Math.floor((newArray.length - start) * Math.random());
      const randomItem = newArray.splice(randomPosition, 1);

      newArray.push(...randomItem);
    }

    return newArray;
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    getAllPeaks();
  }, []);

  const getAllPeaks = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    try {
      const result: { peaks?: Peaks } = await campaignClient.call("leaderboard", {});
      if (result.peaks) {
        const sorted = result.peaks.sort((a, b) => +b.points - +a.points).slice(0, 10);
        setTop10(sorted);
        const sliced = sorted.slice(0, 10).map((peak, i) => {
          return { ...peak, rank: i + 1 };
        });
        const randomized = shuffle(sliced);
        setAllPeaks(randomized);
      }
    } catch (e) {
      window.vex.dialog.alert({
        unsafeMessage: "sorry,something went wrong!Please try again later.",
        callback: () => {
          return;
        },
      });
    }
    setSubmitting(false);
  };
  return (
    <>
      {submitting && <Loading />}
      <div className="main challenges">
        <div className="parallax parallax--different">
          <img src="/assets/images/decoration-2@2x.jpg" alt="image" />
        </div>
        {/* /.parallax */}

        <div className="hero hero--different hero--size1">
          <div className="shell shell--lg">
            <div className="hero__inner">
              <a href="#" className="hero__link">
                {t("leaderboard.text1")}
              </a>

              <h2>{t("leaderboard.text2")}</h2>
            </div>
            {/* /.hero__inner */}
          </div>
          {/* /.shell */}

          <div className="hero__decoration">
            <img src="/assets/images/temp/mountain-2@2x.png" alt="image" />
          </div>
          {/* /.hero__decoration */}
        </div>
        {/* /.hero */}

        <section className="section-blocks">
          <div className="shell shell--lg">
            <div className="section__inner">
              <div className="section__hint">
                <div className="graph">
                  <Graph
                    labels={allpeaks ? allpeaks.map((peak) => convertcaps(peak.peakName)) : []}
                    points={allpeaks ? allpeaks.map((peak) => peak.points) : []}
                    ranks={allpeaks ? allpeaks.map((peak) => peak.rank) : []}
                  />
                </div>
                <div className="top3-section">
                  <h6> {t("leaderboard.text3")}</h6>
                  <ol>
                    {top10?.slice(0, 3).map((peak) => (
                      <li>{convertcaps(peak.peakName)}</li>
                    ))}
                  </ol>
                </div>

                <div className="top10-section">
                  <h6> {t("leaderboard.text1")}</h6>

                  <ol>
                    {top10?.map((peak) => (
                      <div className="peakrecord">
                        <li>{convertcaps(peak.peakName)}</li>
                      </div>
                    ))}
                  </ol>
                </div>
              </div>
              {/* /.section__hint */}
            </div>
            {/* /.section__inner */}
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-blocks */}
      </div>
      {/* /.main */}
    </>
  );
};

export default LeaderBoard;
