import { Route } from "react-router-dom";
import Closed from "../Pages/Closed";
import Open from "../Pages/Open";
import "../i18n";
import PreviousWinners from "../Pages/Open/PreviousWinners";
import RulesEn from "../Pages/RulesEn";
import RulesFr from "../Pages/RulesFr";
import Teams from "../Pages/Open/Teams";
import HowItWorks from "../Pages/Open/HowItWorks";

import Faqs from "../Pages/Open/Faqs";
import Landing from "../Pages/Open/Landing";
import Landing3 from "../Pages/Open/Phase3/Landing";
import Received from "../Pages/Open/Phase3/VoteReceived";
import Vote from "../Pages/Open/Phase3/Vote";
import CheckEmail from "../Pages/Open/Phase3/CheckEmail";
import VoteReceived from "../Pages/Open/Phase3/VoteReceived";
import LeaderBoard from "../Pages/Open/Leaderboard";
import Challenges from "../Pages/Open/Challenges";
import RallyKit from "../Pages/Open/RallyKit";
export default (
  <>
    <Route path="/" element={<Open />}>
      <Route path="/" element={<Landing3 />} />
      <Route path="teams/:peakToken" element={<Teams />} />
      <Route path="vote" element={<Vote />} />
      <Route path="vote/:peakToken" element={<Vote />} />
      <Route path="vote/checkEmail/:pk/:email" element={<CheckEmail />} />
      <Route path="vote/received/:sessionKey/:peakToken/:time" element={<VoteReceived />} />
      <Route path="howitworks" element={<HowItWorks />} />
      <Route path="leaderboard" element={<LeaderBoard />} />
      <Route path="faqs" element={<Faqs />} />
      <Route path="pastwinners" element={<PreviousWinners />} />
      <Route path="challenges" element={<Challenges />} />
      <Route path="rallykit" element={<RallyKit />} />
    </Route>

    <Route path="rulesen" element={<RulesEn />} />
    <Route path="rulesfr" element={<RulesFr />} />

    <Route path="closed" element={<Closed />} />
    <Route path="coming" element={<Landing />}></Route>
  </>
);
