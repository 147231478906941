import { useTranslation } from "react-i18next";
import { useRequiredCampaignState } from "../../helpers/campaignStates";

export default function Closed() {
  useRequiredCampaignState("closed");
  const { t } = useTranslation();
  return (
    <div className="main">
      <div className="parallax parallax--alt">
        <img src="/assets/images/decoration.jpg" alt="decoration" />
      </div>
      {/* /.parallax */}

      <div className="hero hero--alt">
        <div className="shell">
          <div className="hero__inner">
            <h4 style={{ textAlign: "left", color: "#fff" }}>{t("Closed.text1")}</h4>
          </div>
          {/* /.hero__inner */}
        </div>
        {/* /.shell */}

        <div className="hero__decoration">
          <img src="/assets/images/temp/mountain@2x.png" alt="mountain" width="1280" height="560" />
        </div>
        {/* /.hero__decoration */}
      </div>
      {/* /.hero */}

      <section className="section-winners">
        <div className="shell">
          <p>{t("Closed.text2")}</p>
        </div>
        {/* /.shell */}
      </section>
      {/* /.section-winners */}
    </div>
  );
}
