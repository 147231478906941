import { useTranslation } from "react-i18next";

function ImportantDates() {
  const { t } = useTranslation();
  return (
    <div className="section__items">
      <h3>{t("Landing.text26")}</h3>

      <ul>
        <li>
          <h5>{t("Landing.text27")}</h5>

          <p>{t("Landing.text2i7")}</p>
        </li>

        <li>
          <h5>{t("Landing.text28")}</h5>

          <p>{t("Landing.text2i8")}</p>
        </li>

        <li>
          <h5>{t("Landing.text29")}</h5>

          <p>{t("Landing.text2i9")} </p>
        </li>

        <li>
          <h5>{t("Landing.text30")}</h5>

          <p>{t("Landing.text3i0")}</p>
        </li>

        <li>
          <h5>{t("Landing.text31")}</h5>

          <p>{t("Landing.text31i1")}</p>
        </li>

        <li>
          <h5>{t("Landing.text32")}</h5>

          <p>{t("Landing.text32i1")}</p>
        </li>
      </ul>
    </div>
  );
}

export default ImportantDates;
