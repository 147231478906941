import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router";
import { campaignClient, getSessionClient, setSessionClient } from "../../../api";
import TeamDetails from "../../../Components/TeamDetails";
import "./index.css";
import { useForm } from "react-hook-form";
import { defaultStorageHelper } from "@spry/campaign-client";
import { convertcaps } from "../../../helpers/utils";
import Loading from "../../../Components/Loading";

export type RegisterFormData = {
  firstName: string;
  email: string;
  address: string;
  city: string;
  phone: string;
  peakName: string;
  teamName: string;
  yearOfBirth: string;
  userType: string;
  rules: boolean;
  cause: string;
};

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const Teams = () => {
  const { t } = useTranslation();
  const { peakToken } = useParams();
  const { state }: { state: { peakName: string; peakCode: string } } = useLocation();

  const [submitting, setSubmitting] = useState(false);
  const [peak, setPeak] = useState<any[]>();
  const navigate = useNavigate();
  const { currentPhase }: { currentPhase: { phase: string; timer: string } } = useOutletContext();

  const peakName = state ? convertcaps(state.peakName) : peak ? convertcaps(peak[0].peakName) : "";
  const peakCode = state ? convertcaps(state.peakCode) : peak ? convertcaps(peak[0].peakCode) : "";


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getPeakTeams = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    try {
      const result: { teams?: any[] } = await campaignClient.call("getTeam", {
        peakToken: peakToken,
      });
      if (result.teams) {
        setPeak(result.teams);
      }
    } catch (e) {
      window.vex.dialog.alert({
        unsafeMessage: `Error while fetching the peak details. Please try again!`,
        callback: () => {
          return;
        },
      });
    }
    setSubmitting(false);
  };
  useEffect(() => {
    getPeakTeams();
  }, [peakToken]);

  return (
    <>
      {submitting && <Loading />}
      <div className="main teams">
        <div className="parallax parallax--different">
          <img src="/assets/images/decoration-2@2x.jpg" alt="image" />
        </div>
        {/* /.parallax */}

        <div className="hero hero--different hero--size1">
          <div className="shell shell--lg">
            <div className="hero__inner">
              <a href="/" className="hero__link">
                {t("teams.text1")}
              </a>
              {(state || (peak && peak[0])) && (
                <>
                  <h2>{peakName}</h2>
                  <p>{peakCode}</p>
                </>
              )}
            </div>
            {/* /.hero__inner */}
          </div>
          {/* /.shell */}

          <div className="hero__decoration">
            <img src="/assets/images/temp/mountain-2@2x.png" alt="image" />
          </div>
          {/* /.hero__decoration */}
        </div>
        {/* /.hero */}

        <section className="section-blocks">
          <div className="shell shell--lg">
            <div className="section__inner">
              <div className="section__hint">
                {peak && peak.length > 0 && (
                  <>
                    {" "}
                    {peak.length < 0 && (
                      <button className="btn btn-success" onClick={() => navigate("/teamterms")}>
                        {t("teams.text3")}
                      </button>
                    )}
                    {peak.map((team: any, index) => (
                      <TeamDetails team={team} key={index} />
                    ))}
                  </>
                )}
                <div className="section__actions">
                  {currentPhase.phase === "rally" && peak && peak.length > 0 && (
                    <button
                      onClick={async () => {
                        defaultStorageHelper.set("teamName", peak[0].teamName);
                        navigate("/nominate", {
                          state: { peakToken,peakName },
                        });
                      }}
                      className="btn btn-primary">
                      {t("teams.text4")}
                    </button>
                  ) }
                </div>
                {/* /.section__actions */}
              </div>
              {/* /.section__hint */}
            </div>
            {/* /.section__inner */}
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-blocks */}
      </div>
      {/* /.main */}
    </>
  );
};

export default Teams;
