import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { defaultStorageHelper } from "@spry/campaign-client";
import "./index.css";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import styles from "../../Pages/Open/ReviewProfile/submission.module.css";

type TeamDetails = {
  city: string;
  creationTime: string;
  sessionKey: string;
  team: string;
  teamName: string;
  metadata?: {
    points: number;
    peakName: string;
    cause: string;
    question1?: string;
    videoKey?: string;
    thumbnailKey?: string;
    question2?: string;
  };
};

const TeamDetails = (props: { team: TeamDetails }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);

  const [preview, setPreview] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  function afterOpenModal() {}

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: "1000",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const handleMediaClick = (e: any) => {
    e.preventDefault();
    setIsOpen(true);
  };
  function closeModal() {
    setIsOpen(false);
    setPreview(null);
  }

  return (
    <>
      <div className="section__grid details">
        <div className="section__col section__col--1of2">
          <div className="media">
            {props.team.metadata?.videoKey && (
              <img
                src="/assets/images/svg/ic_play.svg"
                alt="decoration"
                className="videoplay_btn"
                onClick={() => {
                  props.team.metadata?.videoKey && setIsOpen(true);
                }}
              />
            )}
            <img
              src={props.team.metadata?.thumbnailKey && props.team.metadata?.thumbnailKey}
              alt="thumbnail"
              className="thumbnail-img"
            />

            <div className="section__decoration">
              <img src="/assets/images/svg/ico-plus-small.svg" alt="decoration" />
            </div>
            {/* /.section__decoration */}
          </div>
          {/* /.section__media */}
        </div>
        {/* /.section__col */}

        <div className="section__col section__col--1of2">
          <div className="section__content">
            <header className="section__head">
              <h6>{t("questions.text2")}</h6>
            </header>
            {/* /.section__head */}

            <div className="section__body">
              <p>
                {props.team.metadata?.question1
                  ? props.team.metadata?.question1
                  : "no details entered"}
              </p>
            </div>
            {/* /.section__body */}
          </div>
          <div className="section__content">
            <header className="section__head">
              <h6>{t("questions.text3")}</h6>
            </header>
            {/* /.section__head */}

            <div className="section__body">
              <p>
                {props.team.metadata?.question2
                  ? props.team.metadata?.question2
                  : "no details entered"}
              </p>
            </div>
            {/* /.section__body */}
          </div>
          {/* /.section__content */}
        </div>
        {/* /.section__col */}
      </div>
      {/* /.section__grid */}

      <div className="section__features">
        <div className="section__feature">
          <figure>
            <img src="/assets/images/svg/ico-person.svg" alt="img" />
          </figure>

          <div className="section__feature-inner">
            <span>
              TEAM <br />
              {props.team?.team}
            </span>
          </div>
        </div>
        {/* /.section__feature */}

        <div className="section__feature">
          <figure>
            <img src="/assets/images/svg/ico-pin.svg" alt="img" />
          </figure>

          <div className="section__feature-inner">
            <span>{props.team.city}</span>
          </div>
        </div>
        {/* /.section__feature */}

        <div className="section__feature">
          <figure>
            <img src="/assets/images/svg/ico-hand.svg" alt="img" />
          </figure>

          <div className="section__feature-inner">
            <span>{props.team.metadata?.cause}</span>
          </div>

          {/* /.section__actions */}
        </div>
        <div className="section__feature"></div>
        {/* /.section__feature */}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal"
        className={styles.Modal}>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
          style={{ border: "none", background: "transparent", float: "right" }}>
          <span aria-hidden="true">
            <img src="/assets/images/ico-close-black.png" alt="close" />
          </span>
        </button>
        <div className={styles.modal_content}>
          {
            <div className={styles.iframe_container}>
              <ReactPlayer
                id="player"
                className={styles.video}
                url={props.team.metadata?.videoKey}
                onPlay={() => {}}
                onEnded={() => {}}
                controls={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                      onContextMenu: (e: any) => e.preventDefault(),
                    },
                  },
                }}
              />
            </div>
          }
        </div>
      </Modal>
    </>
  );
};

export default TeamDetails;
