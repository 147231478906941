import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { campaignClient, getSessionClient } from "./api";
import Pages from "./Pages";
import loadGtm from "./data/loadGtm";
import Loading from "./Components/Loading";
import { defaultStorageHelper } from "@spry/campaign-client";
import dayjs from "dayjs";
import CustomRoutes from "./Config/Routes";
import Voteopen from "./Config/Voteopen";
import Rallyphase from "./Config/Rallyphase";
import Winners from "./Config/Winner";
import WinnersSoon from "./Config/WinnersSoon";

export default function App() {
    useEffect(() => {
        loadGtm();
    }, []);

    const [phases, setPhases] = useState<{
        teaser: string;
        rally: string;
        vote: string;
        winner: string;
        winnerComing: string;
    } | null>(null);
    const [loading, setLoading] = useState(false);

    const fetchState = async () => {
        if (loading) {
            return;
        }
        setLoading(true);
        try {
            campaignClient.getState().then((res: any) => {
                setPhases(res.metadata);
                defaultStorageHelper.set("state", res.state);
            });
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchState();
    }, []);

    const [currentPhase, setCurrentPhase] = useState<{
        phase: string;
        timer?: string;
    } | null>(null);
    let currentTime = new Date();

    useEffect(() => {
        if (phases) {
            if (dayjs(currentTime).isBefore(new Date(phases.winnerComing))) {
                setCurrentPhase({ phase: "vote", timer: phases.winnerComing });
                defaultStorageHelper.set("timer", phases.winnerComing);
            } else if (dayjs(currentTime).isBefore(new Date(phases.winner))) {
                setCurrentPhase({ phase: "winnerComing", timer: phases.winner });
                defaultStorageHelper.set("timer", phases.winner);
            }
            if (dayjs(currentTime).isAfter(new Date(phases.winner))) {
                //winner phase
                setCurrentPhase({ phase: "winner" });
                // setCurrentPhase({ phase: "winnerComing", timer: phases.winner });
                // defaultStorageHelper.set("timer", phases.winner);
            }
        }
    }, [phases]);

    return (
        <>
            {(loading || !phases) && <Loading />}
            <BrowserRouter>
                {currentPhase && (
                    <Routes>
                        <Route path="/" element={<Pages currentPhase={currentPhase} />}>
                            {/* {Rallyphase} */}
                            {/* {currentPhase?.phase === "teaser" && CustomRoutes}
              {currentPhase?.phase === "rally" && Rallyphase} */}
                            {currentPhase?.phase === "vote" && Voteopen}
                            {currentPhase?.phase === "winnerComing" && WinnersSoon}

                            {currentPhase?.phase === "winner" && Winners}
                        </Route>

                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                )}
            </BrowserRouter>
        </>
    );
}
