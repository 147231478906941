import * as React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useOutletContext } from "react-router-dom";
import { useRequiredCampaignState } from "../../helpers/campaignStates";

export default function Open() {
  useRequiredCampaignState("open");
  const { currentPhase }: { currentPhase: { phase: string; timer: string } } = useOutletContext();
  const { t } = useTranslation();
  return (
    <div>
      <Outlet context={{ currentPhase }} />
    </div>
  );
}
