import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { emailRegex } from "../../../helpers/regexes";
import { campaignClient } from "../../../api";
import ImportantDates from "../../../Components/ImportantDates";

type SaveEmail = {
  email: string;
};
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

export default function Landing() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SaveEmail>({ mode: "onTouched" });
  const [submitting, setSubmitting] = useState(false);
  const [timeLeft, setTimeLeft] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>();
  const difference = +new Date("2024/01/28") - +new Date();

  function calculateTimeLeft() {
    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    setTimeLeft(timeLeft);

    return timeLeft;
  }
  useEffect(() => {
    if (difference > 0) {
      const id = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function saveEmail(data: SaveEmail) {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    try {
      const { email } = await campaignClient.call<{ email: string }>("saveEmail", {
        email: data.email,
        language: i18n.language,
      });
      if (email) {
        navigate("thankyou");
        // window.vex.dialog.alert({
        //   unsafeMessage: t("Thanks.text2"),
        //   callback: () => {
        //     return;
        //   },
        // });
      } else {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      }
    } catch {
      window.vex.dialog.alert({
        unsafeMessage: `Something went wrong, please try again.`,
        callback: () => {
          return;
        },
      });
    }
    setSubmitting(false);
  }

  return (
    <>
      <div className="main">
        <div className="parallax">
          <img src="/assets/images/decoration.jpg" alt="image" />
        </div>
        {/* /.parallax */}

        <div className="hero">
          <div className="shell">
            <div className="hero__inner">
              <h1>
                {t("Landing.text1")}
                <br />
                {t("Landing.texti1")}
              </h1>

              <div className="subscribe">
                <form onSubmit={handleSubmit(saveEmail)}>
                  {/* <label htmlFor="mail" className="hidden">
                    {t("Lanidng.email")}
                  </label> */}

                  <input
                    {...register("email", {
                      required: { value: true, message: t("errors.email") },
                      pattern: { value: emailRegex, message: t("errors.emailvalid") },
                    })}
                    placeholder={t("Landing.email")}
                    type="email"
                    className="subscribe__field"
                  />
                  {errors.email && (
                    <p className="error-message">
                      <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                      {errors.email.message}
                    </p>
                  )}

                  <button type="submit" className="subscribe__btn">
                    {t("Landing.signup")}
                  </button>
                </form>
              </div>
              {/* /.subscribe */}
            </div>
            {/* /.hero__inner */}
          </div>
          {/* /.shell */}

          <div className="hero__decoration">
            <img src="/assets/images/temp/mountain@2x.png" alt="image" width="1280" height="560" />
          </div>
          {/* /.hero__decoration */}
        </div>
        {/* /.hero */}

        {difference > 0 && (
          <section className="section-countdown">
            <div className="shell">
              <h4>{t("Landing.text9")}</h4>

              <ul>
                <li>
                  <strong>{timeLeft?.days}</strong>

                  <p>{t("Landing.text10")}</p>
                </li>

                <li>
                  <strong>{timeLeft?.hours}</strong>

                  <p>{t("Landing.text11")}</p>
                </li>

                <li>
                  <strong>{timeLeft?.minutes}</strong>

                  <p>{t("Landing.text12")}</p>
                </li>

                <li>
                  <strong>{timeLeft?.seconds}</strong>

                  <p>{t("Landing.text13")}</p>
                </li>
              </ul>
            </div>
            {/* /.shell */}
          </section>
        )}
        {/* /.section-countdown */}

        <section className="section-about">
          <div className="shell">
            <div className="section__content">
              <h3>
                {t("Landing.text2")} <span>{t("Landing.text2i1")}</span> {t("Landing.text2i2")}
              </h3>

              <div className="section__inner">
                <div className="section__entry">
                  <h6>
                    {t("Landing.text3")} <br />
                    {t("Landing.text3i1")}
                  </h6>

                  <p>{t("Landing.text4")} </p>
                </div>
                {/* /.section__entry */}

                <div className="section__image">
                  <img
                    src="./assets/images/temp/helmet@2x.png"
                    alt="image"
                    width="292"
                    height="321"
                  />
                </div>
                {/* /.section__image */}
              </div>
              {/* /.section__inner */}

              <div className="section__block">
                <p>{t("Landing.text5")}</p>
                <p>{t("Landing.text6")}</p>
                <p>{t("Landing.text7")}</p>
                {/* <p>{t("Landing.text8")}</p> */}
              </div>
              {/* /.section__block */}
            </div>
            {/* /.section__content */}
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-about */}

        <section className="section-conditions">
          <div className="shell">
            <div className="section__content">
              <h3>{t("Landing.text14")}</h3>

              <ol>
                <li>
                  <h6>{t("Landing.text15")}</h6>

                  <p>{t("Landing.text16")} </p>
                </li>

                <li>
                  <h6>{t("Landing.text17")}</h6>

                  <p>{t("Landing.text18")}</p>
                </li>

                <li>
                  <h6>{t("Landing.text19")}</h6>

                  <p>{t("Landing.text20")} </p>
                </li>

                <li>
                  <h6>{t("Landing.text21")}</h6>

                  <p>{t("Landing.text22")}</p>
                </li>

                <li>
                  <h6>{t("Landing.text23")}</h6>

                  <p>{t("Landing.text24")}</p>
                </li>
              </ol>

              {/* <div className="section__actions">
							<a href="#" className="btn btn-success">
								{t("Landing.text25")} <br />{t("Landing.text2i5")}
							</a>
						</div> */}
              {/* /.section__actions */}
            </div>
            {/* /.section__content */}
          </div>
          {/* /.shell */}

          <div className="section__decoration">
            <img src="./assets/images/temp/mountain-lg@2x.png" alt="image" />
          </div>
          {/* /.section__decoration */}
        </section>
        {/* /.section-conditions */}

        <section className="section-dates">
          <div className="shell">
            <div className="section__inner">
              <div className="section__aside">
                <div className="section__image">
                  <img
                    src="./assets/images/temp/image@2x.png"
                    alt="image"
                    width="750"
                    height="750"
                  />
                </div>
                {/* /.section__image */}
              </div>
              {/* /.section__aside */}

              <div className="section__content">
                <ImportantDates />

                <div className="section__entry">
                  <h3>{t("Landing.text33")}</h3>

                  <p>
                    {t("Landing.text34")} <span>{t("Landing.text34i1")}</span>{" "}
                    {t("Landing.text34i2")}
                  </p>
                </div>
                {/* /.section__entry */}
              </div>
              {/* /.section__content */}
            </div>
            {/* /.section__inner */}
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-dates */}
      </div>
      {/* /.main */}
    </>
  );
}
